import React, { useContext } from "react";
import GameStateContext from "../GameStateContext";

const GameOverScreen: React.FC = () => {
  const { isGameOver, playerPoints, gamePlayers, selectedBook } =
    useContext(GameStateContext);

  if (!isGameOver || !playerPoints || !gamePlayers || selectedBook) {
    return null;
  }

  // Find the player(s) with the highest score
  const maxPoints = Math.max(...Object.values(playerPoints));
  const winners = gamePlayers.filter(
    (player) => playerPoints[player.id] === maxPoints
  );

  return (
    <div className="game-over-screen">
      <h1>The game is over!</h1>

      <h3>Winner{winners.length > 1 ? "s" : ""}:</h3>
      <ul>
        {winners.map((winner) => (
          <li key={winner.id}>{winner.name}</li>
        ))}
      </ul>
      <h3>Final Scores:</h3>
      <ul>
        {gamePlayers.map((player) => (
          <li key={player.id}>
            {player.name}: {playerPoints[player.id]} points
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GameOverScreen;
