import React, { useState } from "react";
import Debug from "./Debug";
import PlayerTest from "./PlayerTest";
import BookTest from "./BookTest";

const DebugSwitch = () => {
  const [showSwitch, setShowSwitch] = useState(false);

  const toggleSwitch = () => {
    setShowSwitch(!showSwitch);
  };

  return (
    <>
      <div className="debug-box" onClick={toggleSwitch}>
        <div className="debug-icon">!</div>
      </div>
      {showSwitch && (
        <>
          <Debug />
          <div className="TestElements">
            <PlayerTest />
            <BookTest />
          </div>
        </>
      )}
    </>
  );
};

export default DebugSwitch;
