import React, {
  Children,
  PropsWithChildren,
  useContext,
  useState,
} from "react";
import GameStateContext from "../GameStateContext";
import { bookData } from "../data/bookData";
import ReadySwitch from "./ReadySwitch";

const SummaryPager = (props: PropsWithChildren) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  if (!props.children) return null;

  const childArray = Children.toArray(props.children);
  return (
    <div>
      {childArray.map((child, index) => (
        <div
          className={
            index === currentIndex ? "mobile-visible" : "mobile-hidden"
          }
        >
          {child}
        </div>
      ))}
      <br></br>
      <button
        className="summary-pager-button"
        disabled={currentIndex >= childArray.length - 1}
        onClick={() => setCurrentIndex(currentIndex + 1)}
      >
        More info
      </button>
    </div>
  );
};

const MessagesSummary: React.FC = () => {
  const {
    gamePlayers,
    rounds,
    nextRound,
    hotPlayer,
    currentRound,
    selectedBook,
    promptingPlayer,
    playerPoints,
    isGameOver,
  } = useContext(GameStateContext);

  const hotPlayerIsPrompter = hotPlayer?.id === promptingPlayer?.id;
  const hotPlayerHasSubmittedResponse =
    hotPlayer && currentRound?.playerResponses[hotPlayer.id];
  const allPlayersHaveSubmittedResponse =
    Object.keys(currentRound?.playerResponses || {}).length ===
    gamePlayers.length;
  const hotPlayerHasChosenAPlayerResponse =
    currentRound &&
    Object.keys(currentRound.chosenPlayerIds || {}).includes(
      String(hotPlayer?.id) || ""
    );
  const allPlayersHaveChosenAPlayerResponse =
    Object.keys(currentRound?.chosenPlayerIds || {}).length ===
    gamePlayers.length;

  // LOGIC BELOW, DEFINITIONS ABOVE

  const shouldShowNonPromptingMessage =
    promptingPlayer && !selectedBook && !hotPlayerIsPrompter && !isGameOver;

  const shouldShowResponseCompletedMessage =
    selectedBook &&
    hotPlayerHasSubmittedResponse &&
    !allPlayersHaveSubmittedResponse;

  const shouldShowChosenPlayerMessage =
    hotPlayer &&
    selectedBook &&
    allPlayersHaveSubmittedResponse &&
    hotPlayerHasChosenAPlayerResponse &&
    !allPlayersHaveChosenAPlayerResponse;

  const shouldShowAllPlayersHaveChosen =
    hotPlayer &&
    selectedBook &&
    currentRound &&
    allPlayersHaveSubmittedResponse &&
    allPlayersHaveChosenAPlayerResponse;

  const sortedPlayers = (
    playerPoints
      ? gamePlayers.map((player) => ({
          ...player,
          points: playerPoints[player.id] || 0,
        }))
      : gamePlayers.map((player) => ({ ...player, points: 0 }))
  ).sort((a, b) => b.points - a.points);

  const allPlayersReady = gamePlayers.every((player) => player.ready);

  const endRound = () => {
    nextRound();
  };

  return (
    <>
      {" "}
      {shouldShowNonPromptingMessage && (
        <p>
          Please wait while {promptingPlayer?.name} chooses a book category.
        </p>
      )}
      {shouldShowResponseCompletedMessage && (
        <p>
          Response submitted. Please wait for the following players to submit
          their response:
          <ul
            style={{
              listStyleType: "none",
              margin: "1rem",
              fontWeight: "bold",
            }}
          >
            {gamePlayers.map(
              (player) =>
                !currentRound?.playerResponses[player.id] && (
                  <li key={player.id}>{player.name}</li>
                )
            )}
          </ul>
        </p>
      )}
      {shouldShowChosenPlayerMessage && (
        <div>
          <p>
            Chosen response selected. Please wait for the following players to
            choose a response:
          </p>
          <p>
            <ul
              style={{
                listStyleType: "none",
                margin: "1rem",
                fontWeight: "bold",
              }}
            >
              {gamePlayers.map(
                (player) =>
                  currentRound?.chosenPlayerIds[player.id] === undefined && (
                    <li key={player.id}>{player.name}</li>
                  )
              )}
            </ul>
          </p>
        </div>
      )}
      {shouldShowAllPlayersHaveChosen && (
        <div className="round-results">
          <h2>
            Round {rounds.length} results - {selectedBook.name} -{" "}
            {selectedBook.author}
          </h2>

          <p></p>

          <SummaryPager>
            <div>
              <div>
                {Object.entries(currentRound.chosenPlayerIds).map(
                  ([choosingPlayerId, chosenPlayerId]) => {
                    const chosenPlayer =
                      chosenPlayerId !== "correct"
                        ? gamePlayers.find(
                            (player) => player.id === chosenPlayerId
                          )
                        : null;

                    if (choosingPlayerId === hotPlayer.id.toString()) {
                      const selectedBook = bookData.find(
                        (book) => book.id === currentRound.selectedBookId
                      );

                      return (
                        <div key={choosingPlayerId}>
                          {chosenPlayerId === "correct" ? (
                            <p>
                              You chose the correct first line, which was: "
                              {selectedBook?.firstLine}"
                              <p>
                                <b>Therefore you get 1 point!</b>
                              </p>
                            </p>
                          ) : (
                            <>
                              <p>
                                You chose {chosenPlayer?.name}'s submission,
                                which was: "
                                {
                                  currentRound.playerResponses[
                                    chosenPlayerId as keyof typeof currentRound.playerResponses
                                  ]
                                }
                                "
                                <p>
                                  <b>
                                    Therefore {chosenPlayer?.name} gets 1 point!
                                  </b>
                                </p>
                              </p>
                              <p>
                                The real first line was actually: "
                                {selectedBook?.firstLine}"
                              </p>
                            </>
                          )}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  }
                )}
              </div>
              <div>
                <table className="summary-table">
                  <thead>
                    <tr>
                      <th>Player</th>
                      <th>Response</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(currentRound.chosenPlayerIds).map(
                      ([choosingPlayerId, chosenPlayerId]) => {
                        const choosingPlayer = gamePlayers.find(
                          (player) => player.id === parseInt(choosingPlayerId)
                        );
                        const chosenPlayer =
                          chosenPlayerId !== "correct"
                            ? gamePlayers.find(
                                (player) => player.id === chosenPlayerId
                              )
                            : null;

                        const isHotPlayer =
                          choosingPlayerId === hotPlayer.id.toString();

                        return (
                          <tr
                            key={choosingPlayerId}
                            className={isHotPlayer ? "highlight" : ""}
                          >
                            <td>{choosingPlayer?.name}</td>
                            <td>
                              {chosenPlayerId === "correct"
                                ? "Real first line"
                                : `${chosenPlayer?.name}: ${
                                    currentRound.playerResponses[
                                      chosenPlayerId as keyof typeof currentRound.playerResponses
                                    ]
                                  }`}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
              <div>
                <table className="summary-table">
                  <thead>
                    <tr>
                      <th>Player</th>
                      <th>Points</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedPlayers.map((player) => (
                      <tr
                        key={player.id}
                        className={
                          player.id === hotPlayer.id ? "highlight" : ""
                        }
                      >
                        <td>{player.name}</td>
                        <td>{player.points}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p>
                  <ReadySwitch />
                  <button onClick={endRound} disabled={!allPlayersReady}>
                    End round
                  </button>
                </p>
              </div>
            </div>
          </SummaryPager>
        </div>
      )}
    </>
  );
};

export default MessagesSummary;
