// PlayerTest.tsx

import React, { useCallback, useContext } from "react";
import GameStateContext from "../GameStateContext";
import { Player } from "../data/Player";
import { handleShufflePlayers } from "../logic/GameActions";

const PlayerTest: React.FC = () => {
  const {
    gamePlayers,
    setGamePlayers,
    nextPlayer,
    setHotPlayerId,
    promptingPlayer,
    hotPlayer,
  } = useContext(GameStateContext);

  const handleNextPlayer = () => {
    nextPlayer();
  };

  const populatePlayers = useCallback(() => {
    const newPlayers: Player[] = [];
    for (let i = 1; i <= 8; i++) {
      newPlayers.push({
        id: i,
        name: `Player ${i}`,
        ready: true,
      });
    }
    setGamePlayers(newPlayers);
  }, [setGamePlayers]);

  return (
    <div>
      <div>
        <p>
          Select Hot Player:
          <select
            value={hotPlayer ? hotPlayer.id.toString() : ""}
            onChange={(e) => {
              const playerId = parseInt(e.target.value);
              setHotPlayerId(playerId);
            }}
          >
            <option value="">Select Player</option>
            {gamePlayers.map((player) => (
              <option key={player.id} value={player.id}>
                {player.name}
              </option>
            ))}
          </select>
        </p>
        {promptingPlayer && <p>PROMPTING PLAYER: {promptingPlayer.name}</p>}
        <p>
          Players:{" "}
          {gamePlayers.map((player: Player) => (
            <span
              key={player.id}
              style={{
                fontWeight:
                  promptingPlayer?.id === player.id ? "bold" : "normal",
                textDecoration:
                  promptingPlayer?.id === player.id ? "underline" : "none",
              }}
            >
              {player.name},{" "}
            </span>
          ))}
        </p>
      </div>

      <p>
        <button onClick={populatePlayers}>Populate Players</button>
        <button onClick={handleNextPlayer}>Next Player</button>
        <button onClick={() => handleShufflePlayers(setGamePlayers)}>
          Shuffle Players
        </button>
      </p>
    </div>
  );
};

export default PlayerTest;
