import HelpBox from "./components/HelpBox";
import ResponseEntry from "./components/ResponseEntry";
import BookSelection from "./components/BookSelection";
import ResponsePicking from "./components/ResponsePicking";
import MessagesSummary from "./components/MessagesSummary";
import Lobby from "./components/Lobby";
import DebugSwitch from "./components/DebugSwitch";
import GameOverScreen from "./components/GameOverScreen";

function Game() {
  return (
    <>
      <div className="App">
        <HelpBox />
        <div className="ui-title">
          <h2>BOOK-GAME</h2>
        </div>
        <DebugSwitch />
        <Lobby />
        <BookSelection />
        <MessagesSummary />
        <ResponsePicking />
        <ResponseEntry />
        <GameOverScreen />
      </div>
    </>
  );
}

export default Game;
