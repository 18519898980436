import React, { useState } from "react";

const HelpBox = () => {
  const [showBox, setShowBox] = useState(false);

  const toggleBox = () => {
    setShowBox(!showBox);
  };

  return (
    <>
      <div className="help-box" onClick={toggleBox}>
        <div className="help-icon">?</div>
      </div>
      {showBox && (
        <div className="help-box-content">
          <h1
            style={{
              marginBottom: "1px",
              marginTop: "5px",
            }}
          >
            How To Play
          </h1>
          <p style={{ textAlign: "justify" }}>
            The Book Game is a fun party game, emphasising creativity and
            deception. On your turn, select a category of book. Each player,
            including you, will be presented with the title, author's name and
            blurb of a random book from that category.{" "}
          </p>
          <p style={{ textAlign: "justify" }}>
            Using this information as inspiration, imagine what a plausible
            first line of the book could be and submit this as your response.
            All responses will be shuffled up with the real opening line of the
            book and shown to all players.{" "}
          </p>
          <p style={{ textAlign: "justify" }}>
            Click on the response you think is the <b>actual</b> first line of
            the book. You will be awarded a point if you are correct! However,
            if you are incorrect then the player who submitted that response
            will gain a point instead. In order to win, you must both
            successfully identify the first line of the book in each round, and
            submit a response that is plausible and convincing enough to trick
            other players into selecting it.{" "}
          </p>
          <p>
            <i className="help-credits">
              Programming by Shane Rutherfoord-Jones with help from Chatty Geeps
              and Iggy Fisk
            </i>
          </p>
        </div>
      )}
    </>
  );
};

export default HelpBox;
