import React, { useContext, useState } from "react";
import GameStateContext from "../GameStateContext";
import { updateRoundWithChosenPlayerIds } from "../logic/GameActions";
import ConfirmationModal from "./ConfirmationModal"; // Import the modal component

// Define a type for player response
type SelectableResponse = {
  playerId: number | "correct";
  response: string;
};

const cyrb64 = (str: string, seed: number) => {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

const shuffleArray = (array: SelectableResponse[], hotplayerId: number) => {
  // Sort the array based on the hashed response text with hotplayerId as salt
  array.sort((a, b) => {
    // Generate hashes for responses with the hotplayerId as salt
    const hashA = cyrb64(a.response, hotplayerId);
    const hashB = cyrb64(b.response, hotplayerId);
    return hashA - hashB;
  });
};

const ResponsePicking: React.FC = () => {
  const {
    gamePlayers,
    rounds,
    setRounds,
    currentRound,
    selectedBook,
    hotPlayer,
  } = useContext(GameStateContext);

  const [selectedPlayerId, setSelectedPlayerId] = useState<
    number | "correct" | null
  >(null);
  const [isModalVisible, setModalVisible] = useState(false);

  const shouldShowPickingScreen =
    hotPlayer &&
    selectedBook &&
    currentRound &&
    !Object.keys(currentRound.chosenPlayerIds || {}).includes(
      String(hotPlayer?.id) || ""
    ) &&
    Object.keys(currentRound.playerResponses || {}).length ===
      gamePlayers.length;

  const selectablePlayerResponses: SelectableResponse[] = [];

  if (shouldShowPickingScreen && currentRound) {
    const { playerResponses } = currentRound;
    gamePlayers.forEach((player) => {
      const playerId = player.id;
      const response = playerResponses
        ? (playerResponses as Record<string, string>)[playerId] || ""
        : "";
      selectablePlayerResponses.push({ playerId, response });
    });

    selectablePlayerResponses.push({
      playerId: "correct",
      response: selectedBook.firstLine,
    });

    if (hotPlayer) {
      const hotPlayerResponseIndex = selectablePlayerResponses.findIndex(
        (response) => response?.playerId === hotPlayer?.id
      );
      if (hotPlayerResponseIndex !== -1) {
        selectablePlayerResponses.splice(hotPlayerResponseIndex, 1);
      }
    }

    if (selectablePlayerResponses.length > 0 && hotPlayer) {
      shuffleArray(
        selectablePlayerResponses as SelectableResponse[],
        hotPlayer?.id
      );
    }
  }

  const handleClick = (playerId: number | "correct") => {
    setSelectedPlayerId(playerId);
    setModalVisible(true);
  };

  const confirmSelection = () => {
    if (hotPlayer && selectedPlayerId !== null) {
      setRounds(
        updateRoundWithChosenPlayerIds(rounds, hotPlayer.id, selectedPlayerId)
      );
    }
    setModalVisible(false);
  };

  const cancelSelection = () => {
    setModalVisible(false);
  };

  return shouldShowPickingScreen ? (
    <>
      <h2>
        Select the first line of <i>{selectedBook.name}</i> by{" "}
        {selectedBook.author}:
      </h2>

      <ul className="responses">
        {selectablePlayerResponses.map((selectableResponse, index) => (
          <li className="response" key={index}>
            {selectableResponse ? (
              <div className="response-div">
                <div className="response-content">
                  {selectableResponse.response}
                </div>
                <div className="response-submit">
                  <button
                    onClick={() => handleClick(selectableResponse.playerId)}
                  >
                    Select
                  </button>
                </div>
              </div>
            ) : (
              <>DEBUG: selectable responses was falsy</>
            )}
          </li>
        ))}
      </ul>

      {isModalVisible && (
        <ConfirmationModal
          message="Are you sure you want to select this response?"
          onConfirm={confirmSelection}
          onCancel={cancelSelection}
        />
      )}
    </>
  ) : null;
};

export default ResponsePicking;
