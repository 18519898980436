import React, { useState, useContext } from "react";
import GameStateContext from "../GameStateContext";
import { updateRoundWithPlayerResponse } from "../logic/GameActions";

const ResponseEntry: React.FC = () => {
  const { rounds, setRounds, hotPlayer, currentRound, selectedBook } =
    useContext(GameStateContext);

  const [userInput, setUserInput] = useState<string>("");

  const shouldShowResponseEntry =
    selectedBook && hotPlayer && !currentRound?.playerResponses[hotPlayer.id];

  // Function to handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (hotPlayer && userInput.trim() !== "") {
      setRounds(updateRoundWithPlayerResponse(rounds, hotPlayer, userInput));
      console.log("Selected Player:", hotPlayer);
      console.log("User Input:", userInput);
      setUserInput(""); // Clear input field after submission
    }
  };

  return shouldShowResponseEntry ? (
    <>
      {selectedBook ? (
        <div style={{ display: "grid" }}>
          <h2 style={{ margin: "0.5rem" }}>
            <b>"{selectedBook.name}"</b> by {selectedBook.author}
          </h2>
          <h4 style={{ marginBottom: "0.5rem", textAlign: "center" }}>
            Category: <i>{selectedBook.category}</i>
          </h4>
          <div className="book-blurb">
            {selectedBook.blurb.split("\n\n").map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
          <br></br>
        </div>
      ) : (
        <p>No books in the selected category.</p>
      )}
      <form onSubmit={handleSubmit} className="ResponseEntry">
        <label>
          Write a plausible first line:
          <textarea
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            rows={10}
            cols={50}
            maxLength={1000}
          />
        </label>
        <button type="submit">Submit</button>
      </form>
    </>
  ) : null;
};

export default ResponseEntry;
