import React, { useContext } from "react";
import GameStateContext from "../GameStateContext";
import { Book } from "../data/Book";
import {
  updateRoundWithChosenPlayerIds,
  updateRoundWithPlayerResponse,
} from "../logic/GameActions";

import { bookData } from "../data/bookData";
import BookInventory from "./BookInventory";

const BookTest: React.FC = () => {
  const {
    setRounds,
    nextRound,

    gamePlayers,
  } = useContext(GameStateContext);

  const handleNextRoundClick = () => {
    nextRound();
  };

  const generateRandomString = (bookData: Book[]) => {
    // Check if bookData is empty
    if (!bookData || bookData.length === 0) {
      return ""; // Return empty string if no book data is provided
    }

    // Choose a random book from bookData
    const randomBook = bookData[Math.floor(Math.random() * bookData.length)];

    // Deprecated, used to randomly select last lines.
    const selectedLine = randomBook.firstLine;

    // Return the selected line
    return selectedLine;
  };

  const populateResponses = () => {
    gamePlayers.forEach((player) => {
      const randomString = generateRandomString(bookData);
      setRounds((prevRounds) =>
        updateRoundWithPlayerResponse(prevRounds, player, randomString)
      );
    });
  };

  const populateChosenPlayerIds = () => {
    gamePlayers.forEach((player) => {
      let validRandomNumber: number | "correct" = "correct";
      if (Math.random() < 0.5) {
        // Adjust probability as needed
        validRandomNumber = Math.floor(Math.random() * gamePlayers.length);
      }
      setRounds((prevRounds) =>
        updateRoundWithChosenPlayerIds(prevRounds, player.id, validRandomNumber)
      );
    });
  };

  return (
    <div>
      <p>
        <button onClick={handleNextRoundClick}>Next Round</button>
        <button onClick={populateResponses}>Populate Responses</button>
        <button onClick={populateChosenPlayerIds}>
          Populate Chosen Player Ids
        </button>
        <BookInventory />
      </p>
    </div>
  );
};

export default BookTest;
