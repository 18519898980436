import React, { useContext } from "react";
import GameStateContext from "../GameStateContext";
import { categories, Category } from "../data/Book";
import { selectBookByCategory, selectRandomBook } from "../logic/GameActions";

const BookSelection: React.FC = () => {
  const {
    setRounds,

    promptingPlayer,
    hotPlayer,

    selectedBook,
  } = useContext(GameStateContext);

  const handleCategoryClick = (category: Category) => {
    if (!promptingPlayer)
      throw new Error("Prompting player not set when selecting book");

    selectBookByCategory(category, setRounds, promptingPlayer);
  };

  const handleRandomClick = () => {
    if (!promptingPlayer)
      throw new Error("Prompting player not set when selecting book");

    selectRandomBook(setRounds, promptingPlayer);
  };

  const shouldShowBookSelection =
    promptingPlayer && promptingPlayer?.id === hotPlayer?.id && !selectedBook;

  return shouldShowBookSelection ? (
    <div>
      <h2>Select a book category:</h2>
      <div className="book-selection">
        {categories.map((category) => (
          <button key={category} onClick={() => handleCategoryClick(category)}>
            {category}
          </button>
        ))}
        <button onClick={handleRandomClick}>Random</button>
      </div>
    </div>
  ) : null;
};

export default BookSelection;
