export type Category =
  | "Art"
  | "Biography"
  | "Children's"
  | "History"
  | "Horror"
  | "Mystery"
  | "Self Help"
  | "Thriller"
  | "Romance"
  | "Adventure"
  | "Fantasy"
  | "Science Fiction"
  | "Novel"
  | "Non-Fiction";

export interface Book {
  id: number;
  name: string;
  author: string;
  category: Category;
  blurb: string;
  firstLine: string;
  lastLine: string;
  imgUrl: string;
}

export const categories: Category[] = [
  "Art",
  "Biography",
  "Children's",
  "History",
  "Horror",
  "Mystery",
  "Self Help",
  "Thriller",
  "Romance",
  "Adventure",
  "Fantasy",
  "Science Fiction",
  "Non-Fiction",
  "Novel",
];
