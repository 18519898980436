import { Book } from "./Book";
import { Player } from "./Player";

export const MIN_PLAYER_COUNT = 3;
export const POINTS_TO_WIN = 6;

export interface Round {
  promptingPlayerId: Player["id"];
  selectedBookId: Book["id"] | null;
  playerResponses: Record<Player["id"], string>;
  chosenPlayerIds: Record<Player["id"], Player["id"] | "correct">;
  // a value of null means that Player chose the correct response
}
