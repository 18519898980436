// GameStateContext.tsx

import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { Player, demoInitialPlayerNames } from "./data/Player";
import { Round, POINTS_TO_WIN } from "./data/Round";
import {
  calculateCumulativePoints,
  setupGame,
  shufflePlayers,
} from "./logic/GameActions";
import { Book } from "./data/Book";
import { bookData } from "./data/bookData";
import {
  sendPlayers,
  sendRounds,
  onPlayersChange,
  onRoundsChange,
} from "./Multiplayer";

const players = demoInitialPlayerNames;

export interface SharedGameState {
  gamePlayers: Player[];
  rounds: Round[];
}

// Define the shape of your game state
interface GameState {
  gamePlayers: Player[];
  rounds: Round[];
  setGamePlayers: React.Dispatch<React.SetStateAction<Player[]>>;
  setRounds: React.Dispatch<React.SetStateAction<Round[]>>;

  addPlayer: (playerName: string) => number | void;
  nextPlayer: () => void;
  nextRound: () => void;

  hotPlayer: Player | null;
  setHotPlayerId: (playerId: Player["id"]) => void;

  promptingPlayer: Player | null;
  currentRound: Round | null;
  selectedBook: Book | null;
  playerPoints: Record<Player["id"], number> | null;
  isGameOver: boolean;
}

interface GameStateProviderProps {
  gameId: string;
  children: React.ReactNode;
}

// Create the game state context
// @ts-expect-error
const GameStateContext = createContext<GameState>({});

// Export the context
export default GameStateContext;

const GameStateProvider: React.FC<GameStateProviderProps> = ({
  children,
  gameId,
}) => {
  // SHARED MULTIPLAYER STATE
  const [gamePlayers, setGamePlayers] = useState(setupGame(players));
  const [rounds, setRounds] = useState<Round[]>([]);

  // LOCAL MUTABLE STATE
  const [hotPlayerId, setHotPlayerId] = useState<Player["id"] | null>(null);

  //LOCAL COMPUTED STATE
  const currentRound = useMemo(
    () => (rounds.length ? rounds[rounds.length - 1] : null),
    [rounds]
  );

  // Listening to Firebase changes and updating local state
  // These will keep us updated when another client changes the state
  useEffect(() => {
    if (!gameId) return;

    const unsubscribe = onPlayersChange(setGamePlayers, gameId);
    return unsubscribe;
  }, [setGamePlayers, gameId]);

  useEffect(() => {
    if (!gameId) return;

    const unsubscribe = onRoundsChange(setRounds, gameId);
    return unsubscribe;
  }, [setRounds, gameId]);

  // State update functions that also send to Firebase.
  // These should only be called when the current client updates the state
  const setGamePlayersFromThisClient = useCallback(
    (value: React.SetStateAction<Player[]>) => {
      if (typeof value == "function") {
        setGamePlayers((prevPlayers) => {
          const newPlayers = value(prevPlayers);
          sendPlayers(newPlayers, gameId);
          return newPlayers;
        });
      } else {
        setGamePlayers(value);
        sendPlayers(value, gameId);
      }
    },
    [setGamePlayers, gameId]
  );

  const setRoundsFromThisClient = useCallback(
    (value: React.SetStateAction<Round[]>) => {
      if (typeof value == "function") {
        setRounds((prevRounds) => {
          const newRounds = value(prevRounds);
          sendRounds(newRounds, gameId);
          return newRounds;
        });
      } else {
        setRounds(value);
        sendRounds(value, gameId);
      }
    },
    [setRounds, gameId]
  );

  // Saving and reading hotPlayerId from localStorage to maintain state after browser refresh
  useEffect(() => {
    if (hotPlayerId === null) return;
    localStorage.setItem(`game_${gameId}`, hotPlayerId.toString());
  }, [hotPlayerId, gameId]);

  useEffect(() => {
    const savedHotPlayerIdValue = localStorage.getItem(`game_${gameId}`);
    if (!savedHotPlayerIdValue) return;
    const savedHotPlayerId = parseInt(savedHotPlayerIdValue);
    if (savedHotPlayerId !== hotPlayerId) {
      setHotPlayerId(savedHotPlayerId);
    }
    // We only save the hotplayerId when joining a game, not when hotPlayerId changes for whatever reason
  }, [gameId]); // eslint-disable-line react-hooks/exhaustive-deps

  const selectedBook = useMemo(
    () =>
      currentRound
        ? bookData.find((book) => book.id === currentRound.selectedBookId) ||
          null
        : null,
    [currentRound]
  );

  const promptingPlayer = useMemo(
    () =>
      currentRound
        ? gamePlayers.find(
            (player) => player.id === currentRound.promptingPlayerId
          ) || null
        : null,
    [currentRound, gamePlayers]
  );

  const hotPlayer = useMemo(
    () =>
      hotPlayerId !== null
        ? gamePlayers.find((player) => player.id === hotPlayerId) || null
        : null,
    [hotPlayerId, gamePlayers]
  );

  const playerPoints = useMemo(
    () => calculateCumulativePoints(rounds),
    [rounds]
  );

  const isGameOver = useMemo(() => {
    if (playerPoints === null) {
      return false;
    }

    return Object.values(playerPoints).some(
      (points) => points >= POINTS_TO_WIN
    );
  }, [playerPoints]);

  // END STATE
  // Functions below

  const addPlayer = (playerName: string) => {
    if (playerName.trim() === "") {
      // Prevent adding players with empty names
      return;
    }

    const newPlayer: Player = {
      id: gamePlayers.length,
      name: playerName,
      ready: false,
    };

    setGamePlayersFromThisClient((prevPlayers) => [...prevPlayers, newPlayer]);

    // Store the player's name and ID locally
    localStorage.setItem("hotPlayerId", newPlayer.id.toString());
    localStorage.setItem("playerName", newPlayer.name);

    return newPlayer.id;
  };

  const nextPlayer = () => {
    if (promptingPlayer && currentRound) {
      // Find the next position (wrapping around)
      const currentIndex = gamePlayers.findIndex(
        (player) => player.id === promptingPlayer.id
      );
      const nextIndex = (currentIndex + 1) % gamePlayers.length;

      // Find the player with the next position
      const nextPlayer = gamePlayers[nextIndex];

      // Set the new active player index
      if (nextPlayer) {
        const newCurrentRound = {
          ...currentRound,
          promptingPlayerId: nextPlayer.id,
        };

        setRoundsFromThisClient((prevRounds) => {
          const updatedRounds = [...prevRounds];
          updatedRounds[updatedRounds.length - 1] = newCurrentRound;
          return updatedRounds;
        });
      }
    }
  };

  const nextRound = () => {
    setRoundsFromThisClient((prevRounds) => {
      const updatedRounds = [...(prevRounds || [])]; // Ensure prevRounds is not null
      // Update all players' ready status to false
      const readyResetPlayers = gamePlayers.map((player) => ({
        ...player,
        ready: false,
      }));
      let newPromptingPlayerId;

      if (!prevRounds || prevRounds.length === 0) {
        // Shuffle the players and pick one at random
        const shuffledPlayers = shufflePlayers(readyResetPlayers);
        setGamePlayersFromThisClient(shufflePlayers(readyResetPlayers));
        newPromptingPlayerId =
          shuffledPlayers[Math.floor(Math.random() * shuffledPlayers.length)]
            .id;
      } else {
        // Otherwise, find the next player based on the last round's prompting player

        const lastRound = prevRounds[prevRounds.length - 1];
        const currentPlayerIndex = gamePlayers.findIndex(
          (player) => player.id === lastRound.promptingPlayerId
        );
        const nextPlayerIndex = (currentPlayerIndex + 1) % gamePlayers.length;
        newPromptingPlayerId = gamePlayers[nextPlayerIndex].id;
        setGamePlayersFromThisClient(readyResetPlayers);
      }

      const newRound: Round = {
        promptingPlayerId: newPromptingPlayerId,
        selectedBookId: null,
        playerResponses: {},
        chosenPlayerIds: {},
      };
      updatedRounds.push(newRound);
      return updatedRounds;
    });
  };

  // Create the game state object with all the necessary values and functions
  const gameState: GameState = {
    gamePlayers,
    addPlayer,
    setGamePlayers: setGamePlayersFromThisClient,
    nextPlayer,
    rounds,
    setRounds: setRoundsFromThisClient,
    nextRound,
    currentRound,
    promptingPlayer,
    hotPlayer,
    setHotPlayerId,
    selectedBook,
    playerPoints,
    isGameOver,
  };

  return (
    <GameStateContext.Provider value={gameState}>
      {children}
    </GameStateContext.Provider>
  );
};

export { GameStateProvider };
