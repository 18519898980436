// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, set, ref, onValue } from "firebase/database";
import { Round } from "./data/Round";
import { Player } from "./data/Player";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyABs52JQv1dXhgAg1MFPOIRm4D4VI3g2IQ",
  authDomain: "book-game-d7af7.firebaseapp.com",
  databaseURL:
    "https://book-game-d7af7-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "book-game-d7af7",
  storageBucket: "book-game-d7af7.appspot.com",
  messagingSenderId: "1068590210807",
  appId: "1:1068590210807:web:090ec99905de39f5fb3df2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export const sendPlayers = (newState: Player[], gameId: string) => {
  set(ref(database, `games/${gameId}/players`), newState);
};

export const sendRounds = (newState: Round[], gameId: string) => {
  set(ref(database, `games/${gameId}/rounds`), newState);
};

export const onPlayersChange = (
  callback: (newState: Player[]) => void,
  gameId: string
) => {
  const unsubscribe = onValue(
    ref(database, `games/${gameId}/players`),
    (snapshot) => {
      const newPlayers: Player[] | undefined = snapshot.val();
      if (!newPlayers) return;
      callback(newPlayers);
    }
  );

  return unsubscribe;
};

export const onRoundsChange = (
  callback: (newState: Round[]) => void,
  gameId: string
) => {
  const unsubscribe = onValue(
    ref(database, `games/${gameId}/rounds`),
    (snapshot) => {
      const newRounds: Partial<Round>[] | undefined = snapshot.val();
      if (!newRounds) return;

      // Firebase prunes empty arrays and records, put them back in
      const completedRounds = newRounds.map((r) => ({
        promptingPlayerId: r.promptingPlayerId!,
        selectedBookId: r.selectedBookId || null,
        playerResponses: r.playerResponses || {},
        chosenPlayerIds: r.chosenPlayerIds || {},
      }));
      callback(completedRounds);
    }
  );

  return unsubscribe;
};
