import React, { useContext, useEffect, useMemo, useState } from "react";
import GameStateContext from "../GameStateContext";
import { Player } from "../data/Player";
import { MIN_PLAYER_COUNT } from "../data/Round";
import ReadySwitch from "./ReadySwitch";

const Lobby: React.FC = () => {
  const {
    gamePlayers,
    addPlayer,
    nextRound,
    currentRound,
    hotPlayer,
    setHotPlayerId,
  } = useContext(GameStateContext);

  const gameNotYetStarted = !currentRound;
  const gameId = new URLSearchParams(window.location.search).get("gameId");
  const hotPlayerIsInGame =
    gamePlayers.find((player) => player.id === hotPlayer?.id) !== undefined;

  const [playerNameInput, setPlayerNameInput] = useState("");

  const playerNameValid = useMemo(() => {
    // Check name length
    if (
      !playerNameInput ||
      playerNameInput.length === 0 ||
      playerNameInput.length > 20
    ) {
      return false;
    }

    // Check if name contains only alphanumeric characters
    const alphanumericRegex = /^[a-z0-9]+$/i;
    if (!alphanumericRegex.test(playerNameInput)) {
      return false;
    }

    return true;
  }, [playerNameInput]);

  const sufficientPlayersToStart = gamePlayers.length >= MIN_PLAYER_COUNT;
  const allPlayersReady = gamePlayers.every((player) => player.ready);
  const readyToStartGame = sufficientPlayersToStart && allPlayersReady;

  const handleAddPlayer = () => {
    const newPlayerId = addPlayer(playerNameInput);
    setPlayerNameInput(""); // Clear the input field after adding a player
    if (newPlayerId === undefined) {
      throw new Error("New Player had no ID");
    }
    setHotPlayerId(newPlayerId);
  };

  const startGame = () => {
    nextRound();
  };

  // Sanity check function
  const performSanityCheck = (playersFromServer: Player[]) => {
    // Get the locally stored hotPlayerId and playerName
    const localHotPlayerId = localStorage.getItem("hotPlayerId");
    const localPlayerName = localStorage.getItem("playerName");

    if (localHotPlayerId !== null && localPlayerName !== null) {
      const localPlayerId = parseInt(localHotPlayerId, 10);
      const localPlayer = playersFromServer.find(
        (player) => player.id === localPlayerId
      );

      // Sanity check: if the player doesn't exist or the name doesn't match, reset the hotPlayerId
      if (!localPlayer || localPlayer.name !== localPlayerName) {
        localStorage.setItem("hotPlayerId", ""); // Use empty string instead of null
        alert("Player ID conflict detected. Please rejoin the game.");
      }
    }
  };

  useEffect(() => {
    // Only perform sanity check if there are any players in the game
    if (gamePlayers.length > 0) {
      performSanityCheck(gamePlayers);
    }
  }, [gamePlayers]);

  return (
    <div>
      {gameNotYetStarted && (
        <div style={{ textAlign: "center" }} className="game-creator">
          <p>
            <h3>Lobby for game: {gameId}</h3>
          </p>
          <div>
            {!hotPlayerIsInGame && (
              <>
                <input
                  type="text"
                  placeholder="Enter your name"
                  value={playerNameInput}
                  onChange={(e) => setPlayerNameInput(e.target.value)}
                />

                <button onClick={handleAddPlayer} disabled={!playerNameValid}>
                  Join Game
                </button>
              </>
            )}

            {/* Display all players in the game */}
            <div>
              <h3>Players in the Game:</h3>
              <ul
                style={{
                  listStyleType: "none",
                  margin: "1rem",
                  fontWeight: "bold",
                }}
              >
                {gamePlayers.map((player: Player) => (
                  <li
                    key={player.id}
                    style={{
                      textDecoration:
                        player.id === hotPlayer?.id ? "underline" : "none",
                    }}
                  >
                    {player.name}
                  </li>
                ))}
              </ul>
            </div>

            {!sufficientPlayersToStart && (
              <p>
                There are insufficient players to start the game!
                <p>
                  Invite more friends until you have {MIN_PLAYER_COUNT} players.
                </p>
              </p>
            )}
            {hotPlayer && <ReadySwitch />}
            <button onClick={startGame} disabled={!readyToStartGame}>
              Start Game
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Lobby;
