import React, { useContext, useState, ReactNode } from "react";
import GameStateContext from "../GameStateContext";

interface CollapsibleSectionProps {
  title: string;
  children?: ReactNode;
  initiallyCollapsed?: boolean; // New prop
}

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  title,
  children,
  initiallyCollapsed = true, // Default value is true
}) => {
  const [isCollapsed, setCollapsed] = useState(initiallyCollapsed);

  return (
    <div>
      <div
        style={{ cursor: "pointer", textDecoration: "underline" }}
        onClick={() => setCollapsed(!isCollapsed)}
      >
        {title}
      </div>
      {!isCollapsed && <div style={{ marginLeft: "20px" }}>{children}</div>}
    </div>
  );
};

const Debug: React.FC = () => {
  const gameState = useContext(GameStateContext);

  if (!gameState) {
    return <div>Loading...</div>;
  }

  return (
    <div className="DebugContainer">
      <h2>Debug Information</h2>
      <CollapsibleSection title="gamePlayers">
        <pre>{JSON.stringify(gameState.gamePlayers, null, 2)}</pre>
      </CollapsibleSection>
      <CollapsibleSection title="rounds">
        {gameState.rounds.map((round, index) => (
          <div key={index}>
            <CollapsibleSection
              title={`Round ${index + 1}`}
              initiallyCollapsed={false}
            >
              <pre>{JSON.stringify(round, null, 2)}</pre>
            </CollapsibleSection>
          </div>
        ))}
      </CollapsibleSection>
      <CollapsibleSection title="Prompting player">
        <pre>{JSON.stringify(gameState.promptingPlayer, null, 2)} - </pre>
      </CollapsibleSection>
      <CollapsibleSection title="Total Player Points">
      <pre>{JSON.stringify(gameState.playerPoints, null, 2)} - </pre>
      </CollapsibleSection>
    </div>
  );
};

export default Debug;
