import { useContext } from "react";
import GameStateContext from "../GameStateContext";

const ReadySwitch: React.FC = () => {
  const { setGamePlayers, hotPlayer } = useContext(GameStateContext);

  const hotPlayerIsReady = hotPlayer?.ready === true;

  const handleReadyClick = () => {
    setGamePlayers((prevPlayers) =>
      prevPlayers.map((player) =>
        player.id === hotPlayer?.id
          ? { ...player, ready: !player.ready }
          : player
      )
    );
  };

  return (
    <>
      <div className="ready-container">
        <button onClick={handleReadyClick}>Ready</button>
        <input
          type="checkbox"
          checked={hotPlayerIsReady}
          readOnly
          className="ready-checkbox"
        />
      </div>
    </>
  );
};

export default ReadySwitch;
