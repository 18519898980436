import React, { useState } from "react";
import { bookData } from "../data/bookData";
import { Book } from "../data/Book";

const BookInventory: React.FC = () => {
  const [selectedBook, setSelectedBook] = useState<Book | null>(null);
  const books = bookData;
  const [showInventory, setShowInventory] = useState(false);

  const toggleInventory = () => {
    setShowInventory(!showInventory);
  };

  const handleBookChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedBookName = event.target.value;
    const book = books.find((book) => book.name === selectedBookName) || null;
    setSelectedBook(book);
  };

  const bookCount = books.length;

  const booksByCategory = books.reduce((acc: Record<string, number>, book) => {
    acc[book.category] = (acc[book.category] || 0) + 1;
    return acc;
  }, {});

  return (
    <>
      <button onClick={toggleInventory}>Show Book Inventory</button>
      {showInventory && (
        <div className="book-inventory">
          <div className="book-summary">
            <h2>Summary Information</h2>
            <p>Total number of books: {bookCount}</p>
            <b>Books by Category:</b>
            <ul>
              {Object.entries(booksByCategory).map(([category, count]) => (
                <li key={category}>
                  {category}: {count}
                </li>
              ))}
            </ul>
          </div>
          <select onChange={handleBookChange} defaultValue="">
            <option value="" disabled>
              Select a book
            </option>
            {books.map((book) => (
              <option key={book.name} value={book.name}>
                {book.name}
              </option>
            ))}
          </select>
          {selectedBook ? (
            <div style={{ display: "grid" }}>
              <h2 style={{ margin: "0.5rem" }}>
                <b>"{selectedBook.name}"</b> by {selectedBook.author}
              </h2>
              <h4 style={{ marginBottom: "0.5rem", textAlign: "center" }}>
                Category: <i>{selectedBook.category}</i>
              </h4>
              <div className="book-blurb">
                {selectedBook.blurb.split("\n\n").map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </div>
              <br></br>
              First line: <i>{selectedBook.firstLine}</i>
            </div>
          ) : (
            <p>No book selected.</p>
          )}
        </div>
      )}
    </>
  );
};

export default BookInventory;
