import { Player } from "../data/Player";
import { Category } from "../data/Book";
import { bookData } from "../data/bookData";
import { Round } from "../data/Round";

export function initPlayer(name: string, id: number, ready: boolean): Player {
  return {
    id,
    name,
    ready,
  };
}

export function addPlayer(players: Player[], playerName: string): Player[] {
  const newPlayer = initPlayer(playerName, players.length, false);
  return [...players, newPlayer];
}

export function shufflePlayers(players: Player[]): Player[] {
  // Create a new array with the same players, but positioned in a random order
  const shuffledPlayers = [...players].sort(() => Math.random() - 0.5);
  return shuffledPlayers;
}

export function determineStartingPlayer(players: Player[]): Player {
  const startingPlayer = players[Math.floor(Math.random() * players.length)];
  return startingPlayer;
}

export function setupGame(players: string[]): Player[] {
  const gamePlayers: Player[] = players.map((playerName, index) => ({
    id: index,
    name: playerName,
    ready: false,
  }));

  return gamePlayers;
}

export function handleShufflePlayers(
  setGamePlayers: React.Dispatch<React.SetStateAction<Player[]>>
): void {
  setGamePlayers((prevPlayers) => shufflePlayers(prevPlayers));
}

export function selectBookByCategory(
  category: Category,
  setRounds: React.Dispatch<React.SetStateAction<Round[]>>,
  activePlayer: Player
): void {
  setRounds((prevRounds) => {
    const currentRoundIndex = prevRounds.length - 1;
    const updatedRounds = [...prevRounds];

    if (!activePlayer) {
      console.error("Active player not found.");
      return prevRounds;
    }

    // Get the IDs of all books that have already been selected in previous rounds
    const selectedBookIds = updatedRounds
      .map((round) => round.selectedBookId)
      .filter((id): id is number => id !== null);

    // Filter out books that have already been selected
    const categoryBooks = bookData.filter(
      (book) => book.category === category && !selectedBookIds.includes(book.id)
    );

    if (categoryBooks.length === 0) {
      console.error(
        `No books found in the "${category}" category or all have been selected.`
      );
      return prevRounds;
    }

    const randomIndex = Math.floor(Math.random() * categoryBooks.length);
    const selectedBookId = categoryBooks[randomIndex].id;

    const newRound: Round = {
      ...updatedRounds[currentRoundIndex],
      promptingPlayerId: activePlayer.id,
      selectedBookId,
      playerResponses: updatedRounds[currentRoundIndex].playerResponses,
      chosenPlayerIds: updatedRounds[currentRoundIndex].chosenPlayerIds,
    };

    updatedRounds[currentRoundIndex] = newRound;
    return updatedRounds;
  });
}

export function selectRandomBook(
  setRounds: React.Dispatch<React.SetStateAction<Round[]>>,
  activePlayer: Player
): void {
  setRounds((prevRounds) => {
    const currentRoundIndex = prevRounds.length - 1;
    const updatedRounds = [...prevRounds];

    if (!activePlayer) {
      console.error("Active player not found.");
      return prevRounds;
    }

    // Get the IDs of all books that have already been selected in previous rounds
    const selectedBookIds = updatedRounds
      .map((round) => round.selectedBookId)
      .filter((id): id is number => id !== null);

    // Filter out books that have already been selected
    const availableBooks = bookData.filter(
      (book) => !selectedBookIds.includes(book.id)
    );

    if (availableBooks.length === 0) {
      console.error("No books found in the dataset or all have been selected.");
      return prevRounds;
    }

    const randomIndex = Math.floor(Math.random() * availableBooks.length);
    const selectedBookId = availableBooks[randomIndex].id;

    const newRound: Round = {
      ...updatedRounds[currentRoundIndex],
      promptingPlayerId: activePlayer.id,
      selectedBookId,
      playerResponses: updatedRounds[currentRoundIndex].playerResponses,
      chosenPlayerIds: updatedRounds[currentRoundIndex].chosenPlayerIds,
    };

    updatedRounds[currentRoundIndex] = newRound;
    return updatedRounds;
  });
}

export function calculatePoints(
  chosenPlayerIds: Round["chosenPlayerIds"]
): Record<Player["id"], number> {
  const pointsAwarded: Record<Player["id"], number> = {};

  // Initialize pointsAwarded object with default value of 0 for all players
  Object.keys(chosenPlayerIds).forEach((key) => {
    pointsAwarded[parseInt(key)] = 0;
  });

  // Calculate points based on chosenPlayerIds
  Object.keys(chosenPlayerIds).forEach((key) => {
    const playerId = parseInt(key);
    const chosenPlayerId = chosenPlayerIds[playerId];

    if (chosenPlayerId === "correct") {
      // If chosenPlayerId is null, player chose the correct response, award 1 point
      pointsAwarded[playerId] += 1;
    } else {
      // If chosenPlayerId is not null, the chosen player should be awarded 1 point
      pointsAwarded[chosenPlayerId] += 1;
    }
  });

  return pointsAwarded;
}

export function calculateCumulativePoints(
  rounds: Round[] | null
): Record<Player["id"], number> | null {
  if (!rounds) return null;

  const cumulativePoints: Record<Player["id"], number> = {};

  // Iterate over each round and aggregate points for each player
  rounds.forEach((round) => {
    const roundPoints = calculatePoints(round.chosenPlayerIds);
    Object.entries(roundPoints).forEach(([playerId, points]) => {
      const playerIdInt = parseInt(playerId);
      cumulativePoints[playerIdInt] =
        (cumulativePoints[playerIdInt] || 0) + points;
    });
  });

  return cumulativePoints;
}

export function updateRoundWithPlayerResponse(
  rounds: Round[],
  selectedPlayer: Player,
  userInput: string
): Round[] {
  // Get the current round index
  const currentRoundIndex = rounds.length - 1;

  // Get the current round
  const currentRound: Round = rounds[currentRoundIndex];

  // Create a new round object to avoid directly modifying the previous round
  const updatedRound: Round = {
    ...currentRound,
    playerResponses: {
      ...currentRound.playerResponses,
      [selectedPlayer.id]: userInput,
    },
  };

  // Replace the current round in the rounds array with the updated round
  const updatedRounds = [...rounds];
  updatedRounds[currentRoundIndex] = updatedRound;

  return updatedRounds;
}

export function updateRoundWithChosenPlayerIds(
  rounds: Round[],
  hotPlayerId: number | null,
  chosenPlayerId: number | "correct"
): Round[] {
  if (hotPlayerId == null) {
    throw new Error("No hot player when choosing response");
  }
  // Get the current round index
  const currentRoundIndex = rounds.length - 1;

  // Get the current round
  const currentRound: Round = rounds[currentRoundIndex];

  // Create a new round object to avoid directly modifying the previous round
  const updatedRound: Round = {
    ...currentRound,
    chosenPlayerIds: {
      ...currentRound.chosenPlayerIds,
      [hotPlayerId]: chosenPlayerId,
    },
  };

  // Replace the current round in the rounds array with the updated round
  const updatedRounds = [...rounds];
  updatedRounds[currentRoundIndex] = updatedRound;

  return updatedRounds;
}
