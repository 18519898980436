import { Book } from "./Book";

export const bookData: Book[] = [
  {
    id: 10001,
    name: "The Picture of Dorian Gray",
    author: "Oscar Wilde",
    category: "Mystery",
    blurb:
      "Is the price of eternal youth worth a man's soul?\n\nThe exceptionally handsome Dorian Gray is a model and the muse for a young artist, Basil Hallward. Through Basil, Dorian meets Lord Henry Wotton, who values only the pleasurable things in life with no regard for morality. He makes Dorian realize that one day his famed beauty will fade, and he will be left with nothing. Dorian decides to sell his soul so that a portrait of him will age in his place. As he indulges in every vice and selfish whim, his portrait grows increasingly hideous. But will he learn the true cost of his corruption in time to change his ways?",
    firstLine:
      "The studio was filled with the rich odor of roses, and when the light summer wind stirred amidst the trees of the garden, there came through the open door the heavy scent of the lilac, or the more delicate perfume of the pink-flowering thorn.",
    lastLine:
      "It was not till they had examined the rings that they recognized who it was.",
    imgUrl: "dorian-gray.jpg",
  },
  {
    id: 10002,
    name: "Foundation",
    author: "Isaac Asimov",
    category: "Science Fiction",
    blurb:
      "Long after Earth was forgotten, a peaceful and unified galaxy took shape, an Empire governed from the majestic city-planet of Trantor. The system worked, and grew, for countless generations. Everyone believed it would work forever. Everyone except Hari Seldon.\n\nAs the great scientific thinker of his age, Seldon count not be ignored. Reluctantly, the Commission of Public Safety agreed to finance the Seldon Plan. The coming disaster was predicted by Seldon's advances in psychohistory, the mathematics of very large human numbers, and it could not be averted. The Empire was doomed. Soon Trantor would lie in ruins. Chaos would overtake humanity. But the Seldon Plan was a long-term strategy to minimize the worst of what was to come.\n\nTwo Foundations were set up at the opposite ends of the galaxy. Of the Second nothing can be told. It guards the secrets of psychohistory. Foundation is the story of the First Foundation, on the remote planet of Terminus, from which those secrets were withheld.",
    firstLine:
      "His name was Gaal Dornick and he was just a country boy who had never seen Trantor before.",
    lastLine:
      "Let my successors solve those new problems, as I have solved the one of today.",
    imgUrl: "foundation.jpg",
  },
  {
    id: 10003,
    name: "Neuromancer",
    author: "William Gibson",
    category: "Science Fiction",
    blurb:
      "The Matrix: a world within a world, a graphic representation of the databanks of every computer in the human system; a consensual hallucination experienced daily by billions of legitimate users in the Sprawl alone. And by Case, computer cowboy, until his nervous system is grievously maimed by a client he double-crossed. Japanese experts in nerve splicing and microbionics have left him broke and close to dead. But at last Case has found a cure. He's going back into the system. Not for the bliss of cyberspace but to steal again, this time from the big boys, the almighty megacorps. In return, should he survive, he will stay cured.",
    firstLine:
      "The sky above the port was the color of television, tuned to a dead channel.",
    lastLine: "He never saw Molly again.",
    imgUrl: "neuromancer.jpg",
  },
  {
    id: 1004,
    name: "Sophie's World",
    author: "Jostein Gaarder",
    category: "Novel",
    blurb:
      "Looking in her mailbox one day, a fourteen-year-old Norweigan schoolgirl called Sophie Amundsen finds two surprising pieces of paper. On them are written the questions: 'Who are you?' and 'Where does the world come from?'\n\nThe writer is an enigmatic philosopher called Albert Knox, and his two teasing questions are the beginning of an extraordinary tour through the history of Western Philosophy from the pre-Socratics to Sartre. In a series of brilliantly entertaining letters, and then in person (with his dog, Hermes), Albert Knox opens Sophie's enquiring mind to the fundamental questions that philosophers have been asking since the dawn of civilisation.\n\nBut as soon as Sophie begins to find her feet in this dazzling, exciting new world, she and Albert find themselves caught up in a plot which is itself a most perplexing philosophical conundrum...",
    firstLine: "Sophie Amundsen was on her way home from school.",
    lastLine: '"We\'ll both go, Dad."',
    imgUrl: "sophies-world.jpg",
  },
  {
    id: 10005,
    name: "The Bell Jar",
    author: "Sylvia Plath",
    category: "Novel",
    blurb:
      "A girl lives in an out-of-the-way town for nineteen years, so poor she can't afford a magazine and then she gets a scholarship to college and wins a prize here and there and ends up steering New York like her own private car. Only I wasn't steering anything. Not even myself.\n\nWorking in New York one hot summer, Esther Greenwood is on the bring of her future. Yet she is also on the edge of a darkness that makes her world increasingly unreal. In this vivid and unforgettable novel, Esther's vision of the world shimmers and shifts: day-to-day New York living, her crazed men-friends, and hot dinner dances...",
    firstLine:
      "It was a queer, sultry summer, the summer they electrocuted the Rosenbergs, and I didn't know what I was doing in New York.",
    lastLine:
      "The eyes and the faces all turned themselves towards me, and guiding myself by them, as by a magical thread, I stepped into the room.",
    imgUrl: "the-bell-jar.jpg",
  },
  {
    id: 10006,
    name: "Sapiens",
    author: "Yuval Noah Harari",
    category: "History",
    blurb:
      "100,000 years ago, at least six human species inhabited the earth. Today there is just one. Us. Homo sapiens.\n\nHow did our species succeed in the battle for dominance? Why did our foraging ancestors come together to create cities and kingdoms? How did we come to believe in gods, nations and human rights? And what will our world be like in the millennia to come?\n\nBold, wide-ranging and provocative, Sapiens challenges everything we thought we know about being human: our thoughts, our actions, our power... and our future.",
    firstLine:
      "About 13.5 billion years ago, matter, energy, time and space came into being in what is known as the Big Bang.",
    lastLine:
      "Is there anything more dangerous than dissatisfied and irresponsible gods who don't know what they want?",
    imgUrl: "sapiens.jpg",
  },
  {
    id: 1007,
    name: "The Husband's Secret",
    author: "Liane Moriarty",
    category: "Thriller",
    blurb:
      "Mother of three and wife of John-Paul, Cecilia discovers an old envelope in the attic. Written in her husband's hand, it says: to be opened only in the event of my death.\n\nCurious, she opens it- and time stops.\n\nJohn-Paul's letter confesses to a terrible mistake which, if revealed, would wreck their family as well as the lives of others.\n\nCecilia wants to do the right thing, but right for who? If she protects her family by staying silent, the truth will worm through her heart. But if she reveals her husband's secret, she will hurt those she loves most...",
    firstLine: "It was all because of the Berlin Wall.",
    lastLine:
      "She pushed down the handle of the door and staggered out into the relentless rain.",
    imgUrl: "the-husbands-secret.jpg",
  },
  {
    id: 10008,
    name: "The life-changing magic of tidying up",
    author: "Marie Kondo",
    category: "Self Help",
    blurb:
      "Despite constant efforts to declutter your home, do papers still accumulate like snowdrifts and clothes pile up like a tangled mess of noodles?\n\nJapanese cleaning consultant Marie Kondo takes tidying to a whole new level, promising that if you properly simplify and organize your home once, you'll never have to do it again. Most methods advocate a room-by-room or little-by-little approach, which doom you to pick away at your piles of stuff forever.\nThe KonMari Method, with its revolutionary category-by-category system, leads to lasting results. In fact, none of Kondo's clients have lapsed (and she still has a three-month waiting list).\n\nWith detailed guidance for determining which items in your house \"spark joy\" (and which don't), this international best seller featuring Tokyo's newest lifestyle phenomenon will help you clear your clutter and enjoy the unique magic of a tidy home-- and the calm, motivated mindset it can inspire.",
    firstLine:
      "When I tell people that my profession is teaching others how to tidy, I am usually met with looks of astonishment.",
    lastLine: "Life truly begins after you have put your house in order",
    imgUrl: "the-life-changing-magic-of-tidying-up.jpg",
  },
  {
    id: 10009,
    name: "Evening News",
    author: "Marly Swick",
    category: "Novel",
    blurb:
      "When one of your children accidentally kills the other, what happens to the ties of love that bind families together?\n\nGiselle is living the ultimate American dream: her new marriage to college lecturer Dan is working well, after the disaster of her first to her childhood sweetheart; she has had a lovely new baby, Trina, with Dan; and her own son Teddy seems to like his new life in the unbroken sunshine of California.\n\nThen a terrible accident rips their lives apart - Teddy accidentally fires a neighbour's gun and kills his sister - and the certainties on which they had built their perfect lifestyle seem suddenly meaningless.\n\nEvening News is an unflinchingly honest novel about the fragile relationships in stepfamilies, the nature of love between parents and children, and what happens when the two collide.",
    firstLine:
      "His sister, Trina, is sitting in her plastic wading pool, bright blue with purple whales stamped on it.",
    lastLine: "It was enough to break your heart- even then.",
    imgUrl: "evening-news.jpg",
  },
  {
    id: 10010,
    name: "The Prince",
    author: "Niccolo Machiavelli",
    category: "Non-Fiction",
    blurb:
      "Machiavelli's famous portrait of the prince still 'retains its power to fascinate, to frighten, and to instruct.'\n\nRejecting the traditional values of political theory, Niccolo Machiavelli (1469-1527) drew upon his own experiences of office in the turbulent Florentine republic when he wrote his celebrated treatise on statecraft. While Machiavelli was only one of the many Florentine 'prophets of force', he differed from the ruling elite in recognizing the complexity and fluidity of political life. As Anthony Grafton states in his new introduction, in insisting that each political situation must be judged individually, Machiavelli became 'the political teacher of Europe'.\n\nMachiavelli may have given his name to 'Machevill', the intriguer of Jacobean tragedies, but he also provided 'the core of the doctrines of \"reason of state\" that became the basic political education of modern Europe'.",
    firstLine:
      "Men who are anxious to win the favour of a Prince nearly always follow the custom of presenting themselves to him with the possessions they value most, or with things they know especially please him; so we often see princes given horses, weapons, cloth of gold, precious stones, and similar ornaments worthy of their high position.",
    lastLine:
      "Let your illustrious House undertake this task, therefore with the courage and hope which belong to just enterprises, so that, under your standard, our country may be ennobled, and under your auspices what Petrarch said may come to pass.",
    imgUrl: "the-prince.jpg",
  },
  {
    id: 10011,
    name: "Charlotte's Web",
    author: "E. B. White",
    category: "Children's",
    blurb:
      "'I don't want to die! Save me, somebody! Save me!'\n\nOne spring morning a little girl called Fern rescues a runt and names him Wilbur. But then Wilbur is sent to live on a farm where he meets Charlotte, a beautiful large grey spider. They become best friends and, when Wilbur is faced with the usual fate of nice fat little pigs, Charlotte must find a clever way to save him.",
    firstLine:
      "'Where's Papa going with that axe?' said Fern to her mother as they were setting the table for breakfast.",
    lastLine:
      "It is not often that someone comes along who is a true friend and a good writer; Charlotte was both.",
    imgUrl: "charlottes-web.jpg",
  },
  {
    id: 10012,
    name: "The New Geography of Jobs",
    author: "Enrico Moretti",
    category: "Non-Fiction",
    blurb:
      "We're used to thinking of the United States in opposing terms: red versus blue, haves versus have-nots. But today there are three Americas. At one extreme are the brain hubs - cities like San Francisco, Boston and Durham - with workers who are among the most productive, creative, and best paid on the planet. At the other extreme are former manufacturing capitals, which are rapidly losing jobs and residents. The rest of America could go either way. For the past thirty years, the three Americas have been growing apart at an accelerating rate, and this divergence is reshaping the very fabric of our society, affecting all aspects of our lives, from health and education to family stability and political engagement. But the winners and loser aren't necessarily who you'd expect.\n\nEnrico Moretti's groundbreaking research shows that you don't have to be a scientist or an engineer to thrive in one of the brain hubs. Carpenters, taxi drivers, teachers, nurses, and other local service jobs are created at a ratio of five-to-one in the brain hubs, raising salaries and standard of living for all. Dealing with this split - supporting growth in the hubs while arresting the decline elsewhere - is the challenge of the century, and The New Geography of Jobs lights the way.",
    firstLine:
      "Every year, millions of Chinese and Indian farmers leave their villages and move to sprawling urban centers to work in an ever-growing number of factories.",
    lastLine:
      "Our unparalleled ability to attract and welcome the most creative individuals from all over the world, the dynamism of our workplaces, and the strength of our brain hubs give us a significant head start in this new global economy; it is up to us to keep it.",
    imgUrl: "the-new-geography-of-jobs.jpg",
  },
  {
    id: 10013,
    name: "Burial Rites",
    author: "Hannah Kent",
    category: "Mystery",
    blurb:
      "Northern Iceland, 1829.\n\nA woman condemned to death for murdering her lover. A family forced to take her in. A priest tasked with absolving her.\n\nBut all is not as it seems, and time is running out: winter is coming, and with it the execution date.\n\nOnly she can know the truth. This is Agnes's story.",
    firstLine:
      "Assistant reverand Thorvardur Jonsson was inside the small farmstead adjoined to the church of Breidabolstadur, repairing the hearth with new stones, when he heard his father clear his throat in the doorway.",
    lastLine:
      "The sudden sound of the first axe fall echoed throughout the valley.",
    imgUrl: "burial-rites.jpg",
  },
  {
    id: 10014,
    name: "The Beginning of Infinity",
    author: "David Deutsch",
    category: "Non-Fiction",
    blurb:
      "In our search for truth, how far have we advanced? This uniquely human quest for good explanations has driven amazing improvements in everything from scientific understanding and technology to politics, moral values and human welfare. But will progress end, either in catastrophe or completion - or will it continue infinitely?\n\nIn this profound and seminal book, David Deutsch explores the furthest reaches of our current understanding, taking in the Infinity Hotel, supernovae and the nature of optimism, to install in all of us a wonder at what we have achieved - and the fact that this is only the beginning of humanity's infinite possibility.",
    firstLine:
      "To unaided human eyes, the universe beyond our solar system looks like a few thousand glowing dots in the night sky, plus the faint, hazy streaks of the Milky Way.",
    lastLine:
      "All we can choose is whether it is an infinity of ignorance or of knowledge, wrong or right, death or life.",
    imgUrl: "the-beginning-of-infinity.jpg",
  },
  {
    id: 10015,
    name: "The Background Of Passion Music",
    author: "Basil Smallman",
    category: "Art",
    blurb:
      "Many of those who sing in or listen to the St Matthew Passion at Eastertime have little knowledge of what lies behind it in the history of music and of church ritual. In this book the author attempts to relate Bach's Passion music to its historical background and to show how Bach built upon the techniques of the previous century, infusing into them a new and vital spirit.",
    firstLine:
      "In the summer of 1722, the town council of Leipzig were seeking to appoint a new Cantor for the ancient Lutheran school of St Thomas, in succesion to the famous composer Johann Kuhnau who had died on June 5th after more than twenty years' service.",
    lastLine:
      "It may well be that as we approach more nearly to a true assessment of Bach's greatness we shall be compelled to pay greater heed to the value of the achievements of his predecessors.",
    imgUrl: "the-background-of-passion-music.jpg",
  },
  {
    id: 11,
    name: "Alice's Adventures in Wonderland",
    author: "Lewis Carroll",
    category: "Children's",
    blurb:
      "\"Alice's Adventures in Wonderland\" by Lewis Carroll is a timeless tale that whisks readers away to a fantastical world brimming with whimsy and wonder. The story follows young Alice, who tumbles down a rabbit hole into a bizarre and enchanting land where the rules of logic and reality are turned upside down. As she navigates this curious realm, Alice encounters a host of peculiar characters, including the enigmatic Cheshire Cat, the frantic White Rabbit, the eccentric Mad Hatter, and the tyrannical Queen of Hearts. Each encounter challenges her perception of reality and tests her wit and courage.\n\nThis beloved children's classic is celebrated for its playful use of language, imaginative settings, and memorable characters. Carroll's masterful storytelling invites readers of all ages to explore themes of identity, curiosity, and the absurdity of the adult world through the eyes of a child. \"Alice's Adventures in Wonderland\" remains a cherished literary treasure, captivating generations with its dreamlike narrative and enduring charm.",
    firstLine:
      "Alice was beginning to get very tired of sitting by her sister on the bank, and of having nothing to do.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 12,
    name: "Through the Looking-Glass",
    author: "Lewis Carroll",
    category: "Children's",
    blurb:
      'In "Through the Looking-Glass," Lewis Carroll invites readers to step into a whimsical world where logic is turned on its head and imagination reigns supreme. This enchanting sequel to "Alice\'s Adventures in Wonderland" follows young Alice as she ventures through a magical mirror into a fantastical realm. Here, she encounters a host of peculiar characters, including the enigmatic Red Queen, the bumbling White Knight, and the mischievous Tweedledee and Tweedledum. Each encounter is a puzzle, each conversation a riddle, as Alice navigates a landscape where the ordinary rules of reality do not apply.\n\nAs Alice journeys through this topsy-turvy land, she finds herself part of a giant chess game, where she must traverse the board to become a queen. Along the way, she experiences a series of surreal adventures that challenge her perception and wit. Carroll\'s playful use of language, clever wordplay, and imaginative scenarios create a timeless tale that continues to captivate readers of all ages. "Through the Looking-Glass" is a celebration of curiosity, creativity, and the boundless possibilities of the mind.',
    firstLine:
      "One thing was certain, that the white kitten had had nothing to do with it:—it was the black kitten’s fault entirely.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 13,
    name: "The Hunting of the Snark: An Agony in Eight Fits",
    author: "Lewis Carroll",
    category: "Children's",
    blurb:
      '"The Hunting of the Snark: An Agony in Eight Fits" by Lewis Carroll is a whimsical and enigmatic narrative poem that takes readers on a fantastical journey filled with absurdity and wit. The story follows a motley crew of ten characters, including a Bellman, a Boots, and a Barrister, as they embark on a quest to capture the elusive and mysterious Snark. Each "fit" or canto of the poem presents a new episode in their adventure, blending Carroll\'s signature wordplay and nonsensical humor with a sense of underlying tension and intrigue.\n\nAs the crew navigates through strange landscapes and encounters bizarre creatures, the poem delves into themes of ambition, folly, and the unknown. Carroll\'s playful use of language and inventive verse structure create a surreal and captivating experience, leaving readers to ponder the true nature of the Snark and the meaning behind the characters\' pursuit. "The Hunting of the Snark" remains a timeless classic, celebrated for its imaginative storytelling and its ability to delight and perplex audiences of all ages.',
    firstLine: '"Just the place for a Snark!" the Bellman cried.',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 16,
    name: "Peter Pan",
    author: "J Barrie",
    category: "Fantasy",
    blurb:
      '"Peter Pan" by J.M. Barrie is a timeless fantasy novel that whisks readers away to the enchanting world of Never-Never Land. The story follows the adventures of Peter Pan, a mischievous and eternally youthful boy who can fly and never grows up. Alongside his loyal fairy companion, Tinker Bell, Peter leads the Darling children—Wendy, John, and Michael—on a magical journey filled with wonder and peril. In this fantastical realm, they encounter mermaids, fairies, and the Lost Boys, a group of children who, like Peter, refuse to grow up.\n\nThe narrative is rich with themes of innocence, the bittersweet nature of growing up, and the power of imagination. Central to the plot is the ongoing battle between Peter Pan and his arch-nemesis, the villainous Captain Hook, a fearsome pirate who seeks revenge on Peter for the loss of his hand. With its captivating characters and whimsical setting, "Peter Pan" continues to enchant readers of all ages, inviting them to explore the boundless possibilities of childhood and the enduring magic of dreams.',
    firstLine: "All children, except one, grow up.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 18,
    name: "The Federalist Papers",
    author: "Alexander Hamilton",
    category: "History",
    blurb:
      '"The Federalist Papers" is a seminal collection of 85 essays written by Alexander Hamilton, James Madison, and John Jay, under the pseudonym "Publius." Published between 1787 and 1788, these essays were crafted to support the ratification of the United States Constitution and to elucidate the principles underpinning the new framework of government. The authors meticulously argue for a strong, yet balanced federal system, addressing concerns about the potential for tyranny and the need for checks and balances.\n\nThe essays delve into various aspects of the proposed Constitution, including the separation of powers, the functions of the executive, legislative, and judicial branches, and the importance of a union for the stability and security of the fledgling nation. "The Federalist Papers" not only played a crucial role in shaping American political thought but also continue to serve as an essential reference for understanding the intentions of the Founding Fathers and the foundational principles of American constitutional law.',
    firstLine:
      "After an unequivocal experience of the inefficacy of the subsisting federal government, you are called upon to deliberate on a new Constitution for the United States of America.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 21,
    name: "Aesop's Fables",
    author: " Aesop",
    category: "Children's",
    blurb:
      "\"Aesop's Fables,\" translated by George Fyler Townsend, is a timeless collection of moral stories attributed to the ancient Greek storyteller Aesop. This anthology brings together a variety of short tales that feature animals and inanimate objects endowed with human traits, each delivering a poignant lesson about human nature and ethics. From the cunning fox and the wise tortoise to the boastful hare and the industrious ant, these fables have been cherished for generations for their simplicity, wit, and enduring wisdom.\n\nTownsend's translation captures the essence of Aesop's original narratives, making them accessible to English-speaking audiences while preserving their classic charm. Each fable concludes with a moral that distills the story's message into a universal truth, offering readers of all ages valuable insights into virtues such as honesty, humility, and perseverance. Whether read for entertainment or enlightenment, \"Aesop's Fables\" remains a beloved literary treasure that continues to impart timeless lessons on life and human behavior.",
    firstLine:
      "A Wolf, meeting with a Lamb astray from the fold, resolved not to lay violent hands on him, but to find some plea to justify to the Lamb the Wolf’s right to eat him.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 23,
    name: "Narrative of the Life of Frederick Douglass, an American Slave",
    author: "Frederick Douglass",
    category: "Biography",
    blurb:
      '"Narrative of the Life of Frederick Douglass, an American Slave" is a powerful autobiography that chronicles the harrowing journey of Frederick Douglass from enslavement to freedom. Born into the brutal conditions of slavery in the early 19th century, Douglass provides a first-hand account of the physical and psychological torment endured by enslaved people. His narrative exposes the inhumanity of the institution of slavery, detailing the relentless labor, severe punishments, and the constant threat of violence that defined his early life.\n\nDouglass\'s story is not only a testament to his resilience and intellect but also a compelling argument for the abolition of slavery. Through his eloquent prose, he recounts his quest for literacy as an act of rebellion and empowerment, ultimately leading to his daring escape to the North. Once free, Douglass becomes a leading voice in the abolitionist movement, using his experiences to advocate for justice and equality. His narrative remains a seminal work in American literature, offering profound insights into the struggles for freedom and human dignity.',
    firstLine:
      "I was born in Tuckahoe, near Hillsborough, and about twelve miles from Easton, in Talbot county, Maryland.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 24,
    name: "O Pioneers!",
    author: "Willa Cather",
    category: "Novel",
    blurb:
      '"O Pioneers!" by Willa Cather is a poignant and evocative novel that captures the spirit of the American frontier through the life of Alexandra Bergson, a determined and resilient Swedish-American woman. Set in the vast, untamed prairies of Nebraska, the story unfolds as Alexandra inherits her family\'s struggling farm and, through sheer will and innovative thinking, transforms it into a prosperous enterprise. Her journey is one of perseverance, sacrifice, and an unyielding connection to the land, reflecting the broader immigrant experience and the challenges faced by women pioneers.\n\nThe novel delves deeply into themes of family, love, and the relentless pursuit of dreams amidst the harsh realities of frontier life. Alexandra\'s relationships with her siblings, particularly her troubled brother Emil, and her complex feelings for her childhood friend Carl Linstrum, add layers of emotional depth to the narrative. Cather\'s lyrical prose and vivid descriptions of the Nebraska landscape create a rich, immersive reading experience, making "O Pioneers!" a timeless exploration of human endurance and the transformative power of the American frontier.',
    firstLine:
      "One January day, thirty years ago, the little town of Hanover, anchored on a windy Nebraska tableland, was trying not to be blown away.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 27,
    name: "Far from the Madding Crowd",
    author: "Thomas Hardy",
    category: "Novel",
    blurb:
      '"Far from the Madding Crowd" by Thomas Hardy is a timeless classic that delves into the complexities of love, honor, and resilience set against the pastoral backdrop of rural England. The novel follows the life of Bathsheba Everdene, an independent and spirited woman who inherits a large farm in the fictional county of Wessex. As she navigates the challenges of managing her estate, Bathsheba becomes entangled in a love triangle with three very different suitors: the steadfast shepherd Gabriel Oak, the dashing but reckless Sergeant Troy, and the wealthy yet reserved farmer William Boldwood.\n\nThrough richly detailed descriptions and profound character development, Hardy explores themes of social class, gender roles, and the often tumultuous nature of human relationships. "Far from the Madding Crowd" is not just a love story but a didactic narrative that examines the moral and emotional growth of its characters, set against the enduring rhythms of rural life. Hardy\'s masterful storytelling and keen observations of human nature make this novel a poignant and enduring work of literature.',
    firstLine:
      "When Farmer Oak smiled, the corners of his mouth spread till they were within an unimportant distance of his ears, his eyes were reduced to mere chinks, and diverging wrinkles appeared round them, extending upon his countenance like the rays in a rudimentary sketch of the rising sun.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 32,
    name: "Herland",
    author: "Charlotte Perkins Gilman",
    category: "Novel",
    blurb:
      'In "Herland," Charlotte Perkins Gilman crafts a visionary utopian novel that explores a secluded, all-female society discovered by three male explorers. The narrative unfolds through the eyes of Vandyck Jennings, a sociologist who, along with his companions Terry and Jeff, stumbles upon this hidden civilization where women have thrived independently for two thousand years. In this isolated paradise, the women have developed a harmonious, efficient, and peaceful society, free from war, conflict, and gender inequality.\n\nAs the men navigate their way through Herland, they are confronted with their own preconceived notions about gender roles and societal norms. Gilman uses sharp wit and black humor to critique the patriarchal structures of early 20th-century society, while also presenting a thought-provoking vision of what a world governed by women might look like. "Herland" is not just a tale of adventure and discovery, but a profound commentary on the potential for human societies to evolve beyond traditional gender constraints.',
    firstLine: "This is written from memory, unfortunately.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 33,
    name: "The Scarlet Letter",
    author: "Nathaniel Hawthorne",
    category: "Novel",
    blurb:
      'Set in the rigidly puritanical society of 17th-century Boston, Nathaniel Hawthorne\'s "The Scarlet Letter" delves into themes of sin, guilt, and redemption. The novel follows Hester Prynne, a woman who bears an illegitimate child and is condemned to wear a scarlet "A" on her chest as a mark of shame. As Hester grapples with her public humiliation and the scorn of her community, she remains resilient and dignified, raising her daughter Pearl in relative isolation.\n\nThe story also explores the lives of other key characters, including Arthur Dimmesdale, the tormented minister who shares in Hester\'s secret sin, and Roger Chillingworth, Hester\'s estranged husband who seeks revenge. Through intricate character studies and a richly atmospheric setting, Hawthorne examines the complexities of moral judgment and the human capacity for both cruelty and compassion. "The Scarlet Letter" is a profound exploration of the human spirit and the societal forces that shape it.',
    firstLine:
      "A throng of bearded men, in sad-colored garments and gray steeple-crowned hats, intermixed with women, some wearing hoods, and others bareheaded, was assembled in front of a wooden edifice, the door of which was heavily timbered with oak, and studded with iron spikes.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 34,
    name: "Zen and the Art of the Internet",
    author: "Brendan P Kehoe",
    category: "Non-Fiction",
    blurb:
      '"Zen and the Art of the Internet" by Brendan P. Kehoe is a pioneering guide that demystifies the early days of the Internet, offering readers a comprehensive introduction to the burgeoning digital landscape of the 1990s. Written with clarity and wit, the book serves as both a practical manual and a philosophical exploration of the interconnected world of computer networks. Kehoe provides step-by-step instructions on navigating the Internet, from understanding basic protocols and email to utilizing more advanced tools like FTP and Usenet. \n\nBeyond the technical aspects, Kehoe delves into the cultural and social implications of this new form of communication, reflecting on how the Internet can transform information retrieval and human interaction. "Zen and the Art of the Internet" is not just a technical guide but a thoughtful meditation on the potential of the Internet to reshape society, making it an essential read for both novices and seasoned users eager to understand the roots of today\'s digital age.',
    firstLine:
      'The composition of this booklet was originally started because the Computer Science department at Widener University was in desperate need of documentation describing the capabilities of this "great new Internet link" we obtained.',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 35,
    name: "The Time Machine",
    author: "H Wells",
    category: "Science Fiction",
    blurb:
      'In "The Time Machine" by H.G. Wells, a visionary scientist known only as the Time Traveller invents a machine that allows him to journey through the fourth dimension—time. As he ventures far into the future, he discovers a world starkly divided between the delicate, passive Eloi and the subterranean, menacing Morlocks. Through his encounters with these two species, the Time Traveller uncovers unsettling truths about the trajectory of human evolution and the potential consequences of social and economic disparities.\n\nWells masterfully blends adventure with social commentary, using the Time Traveller\'s journey to explore themes of class struggle, technological advancement, and the impermanence of human achievements. "The Time Machine" is not just a pioneering work of science fiction but also a profound reflection on the future of humanity, making it a timeless classic that continues to resonate with readers today.',
    firstLine:
      "The Time Traveller (for so it will be convenient to speak of him) was expounding a recondite matter to us.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 36,
    name: "The War of the Worlds",
    author: "H Wells",
    category: "Science Fiction",
    blurb:
      'In H.G. Wells\' seminal science fiction novel, "The War of the Worlds," humanity faces an unprecedented existential threat as Martians invade Earth. Set in the late 19th century, the story unfolds through the eyes of an unnamed protagonist who witnesses the catastrophic events that ensue when technologically superior Martians land in England. Armed with devastating heat-rays and poisonous black smoke, the invaders swiftly dismantle human civilization, leaving chaos and destruction in their wake.\n\nAs society crumbles, the protagonist embarks on a harrowing journey of survival, encountering both the best and worst of human nature along the way. Wells masterfully explores themes of human vulnerability, the fragility of civilization, and the hubris of mankind in the face of a superior extraterrestrial force. "The War of the Worlds" remains a timeless exploration of fear, resilience, and the unknown, captivating readers with its vivid portrayal of an apocalyptic struggle between worlds.',
    firstLine:
      "No one would have believed in the last years of the nineteenth century that this world was being watched keenly and closely by intelligences greater than man’s and yet as mortal as his own.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 38,
    name: "The Jargon File",
    author: "Eric S Raymond",
    category: "Non-Fiction",
    blurb:
      '"The Jargon File" compiled by Eric S. Raymond, is a comprehensive and humorous dictionary that delves into the rich and often quirky lexicon of computer culture. This collection captures the essence of hacker slang, technical jargon, and the playful language that has evolved within the computing community. It serves as both a reference and a cultural artifact, offering readers a glimpse into the unique and often irreverent world of programmers, engineers, and tech enthusiasts.\n\nThe book is not just a dry list of terms; it is infused with wit and anecdotes that bring the definitions to life. Each entry provides not only the meaning of the term but also its context and usage, often accompanied by humorous examples and historical notes. Whether you are a seasoned techie or a curious newcomer, "The Jargon File" offers an entertaining and insightful journey through the linguistic landscape of the digital age.',
    firstLine:
      "Hackers regard themselves as an elite (a meritocracy based on ability), though one to which new members are gladly welcome.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 39,
    name: "Hitchhiker's Guide to the Internet",
    author: "Ed Krol",
    category: "Non-Fiction",
    blurb:
      '"Hitchhiker\'s Guide to the Internet" by Ed Krol is a seminal work that serves as an essential primer for navigating the burgeoning world of the Internet during its early days. Written in a time when the Internet was still a novel concept to many, this guide demystifies the complexities of computer networks and provides readers with a comprehensive understanding of how to access and utilize the vast resources available online. Krol\'s approachable writing style and clear explanations make it an invaluable resource for both novices and seasoned tech enthusiasts.\n\nThe book covers a wide range of topics, from the basic structure and functionality of the Internet to more advanced subjects like network protocols and online communication tools. It offers practical advice on connecting to the Internet, using email, participating in discussion groups, and exploring the wealth of information available through various online services. By blending technical insights with practical tips, "Hitchhiker\'s Guide to the Internet" equips readers with the knowledge and confidence needed to effectively navigate and make the most of the digital frontier.',
    firstLine:
      "In the beginning there was the ARPAnet, a wide area experimental network connecting hosts and terminal servers together.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 40,
    name: "NorthWestNet User Services Internet Resource Guide (NUSIRG)",
    author: "Jonathan Kochmer",
    category: "Non-Fiction",
    blurb:
      'The "NorthWestNet User Services Internet Resource Guide (NUSIRG)" by Jonathan Kochmer is an essential manual for navigating the burgeoning landscape of the Internet. Tailored for both novice and experienced users, this guide demystifies the complexities of online resources, offering a comprehensive overview of the tools and services available through NorthWestNet. It serves as a practical roadmap, detailing everything from basic connectivity and email usage to more advanced topics like file transfers, online databases, and network troubleshooting.\n\nKochmer\'s expertise shines through in his clear, concise explanations and step-by-step instructions, making the guide an invaluable resource for anyone looking to maximize their Internet experience. The book also includes a wealth of references and tips, ensuring that users can stay up-to-date with the latest developments and best practices in the ever-evolving digital world. Whether you\'re a student, professional, or casual user, the "NorthWestNet User Services Internet Resource Guide" is your go-to companion for mastering the Internet.',
    firstLine: "Welcome to the Internet!",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 41,
    name: "The Legend of Sleepy Hollow",
    author: "Washington Irving",
    category: "Horror",
    blurb:
      'In Washington Irving\'s timeless classic, "The Legend of Sleepy Hollow," readers are transported to a quaint, eerie village in the heart of New York during the post-Revolutionary War era. The story follows the lanky, superstitious schoolteacher Ichabod Crane, who competes for the affections of the beautiful and wealthy Katrina Van Tassel. However, his romantic aspirations are thwarted by the burly local hero, Brom Bones. As Ichabod navigates the complexities of love and rivalry, he becomes increasingly entangled in the village\'s ghostly lore.\n\nThe tale reaches its spine-chilling climax on a fateful night when Ichabod encounters the legendary Headless Horseman, a spectral figure said to haunt the dark woods of Sleepy Hollow. Blending elements of folklore, humor, and suspense, Irving crafts a narrative that explores themes of superstition, love, and the supernatural. "The Legend of Sleepy Hollow" remains a quintessential piece of American literature, captivating readers with its haunting atmosphere and unforgettable characters.',
    firstLine:
      "In the bosom of one of those spacious coves which indent the eastern shore of the Hudson, at that broad expansion of the river denominated by the ancient Dutch navigators the Tappan Zee, and where they always prudently shortened sail and implored the protection of St. Nicholas when they crossed, there lies a small market town or rural port, which by some is called Greensburgh, but which is more generally and properly known by the name of Tarry Town.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 42,
    name: "The Strange Case of Dr. Jekyll and Mr. Hyde",
    author: "Robert Louis Stevenson",
    category: "Horror",
    blurb:
      "In \"The Strange Case of Dr. Jekyll and Mr. Hyde,\" Robert Louis Stevenson crafts a gripping tale of duality and the human psyche. Set against the fog-laden streets of Victorian London, the story follows the respectable Dr. Henry Jekyll, a physician who becomes obsessed with the idea of separating the good and evil aspects of his personality. Through a series of daring self-experiments, Jekyll transforms into the malevolent and uninhibited Mr. Edward Hyde, unleashing a reign of terror that shocks the city.\n\nAs Hyde's actions grow increasingly violent and uncontrollable, Jekyll struggles to maintain his grip on sanity and morality. The narrative, told through the eyes of Jekyll's lawyer and friend, Gabriel John Utterson, delves into themes of identity, morality, and the dark corners of the human soul. Stevenson's masterful storytelling and exploration of psychological horror make this novella a timeless classic that continues to captivate and haunt readers.",
    firstLine:
      "Mr. Utterson the lawyer was a man of a rugged countenance, that was never lighted by a smile; cold, scanty and embarrassed in discourse; backward in sentiment; lean, long, dusty, dreary, and yet somehow lovable.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 44,
    name: "The Song of the Lark",
    author: "Willa Cather",
    category: "Novel",
    blurb:
      "\"The Song of the Lark\" by Willa Cather is a poignant and inspiring bildungsroman that traces the journey of Thea Kronborg, a young Swedish-American girl from a small Colorado town, as she rises to become a renowned opera singer. Thea's story is one of passion, perseverance, and self-discovery, set against the backdrop of early 20th-century America. From her humble beginnings as the daughter of a minister, Thea's exceptional musical talent and unyielding determination propel her from the dusty streets of Moonstone, Colorado, to the vibrant cultural hub of Chicago, and eventually to the grand stages of the world.\n\nCather's rich narrative delves deeply into Thea's inner world, exploring her struggles with identity, ambition, and the sacrifices required to achieve greatness. The novel beautifully captures the transformative power of art and the relentless pursuit of one's dreams. Through Thea's eyes, readers experience the complexities of artistic growth and the profound impact of mentors, friendships, and the landscapes that shape her. \"The Song of the Lark\" is a timeless tale of resilience and the enduring quest for artistic fulfillment.",
    firstLine:
      "Dr. Howard Archie had just come up from a game of pool with the Jewish clothier and two traveling men who happened to be staying overnight in Moonstone.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 45,
    name: "Anne of Green Gables",
    author: "L Montgomery",
    category: "Novel",
    blurb:
      '"Anne of Green Gables" by L. M. Montgomery is a timeless classic that tells the heartwarming story of Anne Shirley, an imaginative and spirited orphan who is mistakenly sent to live with Marilla and Matthew Cuthbert, a brother and sister who had intended to adopt a boy to help with their farm on Prince Edward Island. Despite the initial mix-up, Anne\'s vibrant personality and boundless enthusiasm soon win over the Cuthberts and the entire community of Avonlea. \n\nSet against the picturesque backdrop of early 20th century Prince Edward Island, the novel explores themes of friendship, belonging, and personal growth. Anne\'s adventures and misadventures, her deepening relationships with her friends and neighbors, and her journey from a misunderstood orphan to a beloved member of the community make for a compelling and endearing narrative. Through Anne\'s eyes, readers experience the beauty of rural life and the power of imagination, making "Anne of Green Gables" a cherished read for generations.',
    firstLine:
      "Mrs. Rachel Lynde lived just where the Avonlea main road dipped down into a little hollow, fringed with alders and ladies’ eardrops and traversed by a brook that had its source away back in the woods of the old Cuthbert place.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 46,
    name: "A Christmas Carol in Prose; Being a Ghost Story of Christmas",
    author: "Charles Dickens",
    category: "Novel",
    blurb:
      '"A Christmas Carol in Prose; Being a Ghost Story of Christmas" by Charles Dickens is a timeless tale that delves into the transformative journey of Ebenezer Scrooge, a miserly and cold-hearted businessman. Set in Victorian London, the story begins on a bleak Christmas Eve, where Scrooge\'s disdain for the festive season and his lack of compassion for the poor are starkly evident. However, his life takes a dramatic turn when he is visited by the ghost of his former business partner, Jacob Marley, who warns him of the dire consequences of his selfish ways.\n\nThroughout the night, Scrooge is visited by three spirits—the Ghosts of Christmas Past, Present, and Yet to Come. Each spectral visit reveals poignant scenes from Scrooge\'s own life and the lives of those around him, including the humble Cratchit family and their ailing son, Tiny Tim. These supernatural encounters force Scrooge to confront the impact of his actions and ultimately lead him to a profound and heartfelt transformation. Dickens\' masterful storytelling and rich characterizations make "A Christmas Carol" a powerful narrative about redemption, compassion, and the enduring spirit of Christmas.',
    firstLine: "Marley was dead: to begin with.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 47,
    name: "Anne of Avonlea",
    author: "L Montgomery",
    category: "Novel",
    blurb:
      '"Anne of Avonlea" by L. M. Montgomery continues the enchanting story of Anne Shirley, the spirited and imaginative orphan who captured readers\' hearts in "Anne of Green Gables." Now sixteen and a schoolteacher in her beloved village of Avonlea, Anne faces new challenges and adventures as she navigates the complexities of adulthood. With her characteristic blend of enthusiasm and idealism, Anne transforms her classroom and community, leaving an indelible mark on everyone she meets.\n\nSet against the picturesque backdrop of Prince Edward Island in the early 20th century, the novel delves into themes of growth, friendship, and the enduring power of dreams. Anne\'s journey is enriched by a cast of memorable characters, including her loyal friends Diana Barry and Gilbert Blythe, as well as a host of quirky new neighbors. "Anne of Avonlea" is a heartwarming tale that celebrates the joys of teaching, the bonds of community, and the beauty of finding one\'s place in the world.',
    firstLine:
      "A tall, slim girl, “half-past sixteen,” with serious gray eyes and hair which her friends called auburn, had sat down on the broad red sandstone doorstep of a Prince Edward Island farmhouse one ripe afternoon in August, firmly resolved to construe so many lines of Virgil.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 49,
    name: "Surfing the Internet: An Introduction",
    author: "Jean Armour Polly",
    category: "Non-Fiction",
    blurb:
      '"Surfing the Internet: An Introduction" by Jean Armour Polly is a comprehensive guide designed to help readers navigate the vast and ever-evolving landscape of the internet. This updated edition delves into the essentials of internet usage, providing a wealth of information on how to effectively find and utilize online resources. With a focus on practical applications, the book covers a range of topics including internet addresses, computer network resources, and electronic mail systems, all presented in an accessible and user-friendly format.\n\nPolly\'s expertise shines through as she demystifies the complexities of the digital world, making it easier for both beginners and seasoned users to enhance their online experience. The book is structured to serve as a directory, offering detailed listings and descriptions of valuable internet resources. Whether you\'re looking to improve your email communication, explore new websites, or simply understand the basics of internet navigation, "Surfing the Internet" is an indispensable tool that equips readers with the knowledge and confidence to make the most of their online journeys.',
    firstLine:
      "Today I'll travel to Minnesota, Texas, California, Cleveland, New Zealand, Sweden, and England.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 51,
    name: "Anne of the Island",
    author: "L Montgomery",
    category: "Novel",
    blurb:
      '"Anne of the Island" by L. M. Montgomery continues the heartwarming journey of Anne Shirley, the spirited orphan from Prince Edward Island. Now a young woman, Anne leaves her beloved Green Gables to attend Redmond College in Nova Scotia. As she navigates the challenges and triumphs of university life, Anne forms new friendships, faces romantic entanglements, and grapples with her evolving sense of self. Her adventures are filled with the same charm, wit, and imagination that endeared her to readers in "Anne of Green Gables."\n\nThroughout the novel, Anne\'s relationships deepen and mature, particularly her bond with childhood friend Gilbert Blythe. As she encounters new experiences and opportunities, Anne must reconcile her dreams with the realities of adulthood. "Anne of the Island" is a poignant exploration of growth, identity, and the enduring power of friendship and love, set against the backdrop of early 20th-century Canada.',
    firstLine:
      '"Harvest is ended and summer is gone," quoted Anne Shirley, gazing across the shorn fields dreamily.',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 54,
    name: "The Marvelous Land of Oz",
    author: "L Baum",
    category: "Fantasy",
    blurb:
      'In "The Marvelous Land of Oz," L. Frank Baum takes readers on a whimsical journey back to the enchanting world of Oz. This sequel to "The Wonderful Wizard of Oz" follows the adventures of a young boy named Tip, who escapes from the clutches of the wicked witch Mombi. Alongside a colorful cast of characters, including the animated wooden figure Jack Pumpkinhead and the highly intelligent Saw-Horse, Tip embarks on a quest to the Emerald City. Their mission is to overthrow the Scarecrow, who has been ruling as the city\'s king, and restore rightful order to the land.\n\nAs Tip and his companions navigate through magical landscapes and face various challenges, they encounter themes of friendship, bravery, and self-discovery. The story is rich with Baum\'s signature blend of humor and fantasy, featuring practical jokes, powerful witches, and even a rebellion led by an army of women soldiers. "The Marvelous Land of Oz" is a timeless tale that continues to captivate young readers with its imaginative plot and enduring lessons about the importance of courage and camaraderie.',
    firstLine:
      "In the Country of the Gillikins, which is at the North of the Land of Oz, lived a youth called Tip.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 55,
    name: "The Wonderful Wizard of Oz",
    author: "L Baum",
    category: "Fantasy",
    blurb:
      'In L. Frank Baum\'s timeless classic, "The Wonderful Wizard of Oz," young Dorothy Gale is swept away from her Kansas home by a powerful cyclone and finds herself in the magical land of Oz. Accompanied by her loyal dog, Toto, Dorothy embarks on a quest to return home, seeking the help of the enigmatic Wizard of Oz. Along the way, she befriends a Scarecrow in search of a brain, a Tin Woodman longing for a heart, and a Cowardly Lion who desires courage. Together, they navigate a series of enchanting and perilous adventures, confronting the dichotomy of good and evil embodied by the Wicked Witch of the West and Glinda, the Good Witch of the North.\n\nAs they journey through the vibrant and fantastical landscapes of Oz, each character discovers their own inner strengths and the true meaning of courage, intelligence, and love. Baum\'s narrative weaves themes of home, friendship, and self-discovery, making "The Wonderful Wizard of Oz" a beloved tale that continues to captivate readers of all ages. This enduring story not only entertains but also imparts valuable life lessons, reminding us that sometimes, the answers we seek are already within us.',
    firstLine:
      "Dorothy lived in the midst of the great Kansas prairies, with Uncle Henry, who was a farmer, and Aunt Em, who was the farmer’s wife.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 56,
    name: "NREN for All: Insurmountable Opportunity",
    author: "Jean Armour Polly",
    category: "Non-Fiction",
    blurb:
      '"NREN for All: Insurmountable Opportunity" by Jean Armour Polly delves into the transformative potential of the National Research and Education Network (NREN) in democratizing access to information and fostering innovation. Polly, a seasoned advocate for internet accessibility, explores the historical context, development, and far-reaching implications of NREN, a high-speed network designed to connect research institutions, universities, and educational entities across the nation. Through a blend of technical insights and real-world examples, the book illustrates how NREN has revolutionized the way knowledge is shared and utilized, breaking down barriers to collaboration and enabling unprecedented advancements in various fields.\n\nThe book also addresses the challenges and opportunities that come with expanding NREN\'s reach to a broader audience. Polly argues that making such a powerful resource accessible to all can bridge the digital divide, enhance educational outcomes, and spur economic growth. By highlighting success stories and potential pitfalls, "NREN for All: Insurmountable Opportunity" serves as both a comprehensive guide and a call to action for policymakers, educators, and technologists committed to harnessing the full potential of the internet for societal benefit.',
    firstLine:
      "When Senator Al Gore was evangelizing support for his visionary National Research and Education Network bill, he often pointed to the many benefits of a high-speed, multi-lane, multi-level data superhighway.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 57,
    name: "Aladdin and the Magic Lamp",
    author: "Unknown",
    category: "Children's",
    blurb:
      '"Aladdin and the Magic Lamp" is a timeless tale of adventure, magic, and the triumph of good over evil. Set in the enchanting landscapes of the Arab world, the story follows the journey of Aladdin, a poor but resourceful young man who discovers a magical lamp that houses a powerful genie. With the genie\'s help, Aladdin transforms his fortunes, winning the heart of a beautiful princess and outwitting a cunning sorcerer who seeks the lamp\'s power for his own nefarious purposes.\n\nRich in cultural folklore and brimming with fantastical elements, this classic fairy tale captivates readers with its themes of bravery, cleverness, and the enduring power of love. As Aladdin navigates the challenges and temptations that come with his newfound wealth and power, he learns valuable lessons about integrity and the true meaning of happiness. "Aladdin and the Magic Lamp" continues to enchant audiences of all ages, offering a glimpse into the rich storytelling traditions of the Arab world.',
    firstLine:
      "There once lived a poor tailor, who had a son called Aladdin, a careless, idle boy who would do nothing but play all day long in the streets with little idle boys like himself.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 59,
    name: "Discourse on the Method of Rightly Conducting One's Reason and of Seeking Truth in the Sciences",
    author: "René Descartes",
    category: "Non-Fiction",
    blurb:
      'In "Discourse on the Method of Rightly Conducting One\'s Reason and of Seeking Truth in the Sciences," René Descartes lays the foundational principles of modern scientific inquiry and rational thought. Published in 1637, this seminal work outlines Descartes\' method for achieving clear and certain knowledge, emphasizing the importance of doubt and systematic reasoning. Descartes introduces his famous dictum, "Cogito, ergo sum" ("I think, therefore I am"), as a fundamental element of his philosophy, establishing the self as the first principle of his method.\n\nThe book is divided into six parts, where Descartes discusses his intellectual development, the principles of his method, and the application of this method to various fields of science. He advocates for a break from traditional scholasticism and encourages a new approach based on empirical evidence and logical deduction. Descartes\' method involves four key rules: accepting only what is indubitable, dividing problems into manageable parts, proceeding from simple to complex, and reviewing comprehensively to ensure nothing is omitted. This work not only revolutionized scientific methodology but also laid the groundwork for the Enlightenment and the development of modern philosophy.',
    firstLine:
      "Good sense is, of all things among men, the most equally distributed; for every one thinks himself so abundantly provided with it, that those even who are the most difficult to satisfy in everything else, do not usually desire a larger measure of this quality than they already possess.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 60,
    name: "The Scarlet Pimpernel",
    author: "Emmuska Orczy Orczy",
    category: "Adventure",
    blurb:
      '"The Scarlet Pimpernel" by Emmuska Orczy is a riveting historical adventure set against the tumultuous backdrop of the French Revolution. The novel follows the daring exploits of Sir Percy Blakeney, an English nobleman who leads a double life as the enigmatic Scarlet Pimpernel. By day, Sir Percy is a seemingly foppish aristocrat, but by night, he masterminds audacious rescues of French aristocrats destined for the guillotine, outwitting the revolutionary authorities at every turn.\n\nAs the story unfolds, Sir Percy\'s secret identity is threatened by the cunning and relentless French agent, Chauvelin, who will stop at nothing to unmask and capture the elusive hero. Meanwhile, Lady Marguerite Blakeney, Sir Percy\'s intelligent and beautiful wife, finds herself entangled in a web of political intrigue and personal betrayal. Torn between her loyalty to her homeland and her love for her husband, Marguerite must navigate a perilous path to uncover the truth and protect the man she loves. "The Scarlet Pimpernel" is a timeless tale of bravery, romance, and the enduring fight for justice.',
    firstLine:
      "A surging, seething, murmuring crowd of beings that are human only in name, for to the eye and ear they seem naught but savage creatures, animated by vile passions and by the lust of vengeance and of hate.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 61,
    name: "The Communist Manifesto",
    author: "Karl Marx & Friedrich Engels",
    category: "Non-Fiction",
    blurb:
      '"The Communist Manifesto," co-authored by Friedrich Engels and Karl Marx, is a seminal political document that lays the foundation for modern socialist and communist movements. Published in 1848, the manifesto presents a critical analysis of the class struggles and economic inequalities inherent in capitalist societies. It argues that history is defined by the conflict between the bourgeoisie (the capitalist class who own the means of production) and the proletariat (the working class). The manifesto calls for the proletariat to rise up, overthrow the bourgeoisie, and establish a classless, stateless society where the means of production are communally owned.\n\nThe text is divided into several sections, each addressing different aspects of Marxist theory, outlining the historical development of capitalism and its inevitable downfall. The manifesto also critiques other socialist and communist literature of the time, distinguishing Marx and Engels\' vision from other contemporary ideologies. Concluding with a rallying cry, "Workers of the world, unite!" the manifesto remains a powerful and influential work, inspiring countless movements and revolutions aimed at achieving social and economic justice.',
    firstLine:
      "The history of all hitherto existing societies is the history of class struggles.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 62,
    name: "A Princess of Mars",
    author: "Edgar Rice Burroughs",
    category: "Science Fiction",
    blurb:
      '"A Princess of Mars" by Edgar Rice Burroughs is a pioneering work of science fiction that transports readers to the exotic and perilous world of Barsoom, known to us as Mars. The story follows the extraordinary adventures of John Carter, a Confederate veteran who mysteriously finds himself on the red planet. There, he discovers a world teeming with strange creatures, warring tribes, and advanced civilizations. Carter\'s Earthly strength and agility are amplified in the lower gravity of Mars, making him a formidable warrior in this alien landscape.\n\nAs he navigates the complex social and political dynamics of Barsoom, John Carter encounters Dejah Thoris, the beautiful and courageous princess of the city-state of Helium. Their burgeoning romance and Carter\'s quest to protect her from various threats drive the narrative forward. Through epic battles, daring escapes, and moments of profound heroism, "A Princess of Mars" explores themes of honor, loyalty, and the enduring power of love. Burroughs\' vivid imagination and fast-paced storytelling have made this novel a timeless classic, laying the groundwork for much of modern science fiction.',
    firstLine: "I am a very old man; how old I do not know.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 64,
    name: "The Gods of Mars",
    author: "Edgar Rice Burroughs",
    category: "Science Fiction",
    blurb:
      'In "The Gods of Mars," the second thrilling installment of Edgar Rice Burroughs\' Barsoom series, John Carter returns to the red planet after a decade-long absence, only to find himself in the perilous Valley Dor, the supposed Martian afterlife. As he battles his way through the treacherous terrain, Carter uncovers the dark secrets of the Therns, a race of false gods who exploit the Martian people\'s beliefs for their own gain. Alongside his newfound ally, the fierce Thark warrior Tars Tarkas, Carter embarks on a daring quest to liberate Mars from the clutches of these deceitful deities.\n\nThe adventure intensifies as Carter reunites with his beloved Dejah Thoris, only to face new challenges and formidable foes, including the enigmatic and powerful Black Pirates of Barsoom. With Burroughs\' masterful storytelling and vivid imagination, "The Gods of Mars" delivers a captivating tale of heroism, love, and the relentless pursuit of justice on a world teeming with danger and wonder.',
    firstLine:
      "As I stood upon the bluff before my cottage on that clear cold night in the early part of March, 1886, the noble Hudson flowing like the grey and silent spectre of a dead river below me, I felt again the strange, compelling influence of the mighty god of war, my beloved Mars, which for ten long and lonesome years I had implored with outstretched arms to carry me back to my lost love.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 67,
    name: "The Black Experience in America",
    author: "Norman Coombs",
    category: "History",
    blurb:
      '"The Black Experience in America" by Norman Coombs offers a comprehensive exploration of African American history, tracing the profound and often harrowing journey from the early days of slavery to the ongoing struggle for equality and civil rights. Coombs meticulously examines the socio-economic and political forces that shaped the lives of African Americans, providing a detailed account of the brutal realities of slavery, the fight for emancipation, and the subsequent challenges during Reconstruction and the Jim Crow era.\n\nThrough a blend of historical analysis and personal narratives, Coombs highlights the resilience and contributions of African Americans in the face of systemic oppression. The book delves into the cultural, intellectual, and political movements that have defined the Black experience, including the Harlem Renaissance, the Civil Rights Movement, and contemporary issues of racial injustice. "The Black Experience in America" serves as both an educational resource and a poignant reminder of the enduring quest for justice and equality in the United States.',
    firstLine:
      "Three and a half centuries of immigration have injected ever-fresh doses of energy and tension into the American bloodstream.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 68,
    name: "The Warlord of Mars",
    author: "Edgar Rice Burroughs",
    category: "Science Fiction",
    blurb:
      "In \"The Warlord of Mars,\" the third thrilling installment of Edgar Rice Burroughs' Barsoom series, the intrepid Earthman John Carter continues his epic adventures on the Red Planet. Driven by his unwavering love for the incomparable Dejah Thoris, Carter embarks on a perilous quest to rescue her from the clutches of her captors. His journey takes him through the treacherous landscapes of Mars, from the frozen wastes of the north to the mysterious and deadly regions of the south, encountering a host of formidable foes and forging alliances with unexpected allies.\n\nAs Carter battles his way through the myriad dangers of Barsoom, he uncovers deeper conspiracies and ancient secrets that threaten the very fabric of Martian society. With his unmatched prowess in combat and indomitable spirit, he faces off against powerful adversaries, including the sinister Therns and the ruthless First Born. \"The Warlord of Mars\" is a gripping tale of heroism, romance, and adventure, showcasing Burroughs' masterful storytelling and vivid imagination, and solidifying John Carter's legacy as one of science fiction's most enduring heroes.",
    firstLine:
      "In the shadows of the forest that flanks the crimson plain by the side of the Lost Sea of Korus in the Valley Dor, beneath the hurtling moons of Mars, speeding their meteoric way close above the bosom of the dying planet, I crept stealthily along the trail of a shadowy form that hugged the darker places with a persistency that proclaimed the sinister nature of its errand.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 71,
    name: "On the Duty of Civil Disobedience",
    author: "Henry David Thoreau",
    category: "Non-Fiction",
    blurb:
      "In \"On the Duty of Civil Disobedience,\" Henry David Thoreau presents a compelling argument for individual resistance to unjust government actions. Written in 1849, this seminal essay articulates Thoreau's belief that citizens should not permit their governments to overrule their consciences, and that they have a moral duty to avoid complicity in injustice. Thoreau's reflections are rooted in his own act of defiance—refusing to pay a poll tax that funded the Mexican-American War and supported the institution of slavery.\n\nThoreau's work is a profound exploration of the principles of nonviolent resistance and the power of individual conscience. He advocates for passive resistance as a means to enact social change, emphasizing that true justice comes from the individual's alignment with higher moral laws rather than compliance with governmental decrees. \"On the Duty of Civil Disobedience\" has inspired countless social and political movements, resonating through history as a timeless call to action against oppression and a testament to the enduring power of principled dissent.",
    firstLine:
      "I heartily accept the motto,—“That government is best which governs least;” and I should like to see it acted up to more rapidly and systematically.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 72,
    name: "Thuvia, Maid of Mars",
    author: "Edgar Rice Burroughs",
    category: "Science Fiction",
    blurb:
      'In "Thuvia, Maid of Mars," Edgar Rice Burroughs transports readers back to the exotic and perilous world of Barsoom, where adventure and romance intertwine against the backdrop of the Red Planet. The story follows Carthoris, the son of the legendary John Carter and Dejah Thoris, as he embarks on a daring quest to rescue the captivating Thuvia of Ptarth. Thuvia, a princess with the extraordinary ability to summon and control the fierce banths of Mars, has been abducted, and Carthoris must navigate treacherous landscapes and confront formidable foes to save her.\n\nAs Carthoris battles through political intrigue and deadly encounters, he discovers that the path to Thuvia\'s heart is fraught with challenges that test his courage and resolve. Burroughs masterfully weaves a tale of heroism, loyalty, and the enduring power of love, set against the richly imagined and vividly described Martian terrain. "Thuvia, Maid of Mars" is a thrilling installment in the Barsoom series, offering readers a captivating blend of science fiction and timeless adventure.',
    firstLine:
      "Upon a massive bench of polished ersite beneath the gorgeous blooms of a giant pimalia a woman sat.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 73,
    name: "The Red Badge of Courage: An Episode of the American Civil War",
    author: "Stephen Crane",
    category: "Novel",
    blurb:
      '"The Red Badge of Courage" by Stephen Crane is a seminal work of American literature that delves into the psychological turmoil of a young soldier named Henry Fleming during the American Civil War. As Henry enlists with dreams of glory and heroism, he is quickly confronted with the brutal realities of battle. The novel explores his internal struggle with fear, shame, and courage as he grapples with his instinct for self-preservation and his desire to prove himself on the battlefield.\n\nSet against the backdrop of the Battle of Chancellorsville, Crane\'s narrative is a poignant exploration of the human condition under the duress of war. Through vivid imagery and deep psychological insight, the story captures Henry\'s journey from naive idealism to a more nuanced understanding of bravery and manhood. "The Red Badge of Courage" remains a powerful and timeless examination of the complexities of courage and the harsh truths of war.',
    firstLine:
      "The cold passed reluctantly from the earth, and the retiring fogs revealed an army stretched out on the hills, resting.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 74,
    name: "The Adventures of Tom Sawyer",
    author: "Mark Twain",
    category: "Adventure",
    blurb:
      '"The Adventures of Tom Sawyer" by Mark Twain is a timeless classic that captures the essence of childhood and the spirit of adventure. Set in the quaint town of St. Petersburg, Missouri, the novel follows the mischievous and imaginative Tom Sawyer as he navigates the trials and tribulations of boyhood. From whitewashing fences to treasure hunts, Tom\'s escapades are filled with humor, excitement, and a touch of danger. Alongside his loyal friends, Huck Finn and Becky Thatcher, Tom\'s journey is a vivid portrayal of youthful exuberance and the quest for freedom.\n\nAs Tom and his friends explore the world around them, they encounter a series of thrilling adventures, including witnessing a murder, getting lost in a cave, and running away to become pirates. Through these experiences, Tom learns valuable lessons about friendship, bravery, and responsibility. Mark Twain\'s masterful storytelling and rich, evocative descriptions bring the Mississippi River Valley to life, making "The Adventures of Tom Sawyer" a beloved tale that continues to resonate with readers of all ages.',
    firstLine: '"Tom!" No answer.',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 75,
    name: "E-mail 101",
    author: "John E Goodwin",
    category: "Non-Fiction",
    blurb:
      '"E-mail 101" by John E. Goodwin is an essential guide for anyone looking to master the art of electronic communication. This comprehensive book delves into the fundamentals of email systems, offering readers a clear understanding of how to effectively use email for both personal and professional purposes. Goodwin covers a wide range of topics, from setting up an email account and understanding email protocols to advanced features like filtering, organizing, and securing your inbox.\n\nThe book also emphasizes best practices for email etiquette, ensuring that your messages are clear, concise, and professional. With practical tips and real-world examples, "E-mail 101" is an invaluable resource for beginners and seasoned users alike, helping them navigate the complexities of modern email systems with confidence and ease. Whether you\'re looking to improve your communication skills or simply want to stay organized, this book is your go-to manual for all things email.',
    firstLine:
      "If you like those little machines that give you 24 hour access to your bank account, you'll love the Internet.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 76,
    name: "Adventures of Huckleberry Finn",
    author: "Mark Twain",
    category: "Adventure",
    blurb:
      '"Adventures of Huckleberry Finn" by Mark Twain is a seminal work of American literature that follows the journey of a young boy, Huck Finn, as he escapes from his abusive father and embarks on a raft journey down the Mississippi River. Alongside him is Jim, a runaway slave seeking freedom from the bonds of slavery. The novel is a rich tapestry of humor, adventure, and social commentary, exploring themes of friendship, race relations, and the moral dilemmas faced by individuals in a deeply divided society.\n\nSet in the pre-Civil War South, the story is both a coming-of-age tale and a biting critique of the entrenched racism and hypocrisy of the time. Through Huck\'s eyes, readers experience the complexities of human nature and the struggle for integrity and justice. Twain\'s masterful use of dialect and vivid descriptions bring the characters and settings to life, making "Adventures of Huckleberry Finn" a timeless exploration of freedom, identity, and the American spirit.',
    firstLine:
      "You don't know about me without you have read a book by the name of The Adventures of Tom Sawyer; but that ain't no matter.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 77,
    name: "The House of the Seven Gables",
    author: "Nathaniel Hawthorne",
    category: "Novel",
    blurb:
      "Nathaniel Hawthorne's \"The House of the Seven Gables\" is a richly woven tale that delves into the dark legacy of the Pyncheon family, whose ancestral home is shrouded in mystery and haunted by the sins of the past. Set in the historic town of Salem, Massachusetts, the novel explores themes of guilt, retribution, and atonement through the lives of its complex characters. The story centers around the decaying mansion, a symbol of the family's cursed history, and its current inhabitants, including the reclusive Hepzibah Pyncheon and her brother Clifford, who has recently been released from prison.\n\nAs the narrative unfolds, the arrival of the young and spirited Phoebe Pyncheon brings a glimmer of hope and renewal to the gloomy household. Her presence begins to unravel the dark secrets that have long plagued the family, leading to a climactic revelation that promises redemption and a new beginning. Hawthorne masterfully blends elements of historical fiction, domestic drama, and the supernatural to create a compelling and atmospheric novel that continues to captivate readers with its exploration of human nature and the enduring impact of the past.",
    firstLine:
      "Halfway down a by-street of one of our New England towns, stands a rusty wooden house, with seven acutely peaked gables, facing towards various points of the compass, and a huge, clustered chimney in the midst.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 78,
    name: "Tarzan of the Apes",
    author: "Edgar Rice Burroughs",
    category: "Adventure",
    blurb:
      '"Tarzan of the Apes" by Edgar Rice Burroughs is a timeless adventure novel that introduces readers to the iconic character of Tarzan, a man raised by apes in the wilds of Africa. Orphaned as an infant when his aristocratic British parents perish in the jungle, Tarzan is adopted by a tribe of great apes and grows up learning the ways of the wild. With his extraordinary strength, agility, and keen intellect, Tarzan becomes the undisputed king of the jungle, bridging the gap between the animal kingdom and humanity.\n\nThe novel explores themes of nature versus nurture, civilization versus savagery, and the essence of human identity. Tarzan\'s life takes a dramatic turn when he encounters other humans, including the beautiful Jane Porter, who awakens in him a longing for human connection and a sense of belonging. As Tarzan grapples with his dual heritage, he must navigate the complexities of both the jungle and human society, leading to thrilling adventures and profound self-discovery. Burroughs\' masterful storytelling and vivid descriptions make "Tarzan of the Apes" a captivating read that continues to enchant audiences with its blend of fantasy, adventure, and romance.',
    firstLine:
      '"I had this story from one who had no business to tell it to me, or to any other."',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 79,
    name: "Terminal Compromise",
    author: "Winn Schwartau",
    category: "Thriller",
    blurb:
      'In "Terminal Compromise," Winn Schwartau crafts a gripping narrative that delves into the shadowy world of computer security and the high-stakes battle for digital privacy. The story follows a brilliant but disillusioned hacker who stumbles upon a sinister government conspiracy aimed at controlling and manipulating personal data on an unprecedented scale. As he navigates a labyrinth of deceit, espionage, and cyber warfare, he must decide whether to expose the truth and risk everything or stay silent and protect himself.\n\nSchwartau\'s novel is not just a thrilling tale of intrigue and suspense; it is also a didactic exploration of the ethical dilemmas and societal implications of our increasingly digital world. Through its complex characters and fast-paced plot, "Terminal Compromise" raises critical questions about the right to privacy, the power of information, and the vulnerabilities inherent in our interconnected systems. This thought-provoking work challenges readers to consider the balance between security and freedom in an age where data is the new currency.',
    firstLine: "Thousands of disk drives spun rapidly, at over 3600 rpm.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 81,
    name: "The Return of Tarzan",
    author: "Edgar Rice Burroughs",
    category: "Adventure",
    blurb:
      'In "The Return of Tarzan," Edgar Rice Burroughs continues the thrilling saga of the legendary jungle hero, Tarzan. After his adventures in the African wilderness, Tarzan finds himself navigating the complexities of civilization in Paris. However, his innate sense of justice and adventure soon leads him into a web of intrigue involving espionage, betrayal, and a secret society. As he grapples with these new challenges, Tarzan\'s path crosses with that of the beautiful Jane Porter once more, rekindling a romance that transcends the boundaries of their vastly different worlds.\n\nThe story takes readers from the opulent ballrooms of Europe to the perilous jungles of Africa, where Tarzan must confront old enemies and new dangers. With his unparalleled strength, keen intellect, and indomitable spirit, Tarzan faces each trial head-on, reaffirming his place as a timeless hero. "The Return of Tarzan" is a masterful blend of fantasy and adventure, capturing the essence of human courage and the unyielding quest for identity and love.',
    firstLine:
      '"Magnifique!" ejaculated the Countess de Coude, beneath her breath.',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 82,
    name: "Ivanhoe: A Romance",
    author: "Walter Scott",
    category: "Novel",
    blurb:
      '"Ivanhoe: A Romance" by Walter Scott is a captivating historical novel set in 12th-century England during the reign of King Richard I. The story follows the adventures of the disinherited knight, Wilfred of Ivanhoe, who returns from the Crusades to reclaim his inheritance and the love of his life, Lady Rowena. Amidst the backdrop of Norman-Saxon tensions, the novel weaves a rich tapestry of chivalry, romance, and political intrigue, featuring iconic characters such as the valiant King Richard the Lionheart, the cunning Prince John, and the enigmatic outlaw Robin Hood.\n\nThe narrative delves into themes of loyalty, justice, and the clash of cultures, particularly highlighting the plight of the Jewish community through the character of Rebecca, a healer who becomes entangled in the conflicts of the time. As Ivanhoe navigates a world of tournaments, sieges, and secret alliances, Scott masterfully blends historical fact with fiction, creating a timeless tale that explores the complexities of honor and the enduring power of love.',
    firstLine:
      "In that pleasant district of merry England which is watered by the river Don, there extended in ancient times a large forest, covering the greater part of the beautiful hills and valleys which lie between Sheffield and the pleasant town of Doncaster.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 83,
    name: "From the Earth to the Moon",
    author: "Jules Verne",
    category: "Science Fiction",
    blurb:
      "\"From the Earth to the Moon\" by Jules Verne is a pioneering work of science fiction that takes readers on an extraordinary journey into space. The story begins with the Baltimore Gun Club, a post-American Civil War society of artillery enthusiasts, who conceive an audacious plan to launch a manned projectile to the moon. Led by the visionary President Barbicane, the club's members pool their resources and expertise to construct a colossal cannon capable of propelling a spacecraft beyond Earth's atmosphere. The narrative is rich with technical details and imaginative engineering, reflecting Verne's meticulous research and boundless creativity.\n\nAs the adventure unfolds, Barbicane, along with his daring companions Captain Nicholl and the intrepid French adventurer Michel Ardan, embark on their lunar voyage.",
    firstLine:
      "During the War of the Rebellion, a new and influential club was established in the city of Baltimore in the State of Maryland.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 84,
    name: "Frankenstein; Or, The Modern Prometheus",
    author: "Mary Wollstonecraft Shelley",
    category: "Horror",
    blurb:
      '"Frankenstein; Or, The Modern Prometheus" by Mary Wollstonecraft Shelley is a seminal work of science fiction and Gothic horror that delves into the dark and complex relationship between creator and creation. The novel tells the story of Victor Frankenstein, an ambitious scientist who defies natural laws to create life from death. His creation, a grotesque and sentient being, is initially innocent but becomes increasingly vengeful as it faces relentless rejection and isolation from society. The narrative explores themes of ambition, responsibility, and the ethical limits of scientific exploration, raising profound questions about the consequences of playing God.\n\nAs Victor\'s life spirals into chaos and despair, the novel takes readers on a harrowing journey through the icy landscapes of the Arctic to the shadowy recesses of the human soul. Shelley\'s masterful storytelling and rich, atmospheric prose create a chilling and thought-provoking tale that continues to resonate with modern audiences. "Frankenstein" is not just a story of horror but a poignant exploration of humanity, identity, and the moral dilemmas posed by technological advancement.',
    firstLine:
      "You will rejoice to hear that no disaster has accompanied the commencement of an enterprise which you have regarded with such evil forebodings.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 85,
    name: "The Beasts of Tarzan",
    author: "Edgar Rice Burroughs",
    category: "Adventure",
    blurb:
      'In "The Beasts of Tarzan," the third thrilling installment of Edgar Rice Burroughs\' iconic series, the legendary jungle hero faces his most perilous challenge yet. Tarzan, now living a peaceful life with his wife Jane and their infant son, is thrust back into the wild when his old nemesis, the ruthless Russian villain Nikolas Rokoff, kidnaps his family. Stripped of his resources and allies, Tarzan must rely on his primal instincts and the loyalty of his animal friends to navigate the treacherous African wilderness and rescue his loved ones.\n\nAs Tarzan embarks on this harrowing quest, he forms an unlikely alliance with a fierce panther, a loyal ape, and other jungle creatures, showcasing his unparalleled ability to communicate and command the beasts of the wild. Burroughs masterfully weaves a tale of suspense, danger, and raw adventure, exploring themes of survival, loyalty, and the unbreakable bond between man and nature. "The Beasts of Tarzan" is a gripping adventure that captivates readers with its vivid descriptions, relentless action, and the indomitable spirit of its hero.',
    firstLine: '"The entire affair is shrouded in mystery," said D’Arnot.',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 86,
    name: "A Connecticut Yankee in King Arthur's Court",
    author: "Mark Twain",
    category: "Fantasy",
    blurb:
      "In \"A Connecticut Yankee in King Arthur's Court,\" Mark Twain masterfully blends fantasy, satire, and social commentary in a tale that transcends time. The story follows Hank Morgan, a 19th-century engineer from Connecticut, who inexplicably finds himself transported to the medieval world of King Arthur's Camelot. Armed with his modern knowledge and ingenuity, Hank sets out to revolutionize the archaic society, introducing contemporary technology and democratic ideals to the bewildered inhabitants of the past.\n\nTwain's novel is a sharp critique of romanticized notions of chivalry and the feudal system, juxtaposing the harsh realities of medieval life with the advancements of the industrial age. Through Hank's adventures and misadventures, Twain explores themes of power, progress, and the often absurd nature of human institutions. \"A Connecticut Yankee in King Arthur's Court\" remains a timeless exploration of the clash between tradition and innovation, wrapped in Twain's signature wit and keen observation.",
    firstLine:
      "“Camelot—Camelot,” said I to myself. “I don’t seem to remember hearing of it before. Name of the asylum, likely.”",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 90,
    name: "The Son of Tarzan",
    author: "Edgar Rice Burroughs",
    category: "Adventure",
    blurb:
      'In "The Son of Tarzan," Edgar Rice Burroughs continues the thrilling saga of the jungle hero, Tarzan, through the adventures of his son, Jack Clayton, also known as Korak. The story begins with young Jack discovering his father\'s wild heritage and feeling an irresistible pull towards the African wilderness. Driven by a sense of destiny and a thirst for adventure, Jack escapes to the jungle, where he learns to survive and thrive, much like his legendary father.\n\nAs Korak, Jack encounters a host of dangers and forms a deep bond with a young girl named Meriem, who has her own harrowing past. Together, they navigate the perils of the jungle, from wild beasts to ruthless human adversaries. Burroughs masterfully weaves a tale of courage, loyalty, and the primal call of the wild, capturing the essence of adventure and the enduring legacy of Tarzan. "The Son of Tarzan" is a captivating continuation of the Tarzan series, filled with action, romance, and the timeless allure of the untamed jungle.',
    firstLine:
      "The long boat of the Marjorie W. was floating down the broad Ugambi with ebb tide and current.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 91,
    name: "Tom Sawyer Abroad",
    author: "Mark Twain",
    category: "Adventure",
    blurb:
      'In "Tom Sawyer Abroad," Mark Twain takes his beloved characters Tom Sawyer, Huck Finn, and Jim on an exhilarating adventure far from their familiar Mississippi River. This time, the trio finds themselves aboard a hot air balloon, embarking on an unexpected journey across the globe. Their voyage takes them over the vast expanse of the Atlantic Ocean and into the heart of the African desert, where they encounter a series of thrilling escapades and challenges.\n\nAs they navigate through foreign lands, Tom\'s boundless imagination and knack for storytelling shine, while Huck\'s practical nature and Jim\'s steadfast loyalty provide balance to their escapades. Twain\'s signature wit and humor are ever-present, making "Tom Sawyer Abroad" a delightful blend of adventure, satire, and social commentary. This lesser-known sequel to "The Adventures of Tom Sawyer" and "Adventures of Huckleberry Finn" offers readers a chance to see the world through the eyes of these iconic characters, all while exploring themes of friendship, ingenuity, and the spirit of exploration.',
    firstLine:
      "Do you reckon Tom Sawyer was satisfied after all them adventures?",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 92,
    name: "Tarzan and the Jewels of Opar",
    author: "Edgar Rice Burroughs",
    category: "Adventure",
    blurb:
      'In "Tarzan and the Jewels of Opar," Edgar Rice Burroughs takes readers on a thrilling adventure deep into the heart of Africa. The story follows the iconic jungle hero, Tarzan, as he embarks on a perilous quest to the lost city of Opar, a place shrouded in mystery and danger. Opar is rumored to be filled with untold riches, including the fabled jewels that could secure Tarzan\'s financial future and safeguard his beloved estate. However, the journey is fraught with treacherous obstacles, including wild beasts, hostile natives, and the enigmatic high priestess, La, who harbors a dangerous obsession with Tarzan.\n\nAs Tarzan navigates the perils of Opar, he faces not only physical challenges but also moral dilemmas that test his character and resolve. The narrative weaves together elements of fantasy and adventure, creating a rich tapestry of suspense and excitement. Burroughs\' vivid descriptions and fast-paced storytelling transport readers to a world where courage, loyalty, and cunning are paramount. "Tarzan and the Jewels of Opar" is a captivating tale that continues to enchant fans of classic adventure fiction, showcasing the enduring appeal of one of literature\'s most legendary heroes.',
    firstLine:
      "Lieutenant Albert Werper had only the prestige of the name he had dishonored to thank for his narrow escape from being cashiered.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 94,
    name: "Alexander's Bridge",
    author: "Willa Cather",
    category: "Novel",
    blurb:
      '"Alexander\'s Bridge" by Willa Cather is a compelling psychological novel that delves into the complexities of ambition, love, and identity. The story centers around Bartley Alexander, a successful and renowned civil engineer who is at the pinnacle of his career, celebrated for his innovative bridge designs. Despite his professional triumphs, Alexander finds himself grappling with a profound sense of dissatisfaction and restlessness. His life takes a dramatic turn when he rekindles a past romance with Hilda Burgoyne, an actress in London, which stirs up old emotions and conflicts.\n\nAs Alexander navigates the turbulent waters of his dual existence—balancing his responsibilities to his devoted wife, Winifred, in Boston, and his passionate affair with Hilda—he becomes increasingly entangled in a web of deceit and inner turmoil. The novel explores themes of fidelity, the consequences of living a double life, and the relentless pursuit of personal and professional fulfillment. Cather\'s poignant narrative and rich character development offer a profound examination of the human condition, making "Alexander\'s Bridge" a timeless and thought-provoking read.',
    firstLine:
      "Late one brilliant April afternoon Professor Lucius Wilson stood at the head of Chestnut Street, looking about him with the pleased air of a man of taste who does not very often get to Boston.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 95,
    name: "The Prisoner of Zenda",
    author: "Anthony Hope",
    category: "Adventure",
    blurb:
      'In "The Prisoner of Zenda" by Anthony Hope, readers are transported to the fictional European kingdom of Ruritania, where political intrigue and daring adventure unfold. The story follows Rudolf Rassendyll, an Englishman who bears an uncanny resemblance to the soon-to-be-crowned King Rudolf V. When the king is drugged and kidnapped by his treacherous half-brother, Duke Michael, Rassendyll is persuaded to impersonate the monarch to prevent a coup and maintain stability in the kingdom.\n\nAs Rassendyll navigates the complexities of royal life, he finds himself entangled in a web of deception, romance, and danger. With the help of loyal allies, he must rescue the true king from captivity in the castle of Zenda and thwart Duke Michael\'s sinister plans. "The Prisoner of Zenda" is a thrilling tale of heroism, honor, and the struggle for justice, set against a backdrop of lush landscapes and opulent palaces.',
    firstLine:
      '"I wonder when in the world you’re going to do anything, Rudolf?” said my brother’s wife.',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 97,
    name: "Flatland: A Romance of Many Dimensions",
    author: "Edwin Abbott Abbott",
    category: "Science Fiction",
    blurb:
      '"Flatland: A Romance of Many Dimensions" by Edwin Abbott Abbott is a thought-provoking novella that explores the nature of dimensions through the experiences of A. Square, a humble inhabitant of a two-dimensional world. In Flatland, society is strictly hierarchical, with geometric shapes representing different social classes. Squares and Pentagons are professionals and gentlemen, while Circles are the highest-ranking priests. Women, depicted as simple line segments, occupy the lowest rung of the social ladder. The story delves into the rigid social structure and the limitations of perception within this flat world.\n\nA. Square\'s understanding of reality is dramatically challenged when he is visited by a Sphere from Spaceland, a three-dimensional world. Through this encounter, A. Square begins to grasp the concept of dimensions beyond his own, leading him to question the nature of existence and the limitations of his society\'s understanding. "Flatland" serves as both a satirical critique of the Victorian social hierarchy and a profound exploration of dimensions and perspectives, encouraging readers to expand their own understanding of the universe.',
    firstLine:
      "I call our world Flatland, not because we call it so, but to make its nature clearer to you, my happy readers, who are privileged to live in Space.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 99,
    name: "Collected Articles of Frederick Douglass",
    author: "Frederick Douglass",
    category: "Non-Fiction",
    blurb:
      '"Collected Articles of Frederick Douglass" is a compelling anthology that brings together the powerful writings of one of America\'s most influential abolitionists and social reformers. Frederick Douglass, born into slavery in 1818, escaped to freedom and became a leading voice in the fight against the institution of slavery. This collection showcases his eloquent and impassioned essays, speeches, and articles that span his remarkable career, offering profound insights into the struggles and triumphs of African Americans during the 19th century.\n\nThrough his incisive prose, Douglass addresses the brutal realities of slavery, the moral and political imperatives of abolition, and the broader quest for justice and equality. His writings also delve into the complexities of the Reconstruction era, highlighting the challenges and opportunities faced by newly emancipated African Americans. "Collected Articles of Frederick Douglass" not only serves as a testament to Douglass\'s enduring legacy but also provides a vital historical perspective on the ongoing fight for civil rights and social justice in America.',
    firstLine:
      "In the first narrative of my experience in slavery, written nearly forty years ago, and in various writings since, I have given the public what I considered very good reasons for withholding the manner of my escape.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 101,
    name: "The Hacker Crackdown: Law and Disorder on the Electronic Frontier",
    author: "Bruce Sterling",
    category: "Non-Fiction",
    blurb:
      '"The Hacker Crackdown: Law and Disorder on the Electronic Frontier" by Bruce Sterling delves into the tumultuous world of computer crime and the burgeoning digital landscape of the late 20th century. Sterling meticulously chronicles the events leading up to and following the significant crackdown on hackers in the early 1990s, a period marked by heightened tensions between law enforcement agencies, the burgeoning hacker community, and the telecommunications industry. Through a blend of investigative journalism and narrative storytelling, the book explores the motivations, ethics, and subcultures of hackers, while also shedding light on the legal and societal implications of their actions.\n\nSterling\'s work provides a comprehensive examination of key incidents, such as the infamous Operation Sundevil, and profiles pivotal figures on both sides of the law. By weaving together personal anecdotes, legal battles, and technological advancements, "The Hacker Crackdown" offers readers a nuanced understanding of the complexities and conflicts that defined the early days of the electronic frontier. It is a compelling read for anyone interested in the intersection of technology, law, and society, and serves as a historical snapshot of a pivotal moment in the evolution of the digital age.',
    firstLine:
      "On January 15, 1990, AT&T's long-distance telephone switching system crashed.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 102,
    name: "The Tragedy of Pudd'nhead Wilson",
    author: "Mark Twain",
    category: "Novel",
    blurb:
      '"The Tragedy of Pudd\'nhead Wilson" by Mark Twain is a gripping tale set in the antebellum South, exploring themes of identity, race, and social justice. The story revolves around two children switched at birth: one born into slavery and the other into privilege. As they grow up, their true identities remain hidden, leading to a complex web of deception and mistaken identity. The novel delves into the lives of these characters, examining the impact of societal norms and racial prejudices on their destinies.\n\nAt the heart of the narrative is David "Pudd\'nhead" Wilson, a sharp-witted but underappreciated lawyer whose keen observations and fingerprinting skills ultimately unravel the truth. Twain\'s masterful storytelling and satirical edge expose the absurdities and injustices of the time, making "The Tragedy of Pudd\'nhead Wilson" a thought-provoking and enduring work that challenges readers to reflect on the constructs of identity and the moral fabric of society.',
    firstLine:
      "A person who is ignorant of legal matters is always liable to make mistakes when he tries to photograph a court scene with his pen.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 103,
    name: "Around the World in Eighty Days",
    author: "Jules Verne",
    category: "Adventure",
    blurb:
      '"Around the World in Eighty Days" by Jules Verne is a thrilling adventure novel that follows the journey of the unflappable Englishman, Phileas Fogg, and his loyal French valet, Passepartout. Set in the late 19th century, the story begins with Fogg making an audacious wager with his fellow Reform Club members that he can circumnavigate the globe in just eighty days. With a substantial fortune at stake, Fogg and Passepartout embark on a whirlwind expedition that takes them through exotic locales, from the bustling streets of Bombay to the wild American West.\n\nThroughout their journey, the duo faces a series of unexpected challenges, including natural disasters, transportation mishaps, and the relentless pursuit of Detective Fix, who mistakenly believes Fogg is a bank robber on the run. As they race against time, Fogg\'s meticulous planning and Passepartout\'s resourcefulness are put to the test. "Around the World in Eighty Days" is a captivating tale of determination, ingenuity, and the spirit of adventure, showcasing Verne\'s masterful storytelling and his fascination with the possibilities of travel and technology.',
    firstLine:
      "Mr. Phileas Fogg lived, in 1872, at No. 7, Saville Row, Burlington Gardens, the house in which Sheridan died in 1814.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 104,
    name: "Inaugural Address of Franklin Delano Roosevelt",
    author: "Franklin D Roosevelt",
    category: "Non-Fiction",
    blurb:
      "In his Inaugural Address delivered on March 4th, 1933, Franklin Delano Roosevelt confronts the dire economic crisis gripping the United States, famously asserting that \"the only thing we have to fear is fear itself.\" As the nation grapples with the Great Depression, Roosevelt outlines his vision for recovery, emphasizing the need for immediate and decisive action. He calls for a renewed sense of collective purpose and mutual support, urging Americans to embrace bold, persistent experimentation to revive the economy and restore public confidence.\n\nRoosevelt's address sets the stage for the New Deal, a series of transformative programs and reforms aimed at providing relief, recovery, and reform to a struggling nation. He underscores the importance of leadership and unity, promising to wield the executive power necessary to combat the crisis while respecting democratic principles. This historic speech not only marks the beginning of Roosevelt's presidency but also signals a pivotal shift in American governance and the role of the federal government in addressing economic and social challenges.",
    firstLine:
      "President Hoover, Mr. Chief Justice, my friends: This is a day of national consecration, and I am certain that on this day my fellow Americans expect that on my induction into the Presidency I will address them with a candor and a decision which the present situation of our people impels.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 105,
    name: "Persuasion",
    author: "Jane Austen",
    category: "Romance",
    blurb:
      '"Persuasion" by Jane Austen is a poignant exploration of love, regret, and societal expectations set in early 19th-century England. The novel follows Anne Elliot, a young woman of quiet intelligence and deep emotions, who is persuaded to break off her engagement to the dashing but penniless naval officer, Captain Frederick Wentworth. Eight years later, Anne\'s family faces financial ruin, and Wentworth returns from the Napoleonic Wars as a wealthy and successful captain. As their paths cross once more, Anne must navigate the complexities of rekindled feelings and the societal pressures that once tore them apart.\n\nAusten masterfully delves into themes of second chances and the enduring power of love, while also offering a sharp critique of the class-conscious society of her time. Through Anne\'s journey, readers witness the struggles of a woman who must reconcile her past decisions with her present desires, all while dealing with a dysfunctional family and the rigid social structures that dictate her world. "Persuasion" is a timeless tale of resilience and the hope that true love can prevail against all odds.',
    firstLine:
      "Sir Walter Elliot, of Kellynch Hall, in Somersetshire, was a man who, for his own amusement, never took up any book but the Baronetage; there he found occupation for an idle hour, and consolation in a distressed one; there his faculties were roused into admiration and respect, by contemplating the limited remnant of the earliest patents; there any unwelcome sensations, arising from domestic affairs changed naturally into pity and contempt as he turned over the almost endless creations of the last century; and there, if every other leaf were powerless, he could read his own history with an interest which never failed.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 106,
    name: "Jungle Tales of Tarzan",
    author: "Edgar Rice Burroughs",
    category: "Adventure",
    blurb:
      '"Jungle Tales of Tarzan" by Edgar Rice Burroughs is a captivating collection of twelve short stories that delve into the early life and adventures of the iconic character, Tarzan of the Apes. Set in the lush, untamed jungles of Africa, these tales explore Tarzan\'s interactions with the wild animals, his encounters with native tribes, and his gradual understanding of human nature and society. Each story is a standalone adventure, yet together they paint a vivid picture of Tarzan\'s growth from a feral child to a noble and heroic figure.\n\nThrough Burroughs\' masterful storytelling, readers are transported into a world where the line between man and beast is blurred, and survival depends on wit, strength, and an unbreakable bond with nature. The stories are rich with action, suspense, and the timeless themes of identity, belonging, and the clash between civilization and the wild. "Jungle Tales of Tarzan" not only entertains but also offers a deeper insight into the character of Tarzan, making it a must-read for fans of adventure and fantasy fiction.',
    firstLine:
      "Teeka, stretched at luxurious ease in the shade of the tropical forest, presented, unquestionably, a most alluring picture of young, feminine loveliness.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 107,
    name: "Far from the Madding Crowd",
    author: "Thomas Hardy",
    category: "Novel",
    blurb:
      'Set against the evocative backdrop of rural Wessex, "Far from the Madding Crowd" weaves a compelling tale of love, ambition, and resilience. Bathsheba Everdene, a spirited and independent woman, unexpectedly inherits a large farm, drawing the attention of three very different suitors. Gabriel Oak, a humble and steadfast shepherd, offers her unwavering loyalty; the dashing Sergeant Troy brings passion and excitement; while the wealthy but introverted farmer Boldwood presents a promise of security and status.\n\nAs Bathsheba navigates the challenges of managing her farm and the complexities of her heart, she must confront her own desires and the consequences of her choices. Hardy\'s rich descriptions and keen observations of human nature create an enduring story that explores the timeless struggles of love, pride, and the quest for personal fulfillment. ""Far from the Madding Crowd"" is a vivid portrayal of a woman\'s journey to self-discovery amidst the rustic charm and harsh realities of Victorian rural life.',
    firstLine:
      "When Farmer Oak smiled, the corners of his mouth spread till they were within an unimportant distance of his ears, his eyes were reduced to chinks, and diverging wrinkles appeared round them, extending upon his countenance like the rays in a rudimentary sketch of the rising sun.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 108,
    name: "The Return of Sherlock Holmes",
    author: "Arthur Conan Doyle",
    category: "Mystery",
    blurb:
      'In "The Return of Sherlock Holmes," Arthur Conan Doyle resurrects his iconic detective after a dramatic hiatus following the events at Reichenbach Falls. This collection of thirteen riveting short stories marks the triumphant return of Sherlock Holmes, who re-emerges from presumed death to the astonishment of his loyal friend, Dr. John Watson. Together, they delve into a series of complex and intriguing cases that test Holmes\'s unparalleled deductive skills and keen intellect.\n\nFrom the sinister "Adventure of the Empty House" to the perplexing "Adventure of the Dancing Men," each story showcases Holmes\'s masterful ability to unravel the most baffling mysteries. Set against the backdrop of Victorian London, these tales not only highlight the detective\'s genius but also explore the deep bond between Holmes and Watson. "The Return of Sherlock Holmes" reaffirms Conan Doyle\'s mastery in crafting suspenseful and engaging detective fiction, ensuring that the legendary sleuth remains an enduring figure in literary history.',
    firstLine:
      "It was in the spring of the year 1894 that all London was interested, and the fashionable world dismayed, by the murder of the Honourable Ronald Adair under most unusual and inexplicable circumstances.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 110,
    name: "Tess of the d'Urbervilles: A Pure Woman",
    author: "Thomas Hardy",
    category: "Novel",
    blurb:
      "\"Tess of the d'Urbervilles: A Pure Woman\" by Thomas Hardy is a poignant and tragic tale set in the pastoral landscape of Wessex, England. The novel follows the life of Tess Durbeyfield, a young woman from a poor family who discovers that she may be descended from the noble d'Urberville lineage. This revelation sets off a chain of events that lead Tess into the clutches of Alec d'Urberville, a wealthy but unscrupulous man who takes advantage of her innocence. Tess's life is irrevocably altered by this encounter, and she struggles to find redemption and happiness in a society that harshly judges her for circumstances beyond her control.\n\nAs Tess navigates the complexities of love, betrayal, and societal expectations, she meets Angel Clare, the son of a clergyman, who offers her a glimpse of hope and genuine affection. However, the shadows of her past continue to haunt her, leading to a series of heart-wrenching decisions and ultimately, a tragic conclusion. Hardy's novel is a powerful critique of the rigid moral codes and class structures of Victorian society, and it raises enduring questions about purity, justice, and the human condition. Through Tess's story, Hardy explores themes of fate, resilience, and the often harsh realities faced by women in a patriarchal world.",
    firstLine:
      "On an evening in the latter part of May a middle-aged man was walking homeward from Shaston to the village of Marlott, in the adjoining Vale of Blakemore or Blackmoor.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 111,
    name: "Freckles",
    author: "Gene Stratton-Porter",
    category: "Novel",
    blurb:
      "\"Freckles\" by Gene Stratton-Porter is a heartwarming tale set in the lush, untamed Limberlost Swamp of Indiana. The story follows the journey of a young, one-handed orphan named Freckles, who secures a job as a guard for a valuable timber lease. Despite his physical limitations and lack of family, Freckles' determination, courage, and integrity shine through as he protects the forest from timber thieves and navigates the challenges of his new life.\n\nAs Freckles immerses himself in the beauty and mystery of the Limberlost, he encounters a cast of memorable characters, including the compassionate Bird Woman and the spirited Angel, who help him discover his own worth and potential. Through trials and triumphs, Freckles' story is a testament to the resilience of the human spirit and the transformative power of love and nature. This timeless classic weaves themes of self-discovery, belonging, and the healing power of the natural world into a compelling narrative that continues to resonate with readers of all ages.",
    firstLine:
      "Freckles came down the corduroy that crosses the lower end of the Limberlost.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 112,
    name: "Violists",
    author: "Richard McGowan",
    category: "Novel",
    blurb:
      '"Violists" by Richard McGowan is a poignant collection of short stories that intricately weaves the delicate threads of love, music, and familial bonds. Each story centers around the lives of violists, exploring their passions, struggles, and the unique ways in which their art shapes their relationships. McGowan\'s lyrical prose captures the essence of the violist\'s world, from the intense dedication to their craft to the subtle, often unspoken connections they share with those around them.\n\nAt the heart of the collection is a recurring theme of fathers and daughters, where the violin becomes a symbol of both connection and conflict. Through these tender and sometimes tumultuous relationships, McGowan delves into the complexities of love and the sacrifices made in its name. "Violists" is a symphony of human emotions, resonating with anyone who has ever felt the profound impact of music and the intricate dance of familial love.',
    firstLine:
      "In winter the interior of the university library was hardly warmer than the outside, and it was terribly drafty.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 113,
    name: "The Secret Garden",
    author: "Frances Hodgson Burnett",
    category: "Children's",
    blurb:
      '"The Secret Garden" by Frances Hodgson Burnett is a timeless tale of transformation and rejuvenation. The story follows Mary Lennox, a sour and neglected orphan sent to live with her reclusive uncle in the gloomy Misselthwaite Manor on the Yorkshire moors. Initially, Mary is as cold and uninviting as the manor itself, but her curiosity leads her to discover a hidden, neglected garden. With the help of new friends, including her sickly cousin Colin and a local boy named Dickon, Mary begins to restore the garden, and in doing so, she also starts to heal her own heart and spirit.\n\nAs the garden flourishes, so do the lives of those who tend it. Colin, who has been bedridden and believed to be incurable, finds new strength and vitality through his connection with nature and friendship. The once desolate manor becomes a place of hope and renewal, illustrating the profound impact of love, care, and the natural world. "The Secret Garden" is a poignant exploration of the healing power of nature and the redemptive qualities of friendship and compassion.',
    firstLine:
      "When Mary Lennox was sent to Misselthwaite Manor to live with her uncle everybody said she was the most disagreeable-looking child ever seen.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 118,
    name: "Big Dummy's Guide to the Internet",
    author: "Electronic Frontier Foundation",
    category: "Non-Fiction",
    blurb:
      '"Big Dummy\'s Guide to the Internet" by the Electronic Frontier Foundation is an essential primer for anyone looking to navigate the vast and complex world of the Internet. Written in an accessible and engaging style, this guide demystifies the intricacies of computer networks and online communication, making it an invaluable resource for beginners and seasoned users alike. The book covers a wide range of topics, from the basics of connecting to the Internet and understanding email to more advanced subjects like online privacy, security, and the burgeoning culture of cyberspace.\n\nThe Electronic Frontier Foundation, a leading nonprofit organization dedicated to defending civil liberties in the digital world, brings its expertise to bear in this comprehensive guide. Readers will find practical advice on how to make the most of their online experience while staying safe and informed. Whether you\'re looking to explore the World Wide Web, participate in online communities, or simply understand the technology that powers the Internet, "Big Dummy\'s Guide to the Internet" is your go-to resource for mastering the digital frontier.',
    firstLine:
      "The world is just a phone call away; with a computer and modem, you'll be able to connect to the Internet, the world's largest computer network (and if you're lucky, you won't even need the modem; many colleges and companies now give their students or employees direct access to the Internet).",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 119,
    name: "A Tramp Abroad",
    author: "Mark Twain",
    category: "Novel",
    blurb:
      'In "A Tramp Abroad," Mark Twain takes readers on a whimsical and satirical journey through Europe, blending travelogue with fiction in his inimitable style. The book chronicles Twain\'s adventures and misadventures as he traverses the continent, primarily on foot, accompanied by his loyal but often bewildered friend, Harris. Through a series of humorous anecdotes, keen observations, and exaggerated tales, Twain offers a unique perspective on European culture, customs, and landscapes, all while poking fun at the peculiarities of both the Old World and his own American sensibilities.\n\nTwain\'s sharp wit and playful narrative voice shine throughout the book, making "A Tramp Abroad" not just a travel memoir but a comedic exploration of human nature. From the majestic Alps to the bustling streets of German towns, Twain\'s encounters with locals, fellow travelers, and the occasional mishap provide endless entertainment. His vivid descriptions and humorous commentary invite readers to laugh at the absurdities of travel and the universal quirks of people, making this work a timeless piece of literary humor and a delightful read for anyone with a sense of adventure.',
    firstLine:
      "One day it occurred to me that it had been many years since the world had been afforded the spectacle of a man traveling through Europe on foot.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 120,
    name: "Treasure Island",
    author: "Robert Louis Stevenson",
    category: "Adventure",
    blurb:
      '"Treasure Island" by Robert Louis Stevenson is a timeless adventure novel that plunges readers into the thrilling world of pirates, hidden treasure, and high-seas escapades. The story follows young Jim Hawkins, who discovers a cryptic treasure map in the chest of a deceased pirate. This discovery sets him on a perilous journey to the mysterious Treasure Island, accompanied by a motley crew that includes the enigmatic Long John Silver, whose true intentions remain shrouded in mystery.\n\nAs Jim and his companions navigate treacherous waters and face mutiny, betrayal, and danger at every turn, the novel explores themes of bravery, loyalty, and the quest for fortune. Stevenson\'s masterful storytelling and vivid characterizations bring to life a gripping tale of adventure and moral complexity, making "Treasure Island" a beloved classic that continues to captivate readers of all ages.',
    firstLine:
      'Squire Trelawney, Dr. Livesey, and the rest of these gentlemen having asked me to write down the whole particulars about Treasure Island, from the beginning to the end, keeping nothing back but the bearings of the island, and that only because there is still treasure not yet lifted, I take up my pen in the year of grace 17-- and go back to the time when my father kept the "Admiral Benbow" inn and the brown old seaman with the sabre cut first took up his lodging under our roof.',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 121,
    name: "Northanger Abbey",
    author: "Jane Austen",
    category: "Romance",
    blurb:
      '"Northanger Abbey" by Jane Austen is a delightful satire of Gothic novels and a keen exploration of the social mores of 19th-century England. The story follows Catherine Morland, a naive and imaginative young woman who is invited to Bath by family friends. There, she encounters the sophisticated and charming Henry Tilney and his sister Eleanor, as well as the flirtatious Isabella Thorpe and her brother John. Catherine\'s love for Gothic novels leads her to view the world through a lens of mystery and suspense, often misinterpreting the intentions and actions of those around her.\n\nAs Catherine is invited to the Tilney\'s home, Northanger Abbey, her imagination runs wild with fantasies of dark secrets and hidden passages. However, she soon learns that reality is far different from the novels she adores. Through her experiences, Catherine matures and gains a more balanced perspective on life and love. Austen\'s witty prose and sharp social commentary make "Northanger Abbey" both a humorous critique of Gothic fiction and a timeless coming-of-age story.',
    firstLine:
      "No one who had ever seen Catherine Morland in her infancy would have supposed her born to be an heroine.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 122,
    name: "The Return of the Native",
    author: "Thomas Hardy",
    category: "Novel",
    blurb:
      'Set against the evocative backdrop of Egdon Heath, Thomas Hardy\'s "The Return of the Native" delves into the complexities of human desires and the inexorable forces of fate. The novel intricately weaves the lives of its characters, each grappling with their own aspirations and disillusionments. At its heart is the passionate and headstrong Eustacia Vye, whose yearning for a more exciting life leads her into a tumultuous relationship with Clym Yeobright, the "native" who returns from Paris with dreams of educating the local populace. Their union, however, is fraught with misunderstandings and thwarted ambitions, setting off a chain of events that reverberate through the heath.\n\nHardy masterfully explores themes of love, betrayal, and the struggle between individual desires and societal expectations. The novel\'s rich, atmospheric setting serves as a character in its own right, reflecting the inner turmoil and inevitable tragedies of its inhabitants. Through the intersecting lives of Eustacia, Clym, and other memorable characters like the devoted Thomasin and the opportunistic Damon Wildeve, "The Return of the Native" offers a poignant and timeless examination of the human condition, marked by Hardy\'s signature blend of realism and poetic intensity.',
    firstLine:
      "A Saturday afternoon in November was approaching the time of twilight, and the vast tract of unenclosed wild known as Egdon Heath embrowned itself moment by moment.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 123,
    name: "At the Earth's Core",
    author: "Edgar Rice Burroughs",
    category: "Adventure",
    blurb:
      'In "At the Earth\'s Core," Edgar Rice Burroughs takes readers on a thrilling journey to the mysterious and uncharted world of Pellucidar, located deep within the Earth\'s core. The story follows David Innes, a young mining heir, and his inventor friend, Abner Perry, as they embark on an experimental drilling expedition using a powerful mechanical device called the "Iron Mole." To their astonishment, they break through the Earth\'s crust and find themselves in a subterranean realm teeming with prehistoric creatures, strange landscapes, and primitive human tribes.\n\nAs Innes and Perry navigate this perilous new world, they encounter the beautiful and courageous Dian the Beautiful, who becomes both a love interest and a key ally in their struggle for survival. Together, they face formidable challenges, including battles with savage beasts and confrontations with the tyrannical Mahars, an advanced reptilian race that dominates Pellucidar. Burroughs masterfully blends elements of science fiction, fantasy, and adventure, creating a captivating tale of exploration, bravery, and the indomitable human spirit.',
    firstLine: "I was born in Connecticut about thirty years ago.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 125,
    name: "A Girl of the Limberlost",
    author: "Gene Stratton-Porter",
    category: "Novel",
    blurb:
      '"A Girl of the Limberlost" by Gene Stratton-Porter is a poignant coming-of-age novel set in the enchanting yet challenging environment of the Limberlost Swamp in Indiana. The story follows Elnora Comstock, a determined and resourceful young girl who faces numerous hardships, including the emotional neglect of her mother, Katherine. Despite these challenges, Elnora\'s passion for nature and her keen interest in moths and other wildlife drive her to pursue education and personal growth. Her journey is marked by resilience, self-discovery, and the pursuit of her dreams against all odds.\n\nThe novel intricately weaves themes of family dynamics, generational conflict, and the transformative power of nature. Elnora\'s relationship with her mother is central to the narrative, highlighting the complexities of love, loss, and reconciliation. Through Elnora\'s interactions with the natural world and the people she meets, the story explores the profound impact of environment and community on personal development. "A Girl of the Limberlost" is a timeless tale of perseverance, the beauty of the natural world, and the enduring strength of the human spirit.',
    firstLine:
      '"Elnora Comstock, have you lost your senses?" demanded the angry voice of Katharine Comstock while she glared at her daughter.',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 126,
    name: "The Poison Belt",
    author: "Arthur Conan Doyle",
    category: "Science Fiction",
    blurb:
      'In "The Poison Belt," Arthur Conan Doyle reunites readers with the indomitable Professor Challenger and his intrepid companions from "The Lost World." This gripping science fiction tale plunges the Earth into a dire crisis as a mysterious cosmic phenomenon envelops the planet in a lethal ether, threatening all life. As the deadly belt of poison gas approaches, Challenger, ever the brilliant and eccentric scientist, gathers his trusted colleagues—journalist Edward Malone, adventurer Lord John Roxton, and the skeptical Professor Summerlee—at his country estate to confront the impending catastrophe.\n\nThe narrative unfolds with a blend of suspense, scientific intrigue, and philosophical reflection, as the group grapples with the potential end of humanity. Isolated from the chaos outside, they witness the world teetering on the brink of extinction, prompting profound discussions on the fragility of civilization and the resilience of the human spirit. "The Poison Belt" is a thought-provoking exploration of survival, camaraderie, and the quest for knowledge in the face of unimaginable peril, showcasing Doyle\'s masterful storytelling and his ability to weave scientific curiosity with thrilling adventure.',
    firstLine:
      "It is imperative that now at once, while these stupendous events are still clear in my mind, I should set them down with that exactness of detail which time may blur.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 128,
    name: "The Arabian Nights Entertainments",
    author: "Andrew Lang",
    category: "Children's",
    blurb:
      '"The Arabian Nights Entertainments," curated by Andrew Lang, is a captivating collection of Middle Eastern folk tales that have enchanted readers for centuries. This anthology brings together some of the most famous stories from the legendary "One Thousand and One Nights," where the clever Scheherazade spins tale after tale to delay her execution by the vengeful King Shahryar. Each night, she weaves intricate narratives filled with adventure, romance, magic, and moral lessons, ensuring her survival and captivating the king\'s imagination.\n\nWithin these pages, readers will encounter timeless characters such as Aladdin and his magical lamp, Ali Baba and the Forty Thieves, and Sinbad the Sailor with his extraordinary voyages. Lang\'s retelling preserves the rich cultural heritage and exotic allure of the original tales while making them accessible to a younger audience. "The Arabian Nights Entertainments" is not just a collection of stories; it is a journey into a world where the boundaries between reality and fantasy blur, offering a glimpse into the timeless art of storytelling that has shaped the literary landscape of the Arab world.',
    firstLine: "There was once upon a time a king who had two sons.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 130,
    name: "Orthodoxy",
    author: "G Chesterton",
    category: "Non-Fiction",
    blurb:
      '"Orthodoxy" by G.K. Chesterton is a seminal work in Christian apologetics, where the author embarks on a personal journey to explain and defend the essence, genius, and nature of Christianity. Written with Chesterton\'s characteristic wit and paradoxical style, the book delves into the philosophical and theological underpinnings of the Christian faith, presenting it as a coherent and compelling worldview. Through a series of essays, Chesterton explores themes such as the role of reason and imagination, the importance of tradition, and the paradoxes inherent in Christian doctrine.\n\nChesterton\'s narrative is both autobiographical and argumentative, as he recounts his own path from skepticism to belief. He challenges the prevailing secular philosophies of his time, arguing that Christianity uniquely satisfies the deepest human longings for meaning, morality, and joy. "Orthodoxy" is not just a defense of Christian doctrine but also a celebration of its beauty and mystery, making it a timeless and thought-provoking read for believers and skeptics alike.',
    firstLine:
      "The only possible excuse for this book is that it is an answer to a challenge.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 131,
    name: "The Pilgrim's Progress from this world to that which is to come",
    author: "John Bunyan",
    category: "Novel",
    blurb:
      '"The Pilgrim\'s Progress" by John Bunyan is a seminal work of Christian fiction that allegorically depicts the journey of a man named Christian from his hometown, the City of Destruction, to the Celestial City. Delivered under the guise of a dream, the narrative follows Christian as he encounters various trials, temptations, and characters that symbolize the spiritual struggles and moral challenges faced by believers. Along the way, he meets figures such as Evangelist, who guides him, and Faithful, who becomes his companion, each representing different facets of the Christian faith and experience.\n\nThe story is rich with symbolic meaning and theological insights, making it a profound exploration of the Christian life. Through Christian\'s journey, Bunyan addresses themes of redemption, perseverance, and the ultimate triumph of faith. "The Pilgrim\'s Progress" remains a timeless classic, offering readers both a compelling narrative and a deep spiritual reflection on the path to salvation.',
    firstLine:
      "As I walked through the wilderness of this world, I lighted on a certain place where was a den, and I laid me down in that place to sleep: and, as I slept, I dreamed a dream.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 132,
    name: "The Art of War",
    author: "Sun Tzu",
    category: "Non-Fiction",
    blurb:
      '"The Art of War" by Sunzi, also known as Sun Tzu, is an ancient Chinese military treatise that has profoundly influenced both Eastern and Western military strategy and tactics. Written in the 5th century BC, this seminal work is composed of 13 chapters, each dedicated to a different aspect of warfare, from strategic planning and battlefield tactics to the importance of adaptability and intelligence. Sunzi\'s insights emphasize the significance of preparation, deception, and the psychological aspects of conflict, advocating for the use of strategy over brute force.\n\nBeyond its military applications, "The Art of War" has transcended its original context to become a guide for leadership and strategy in various fields, including business, sports, and politics. Its timeless wisdom on the nature of competition, the value of flexibility, and the power of knowledge continues to resonate, making it a must-read for anyone interested in the dynamics of conflict and the art of strategic thinking.',
    firstLine: '"The art of war is of vital importance to the State."',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 133,
    name: "The Damnation of Theron Ware",
    author: "Harold Frederic",
    category: "Novel",
    blurb:
      '"The Damnation of Theron Ware" by Harold Frederic is a compelling psychological novel that delves into the complexities of faith, doubt, and personal transformation. Set in the late 19th century in a small town in upstate New York, the story follows Theron Ware, a young and ambitious Methodist minister. As Theron becomes increasingly disillusioned with his rigid religious upbringing, he encounters a series of influential characters who challenge his beliefs and ignite his intellectual curiosity. These encounters lead him down a path of moral and spiritual questioning, ultimately causing a profound internal conflict.\n\nThrough Theron\'s journey, Frederic explores themes of religious skepticism, the clash between tradition and modernity, and the seductive allure of intellectual and sensual freedom. The novel paints a vivid portrait of the societal and personal struggles faced by individuals grappling with the constraints of their faith and the desire for self-discovery. "The Damnation of Theron Ware" is a thought-provoking exploration of the human condition, capturing the tumultuous process of questioning and redefining one\'s identity in the face of changing beliefs.',
    firstLine:
      "No such throng had ever before been seen in the building during all its eight years of existence.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 134,
    name: "Maria; Or, The Wrongs of Woman",
    author: "Mary Wollstonecraft",
    category: "Novel",
    blurb:
      '"Maria; Or, The Wrongs of Woman" by Mary Wollstonecraft is a pioneering work of feminist literature that delves into the oppressive conditions faced by women in the 18th century. The novel follows the harrowing journey of Maria, a woman unjustly confined to a madhouse by her cruel husband. Through Maria\'s story, Wollstonecraft exposes the systemic injustices and societal constraints that strip women of their autonomy and subject them to the whims of patriarchal authority.\n\nAs Maria forms a bond with her fellow inmate, Jemima, and reads the memoirs of her lover, Darnford, the narrative weaves together themes of personal suffering, resilience, and the quest for freedom. Wollstonecraft\'s incisive critique of marriage, legal inequities, and the lack of educational opportunities for women underscores the urgent need for social reform. "Maria; Or, The Wrongs of Woman" remains a powerful testament to the enduring struggle for women\'s rights and equality.',
    firstLine:
      "Abodes of horror have frequently been described, and castles, filled with spectres and chimeras, conjured up by the magic spell of genius to harrow the soul, and absorb the wondering mind.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 135,
    name: "Les Misérables",
    author: "Victor Hugo",
    category: "Novel",
    blurb:
      '"Les Misérables" by Victor Hugo is a sweeping epic that delves into the lives of several characters in 19th-century France, weaving a rich tapestry of love, justice, and redemption. At its heart is Jean Valjean, an ex-convict who, after serving 19 years for stealing a loaf of bread, seeks to rebuild his life and find redemption. His journey is fraught with challenges, including relentless pursuit by the dogged Inspector Javert, who believes in the absolute enforcement of the law.\n\nSet against the backdrop of post-revolutionary France, the novel also explores the lives of other memorable characters such as the destitute Fantine, her innocent daughter Cosette, the valiant student Marius, and the streetwise urchin Gavroche. Through their interconnected stories, Hugo paints a vivid picture of the social injustices and struggles of the time, while also celebrating the enduring human spirit. "Les Misérables" is a timeless tale of hope, compassion, and the quest for justice in a world rife with suffering and inequality.',
    firstLine:
      "In 1815, M. Charles-Francois-Bienvenu Myriel was Bishop of D (he was an old man of about seventy-five years of age; he had occupied the see of D) since 1806",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 138,
    name: "George Sand: Some Aspects of Her Life and Writings",
    author: "René Doumic",
    category: "Biography",
    blurb:
      "\"George Sand: Some Aspects of Her Life and Writings\" by René Doumic offers an insightful exploration into the life and literary contributions of one of the 19th century's most influential female novelists, George Sand. Born Amantine Lucile Aurore Dupin, Sand defied societal norms with her unconventional lifestyle and bold, progressive ideas. Doumic delves into her complex personality, her passionate relationships, and her relentless pursuit of artistic and personal freedom. Through meticulous research and engaging narrative, the book paints a vivid portrait of a woman who was as controversial as she was talented.\n\nDoumic also provides a critical analysis of Sand's extensive body of work, highlighting her innovative approach to themes such as gender, social justice, and human rights. By examining her novels, essays, and letters, the author reveals how Sand's personal experiences and convictions were intricately woven into her writing. This biography not only celebrates George Sand's literary achievements but also underscores her enduring impact on French literature and feminist thought.",
    firstLine:
      "In the whole of French literary history, there is, perhaps, no subject of such inexhaustible and modern interest as that of George Sand.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 139,
    name: "The Lost World",
    author: "Arthur Conan Doyle",
    category: "Adventure",
    blurb:
      'In "The Lost World" by Arthur Conan Doyle, readers are taken on an exhilarating journey to the uncharted territories of the Amazon rainforest. The story follows the daring Professor Challenger, a larger-than-life character who claims to have discovered a plateau where prehistoric creatures still roam. Skeptical but intrigued, a motley crew of adventurers, including the intrepid journalist Edward Malone, the skeptical Professor Summerlee, and the seasoned hunter Lord John Roxton, join Challenger on an expedition to verify his astonishing claims.\n\nAs they venture deeper into the heart of South America, the team encounters a world frozen in time, teeming with dinosaurs, primitive tribes, and other ancient wonders. Their journey is fraught with danger, as they must navigate treacherous landscapes and survive encounters with formidable creatures. "The Lost World" is a thrilling tale of adventure and discovery, blending scientific curiosity with the timeless allure of the unknown, and ultimately challenging the boundaries of human knowledge and imagination.',
    firstLine:
      "Mr. Hungerton, her father, really was the most tactless person upon earth: a fluffy, feathery, untidy cockatoo of a man, perfectly good-natured, but absolutely centered upon his own silly self.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 140,
    name: "The Jungle",
    author: "Upton Sinclair",
    category: "Novel",
    blurb:
      '"The Jungle" by Upton Sinclair is a harrowing exploration of the American meatpacking industry in the early 20th century, seen through the eyes of Jurgis Rudkus, a Lithuanian immigrant. As Jurgis and his family arrive in Chicago with dreams of prosperity, they are instead met with the brutal realities of industrial capitalism. The novel vividly depicts the appalling working conditions, unsanitary practices, and exploitation faced by laborers in the stockyards, revealing the human cost of unchecked corporate greed.\n\nSinclair\'s narrative is both a social critique and a call to action, shedding light on the plight of the working class and the systemic corruption that pervades the industry. Through Jurgis\'s journey from hope to despair, "The Jungle" exposes the stark contrast between the American Dream and the harsh reality for many immigrants. The book\'s impact was profound, leading to public outcry and significant reforms in food safety and labor laws, cementing its place as a pivotal work in American literature and social history.',
    firstLine:
      "It was four o’clock when the ceremony was over and the carriages began to arrive.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 141,
    name: "Mansfield Park",
    author: "Jane Austen",
    category: "Romance",
    blurb:
      '"Mansfield Park" by Jane Austen is a compelling exploration of social class, morality, and family dynamics set in early 19th-century England. The novel follows the life of Fanny Price, a young girl from a poor family who is sent to live with her wealthy relatives at Mansfield Park. As Fanny grows up, she navigates the complexities of her new environment, where she is often reminded of her lower status. Despite her meek demeanor, Fanny possesses a strong moral compass, which sets her apart from her more privileged cousins and the sophisticated visitors who frequent the estate.\n\nThe story delves into themes of love, duty, and integrity as Fanny observes and experiences the romantic entanglements and ethical dilemmas of those around her. Her quiet strength and steadfast principles ultimately influence the lives of her relatives and lead to her own personal growth and happiness. "Mansfield Park" is a richly layered narrative that critiques the social hierarchies of its time while offering a poignant look at the resilience and virtue of its heroine.',
    firstLine:
      "About thirty years ago Miss Maria Ward, of Huntingdon, with only seven thousand pounds, had the good luck to captivate Sir Thomas Bertram, of Mansfield Park, in the county of Northampton, and to be thereby raised to the rank of a baronet’s lady, with all the comforts and consequences of an handsome house and large income.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 143,
    name: "The Mayor of Casterbridge",
    author: "Thomas Hardy",
    category: "Novel",
    blurb:
      '"The Mayor of Casterbridge" by Thomas Hardy is a compelling tale of ambition, regret, and redemption set in the fictional town of Casterbridge in Wessex, England. The story follows Michael Henchard, a man whose impulsive decision to sell his wife and daughter at a country fair in a moment of drunken folly sets the stage for his rise and fall. Years later, Henchard has transformed himself into a prosperous and respected mayor, but the return of his estranged family threatens to unravel his hard-earned success and expose his past misdeeds.\n\nAs Henchard grapples with the consequences of his actions, Hardy delves deep into themes of fate, character, and the possibility of atonement. The novel paints a vivid portrait of rural 19th-century England, while exploring the complexities of human nature and the inexorable impact of one\'s choices. Through Henchard\'s tumultuous journey, "The Mayor of Casterbridge" offers a poignant reflection on the struggle for personal redemption and the enduring quest for forgiveness.',
    firstLine:
      "One evening of late summer, before the nineteenth century had reached one-third of its span, a young man and woman, the latter carrying a child, were approaching the large village of Weydon-Priors, in Upper Wessex, on foot.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 144,
    name: "The Voyage Out",
    author: "Virginia Woolf",
    category: "Novel",
    blurb:
      "\"The Voyage Out\" by Virginia Woolf is a compelling exploration of self-discovery and the complexities of human relationships. The novel follows Rachel Vinrace, a sheltered young woman who embarks on a sea voyage to South America aboard her father's ship. As Rachel encounters a diverse array of characters, including the intellectual Helen Ambrose and the charming Terence Hewet, she begins to question her previously unexamined life and societal expectations.\n\nSet against the backdrop of the exotic South American landscape, Rachel's journey becomes a poignant coming-of-age story. Woolf's lyrical prose delves into themes of love, identity, and the search for meaning, capturing the inner lives of her characters with profound sensitivity. \"The Voyage Out\" is not just a physical journey but an emotional and psychological odyssey, marking Woolf's first foray into the literary world with a narrative that is both richly textured and deeply introspective.",
    firstLine:
      "As the streets that lead from the Strand to the Embankment are very narrow, it is better not to walk down them arm-in-arm.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 145,
    name: "Middlemarch",
    author: "George Eliot",
    category: "Novel",
    blurb:
      '"Middlemarch" by George Eliot is a profound exploration of the intricate social fabric and moral complexities of a provincial English town in the early 19th century. The novel intricately weaves together the lives of its diverse characters, focusing on the aspirations, struggles, and transformations of individuals such as the idealistic Dorothea Brooke, the ambitious but flawed Dr. Tertius Lydgate, and the earnest yet naive Fred Vincy. Through their intersecting stories, Eliot delves into themes of marriage, ambition, societal expectations, and the quest for personal fulfillment.\n\nSet against the backdrop of political reform and scientific progress, "Middlemarch" is both a richly detailed social commentary and a deeply personal narrative. Eliot\'s keen psychological insight and empathetic portrayal of her characters\' inner lives make the novel a timeless study of human nature and the enduring quest for meaning and connection in a rapidly changing world.',
    firstLine:
      "Miss Brooke had that kind of beauty which seems to be thrown into relief by poor dress.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 146,
    name: "A Little Princess",
    author: "Frances Hodgson Burnett",
    category: "Children's",
    blurb:
      '"A Little Princess" by Frances Hodgson Burnett is a timeless tale of resilience, kindness, and the power of imagination. The story follows young Sara Crewe, a wealthy and intelligent girl who is sent to a prestigious boarding school in London by her doting father. Despite her privileged status, Sara remains humble and kind-hearted, quickly becoming a favorite among her peers and teachers. Her life takes a dramatic turn when her father unexpectedly dies, leaving her penniless and at the mercy of the cruel headmistress, Miss Minchin.\n\nStripped of her luxuries and forced into servitude, Sara\'s indomitable spirit and vivid imagination help her endure the hardships she faces. She continues to act with grace and generosity, even in the face of adversity, earning the admiration and friendship of those around her. "A Little Princess" is a poignant exploration of the strength of character and the belief that every girl, no matter her circumstances, can be a princess at heart.',
    firstLine:
      "Once on a dark winter's day, when the yellow fog hung so thick and heavy in the streets of London that the lamps were lighted and the shop windows blazed with gas as they do at night, an odd-looking little girl sat in a cab with her father and was driven rather slowly through the big thoroughfares.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 147,
    name: "Common Sense",
    author: "Thomas Paine",
    category: "Non-Fiction",
    blurb:
      '"Common Sense" by Thomas Paine is a seminal work in the history of political thought and a pivotal text in the American Revolution. Published in 1776, this powerful pamphlet passionately argues for American independence from British rule. Paine\'s clear and persuasive prose dismantles the legitimacy of the British monarchy and hereditary succession, advocating instead for the creation of a democratic republic. His arguments are grounded in Enlightenment principles, emphasizing reason, natural rights, and the inherent equality of all individuals.\n\nPaine\'s work resonated deeply with the American colonists, galvanizing public opinion and fueling the revolutionary movement. "Common Sense" is not just a call to arms but a visionary blueprint for a new kind of government, one that derives its power from the consent of the governed. Its impact on the political landscape of the time was profound, helping to lay the intellectual foundation for the Declaration of Independence and the subsequent formation of the United States.',
    firstLine:
      '"Some writers have so confounded society with government, as to leave little or no distinction between them; whereas they are not only different, but have different origins."',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 148,
    name: "The Autobiography of Benjamin Franklin",
    author: "Benjamin Franklin",
    category: "Biography",
    blurb:
      '"The Autobiography of Benjamin Franklin" is a captivating self-portrait of one of America\'s most influential Founding Fathers. Written by Franklin himself, this memoir offers an intimate glimpse into his life, from his humble beginnings as the son of a candle and soap maker to his rise as a renowned statesman, inventor, and philosopher. Through a series of personal anecdotes and reflections, Franklin shares his journey of self-improvement, detailing his relentless pursuit of knowledge, his entrepreneurial ventures, and his contributions to society, including the founding of institutions such as the University of Pennsylvania and the American Philosophical Society.\n\nFranklin\'s autobiography is not just a recounting of his achievements but also a guide to personal development and civic responsibility. He outlines his famous "13 Virtues," a set of principles he devised to cultivate moral character and discipline. With wit, wisdom, and humility, Franklin\'s narrative provides timeless lessons on the value of hard work, perseverance, and the importance of contributing to the greater good. This classic work remains an enduring testament to the spirit of American ingenuity and the power of self-made success.',
    firstLine:
      "Dear Son: I have ever had pleasure in obtaining any little anecdotes of my ancestors.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 149,
    name: "The Lost Continent",
    author: "Edgar Rice Burroughs",
    category: "Fantasy",
    blurb:
      'In "The Lost Continent," Edgar Rice Burroughs crafts a thrilling tale set in a dystopian future where the world is divided by a colossal oceanic barrier. The story follows Lieutenant Jefferson Turck of the Pan-American Navy, who embarks on a daring mission that leads him to the forbidden and mysterious Eastern Hemisphere. Once a thriving civilization, Europe has now devolved into a primitive and perilous land, teeming with wild beasts and savage tribes. Turck\'s journey is fraught with danger as he navigates this untamed world, encountering both allies and adversaries in his quest for survival and understanding.\n\nBurroughs masterfully blends elements of science fiction and fantasy, creating a vivid and imaginative landscape that challenges the protagonist\'s ingenuity and resilience. As Turck delves deeper into the heart of the lost continent, he uncovers secrets that could reshape the future of humanity. "The Lost Continent" is a gripping adventure that explores themes of exploration, survival, and the enduring human spirit, making it a timeless classic in speculative fiction.',
    firstLine:
      "Since earliest childhood I have been strangely fascinated by the mystery surrounding the history of the last days of twentieth century Europe.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 150,
    name: "The Republic",
    author: "Plato",
    category: "Non-Fiction",
    blurb:
      'In "The Republic," the ancient Greek philosopher Plato explores the nature of justice and the ideal state through a series of dialogues led by his mentor, Socrates. Set in Athens, the narrative delves into profound philosophical questions about morality, politics, and human behavior. Socrates engages with various interlocutors, dissecting the concept of justice and examining whether the just life is superior to the unjust life. Through these discussions, Plato presents his vision of a utopian society governed by philosopher-kings, where wisdom and reason prevail over power and ambition.\n\nThe work also introduces the allegory of the cave, a powerful metaphor for human enlightenment and the pursuit of knowledge. Plato\'s exploration of the tripartite soul, comprising reason, spirit, and appetite, mirrors his ideal society\'s structure, where each class performs its designated role in harmony. "The Republic" remains a cornerstone of Western philosophy, offering timeless insights into the complexities of justice, governance, and the human condition.',
    firstLine:
      "I went down yesterday to the Piraeus with Glaucon the son of Ariston, that I might offer up my prayers to the goddess.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 152,
    name: "Wild Justice",
    author: "Ruth M Sprague",
    category: "Novel",
    blurb:
      '"Wild Justice" by Ruth M. Sprague is a gripping tale that delves into the dark underbelly of academia, where sex discrimination and power dynamics shape the lives of those within its walls. The story follows the journey of a determined female professor who battles against the entrenched sexism and institutional biases at a prestigious university. As she navigates the treacherous waters of academic politics, she uncovers a web of deceit and corruption that threatens not only her career but also her sense of justice and morality.\n\nWith a keen eye for detail and a profound understanding of the complexities of gender discrimination, Sprague crafts a narrative that is both thought-provoking and emotionally charged. "Wild Justice" is not just a story of personal struggle but also a broader commentary on the systemic issues that plague higher education. It challenges readers to reflect on the pervasive nature of inequality and the courage required to confront it. This compelling novel is a must-read for anyone interested in the intersections of gender, power, and justice within the academic world.',
    firstLine:
      "It was going to be a perfect June day; already a cloudless, azure sky, promising no hint of rain, arched over a shimmering campus.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 153,
    name: "Jude the Obscure",
    author: "Thomas Hardy",
    category: "Novel",
    blurb:
      "\"Jude the Obscure\" by Thomas Hardy is a poignant and tragic tale that delves into the life of Jude Fawley, a stonemason with dreams of becoming a scholar. Set against the backdrop of Hardy's fictional Wessex, the novel explores Jude's relentless pursuit of education and his tumultuous relationships, particularly with his cousin Sue Bridehead. As Jude grapples with societal constraints and personal misfortunes, the story examines themes of ambition, love, and the harsh realities of social class and institutional rigidity.\n\nThe novel is a powerful critique of the social and educational systems of the time, highlighting the struggles of those who dare to defy societal norms. Jude's aspirations are continually thwarted by the rigid class structure, and his relationships are marred by societal disapproval and personal tragedy. Hardy's unflinching portrayal of the characters' suffering, including the devastating impact on their children, underscores the novel's somber tone and its exploration of the often insurmountable barriers to personal fulfillment and happiness.",
    firstLine:
      "The schoolmaster was leaving the village, and everybody seemed sorry.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 154,
    name: "The Rise of Silas Lapham",
    author: "William Dean Howells",
    category: "Novel",
    blurb:
      "\"The Rise of Silas Lapham\" by William Dean Howells is a compelling exploration of the American Dream and the moral complexities of success. The novel follows Silas Lapham, a self-made businessman who rises to wealth and prominence through his paint manufacturing business. Set against the backdrop of Boston's high society, the story delves into Lapham's struggle to balance his newfound affluence with his ethical values and personal integrity.\n\nAs Lapham attempts to integrate his family into the elite social circles of Boston, he faces numerous challenges, including the scrutiny of the established Brahmin class and the moral dilemmas posed by his business practices. The narrative intricately weaves themes of ambition, social mobility, and the often harsh realities of the business world, offering a nuanced portrayal of the protagonist's internal and external conflicts. Through Lapham's journey, Howells provides a critical examination of the American capitalist ethos and the true cost of success.",
    firstLine:
      'When Bartley Hubbard went to interview Silas Lapham for the "Solid Men of Boston" series, which he undertook to finish up in The Events, after he replaced their original projector on that newspaper, Lapham received him in his private office by previous appointment.',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 155,
    name: "The Moonstone",
    author: "Wilkie Collins",
    category: "Mystery",
    blurb:
      '"The Moonstone" by Wilkie Collins is a pioneering work in the genre of detective fiction, often hailed as the first English detective novel. The story revolves around the theft of a precious Indian diamond, known as the Moonstone, which is bequeathed to Rachel Verinder on her eighteenth birthday. The diamond, however, brings with it a curse and a series of misfortunes. When the Moonstone mysteriously disappears from Rachel\'s room, a complex investigation ensues, involving a colorful cast of characters, including the astute Sergeant Cuff, who is determined to unravel the mystery.\n\nSet against the backdrop of an English country estate, the novel delves into themes of colonialism, social class, and the supernatural. Through multiple narrators, Collins weaves a rich tapestry of suspense, intrigue, and human emotion. As the narrative unfolds, secrets are revealed, and the true nature of the Moonstone\'s curse comes to light, making "The Moonstone" a timeless and compelling read for fans of mystery and classic literature alike.',
    firstLine:
      "I address these lines—written in India—to my relatives in England.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 157,
    name: "Daddy-Long-Legs",
    author: "Jean Webster",
    category: "Novel",
    blurb:
      '"Daddy-Long-Legs" by Jean Webster is a charming epistolary novel that follows the life of Jerusha "Judy" Abbott, an orphan who receives an unexpected opportunity to attend college, thanks to an anonymous benefactor. Known only as "John Smith" or "Daddy-Long-Legs," this mysterious philanthropist stipulates that Judy must write him monthly letters detailing her progress, though he will never respond. Through her witty and heartfelt correspondence, Judy blossoms from a naive girl into a confident young woman, navigating the challenges and joys of college life.\n\nAs Judy\'s letters unfold, readers are treated to her keen observations, humorous anecdotes, and growing self-awareness. She forms deep friendships, excels academically, and even begins to explore her own talents as a writer. Along the way, Judy\'s curiosity about her benefactor grows, leading to a surprising and heartwarming revelation. "Daddy-Long-Legs" is a delightful coming-of-age story that celebrates the transformative power of education, the importance of self-expression, and the unexpected ways in which love and support can shape a person\'s life.',
    firstLine:
      "The first Wednesday in every month was a Perfectly Awful Day--a day to be awaited with dread, endured with courage and forgotten with haste.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 158,
    name: "Emma",
    author: "Jane Austen",
    category: "Romance",
    blurb:
      '"Emma" by Jane Austen is a delightful exploration of the complexities of social class, romance, and personal growth in early 19th-century England. The novel centers around Emma Woodhouse, a charming, intelligent, and wealthy young woman who takes great pleasure in matchmaking for her friends and acquaintances. Despite her good intentions, Emma\'s meddling often leads to misunderstandings and complications, revealing her own naivety and lack of self-awareness. Through a series of humorous and poignant events, Emma learns valuable lessons about humility, love, and the importance of genuine human connections.\n\nSet in the quaint village of Highbury, the story is populated with a rich cast of characters, including the endearing yet bumbling Mr. Woodhouse, the enigmatic Mr. Knightley, and the sweet but impressionable Harriet Smith. Austen\'s keen observations of social manners and her sharp wit bring these characters to life, making "Emma" a timeless and engaging read. The novel is not only a charming love story but also a profound commentary on the intricacies of human relationships and the journey toward self-discovery.',
    firstLine:
      "Emma Woodhouse, handsome, clever, and rich, with a comfortable home and happy disposition, seemed to unite some of the best blessings of existence; and had lived nearly twenty-one years in the world with very little to distress or vex her.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 159,
    name: "The Island of Doctor Moreau",
    author: "H. G. Wells",
    category: "Science Fiction",
    blurb:
      '"The Island of Doctor Moreau" by H.G. Wells is a gripping science fiction novel that delves into the dark and unsettling realms of human ambition and ethical boundaries. The story follows Edward Prendick, a shipwreck survivor who finds himself stranded on a mysterious island governed by the enigmatic Doctor Moreau. As Prendick explores the island, he uncovers Moreau\'s horrifying experiments in vivisection, where animals are grotesquely transformed into human-animal hybrids. These creatures, bound by a strict set of laws imposed by Moreau, struggle with their dual natures, creating a tense and eerie atmosphere.\n\nWells masterfully weaves themes of scientific hubris, the nature of humanity, and the ethical implications of unchecked experimentation. The novel challenges readers to ponder the moral limits of scientific exploration and the potential consequences of playing god. "The Island of Doctor Moreau" remains a timeless and thought-provoking work, compelling readers to reflect on the fine line between civilization and savagery, and the inherent dangers of tampering with the natural order.',
    firstLine:
      "\"I do not propose to add anything to what has already been written concerning the loss of the 'Lady Vain.'\"",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 161,
    name: "Sense and Sensibility",
    author: "Jane Austen",
    category: "Romance",
    blurb:
      '"Sense and Sensibility" by Jane Austen is a timeless exploration of love, family, and societal expectations in early 19th-century England. The novel follows the lives of the Dashwood sisters, Elinor and Marianne, as they navigate the complexities of inheritance, romance, and social standing after the sudden death of their father. Elinor, embodying sense, is the epitome of rationality and restraint, while Marianne, representing sensibility, is driven by emotion and spontaneity. Their contrasting approaches to life and love provide a rich narrative that delves into the challenges and triumphs of finding balance between reason and passion.\n\nSet against the backdrop of the English gentry, the story intricately weaves themes of social class, economic hardship, and the pursuit of suitable marriages. Through a cast of memorable characters, including the charming but unreliable John Willoughby and the steadfast Colonel Brandon, Austen critiques the rigid social structures of her time while celebrating the resilience and growth of her heroines. "Sense and Sensibility" remains a poignant and engaging tale that continues to resonate with readers, offering a keen insight into human nature and the enduring quest for happiness and fulfillment.',
    firstLine: "The family of Dashwood had long been settled in Sussex.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 162,
    name: "Take Me for a Ride: Coming of Age in a Destructive Cult",
    author: "Mark E Laxer",
    category: "Biography",
    blurb:
      "\"Take Me for a Ride: Coming of Age in a Destructive Cult\" by Mark E. Laxer is a gripping memoir that delves into the author's harrowing journey through the world of a manipulative and destructive cult led by Frederick Lenz. Laxer recounts his experiences from the initial allure of spiritual enlightenment to the gradual realization of the cult's insidious control over its members. Through vivid storytelling, he exposes the psychological manipulation, emotional abuse, and the loss of personal freedom that characterized his time within the cult.\n\nThe book is not only a personal narrative but also a cautionary tale about the dangers of charismatic leaders and the vulnerability of those seeking meaning and belonging. Laxer's introspective and candid account provides valuable insights into the dynamics of cults and the profound impact they can have on individuals' lives. \"Take Me for a Ride\" is a compelling read for anyone interested in understanding the complexities of cult psychology and the resilience of the human spirit in the face of adversity.",
    firstLine:
      "After I left Rama's inner circle in 1985, I occasionally bicycled to Walden Pond, where I read about Thoreau's experiment with self-reliance.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 164,
    name: "Twenty Thousand Leagues under the Sea",
    author: "Jules Verne",
    category: "Adventure",
    blurb:
      '"Twenty Thousand Leagues Under the Sea" by Jules Verne is a pioneering science fiction novel that takes readers on an extraordinary underwater adventure. The story follows Professor Pierre Aronnax, his servant Conseil, and the Canadian whaler Ned Land, who are captured by Captain Nemo aboard the enigmatic submarine, the Nautilus. As they journey through the world\'s oceans, they encounter a myriad of marine wonders and face numerous perils, from giant squids to underwater volcanoes.\n\nCaptain Nemo, a complex and enigmatic character, reveals his disdain for the surface world and his desire for freedom beneath the waves. Through Verne\'s vivid descriptions and imaginative storytelling, readers are immersed in a fantastical yet scientifically grounded exploration of the deep sea. The novel not only captivates with its adventurous spirit but also delves into themes of isolation, the quest for knowledge, and the human impact on nature. "Twenty Thousand Leagues Under the Sea" remains a timeless classic, inspiring generations with its visionary depiction of underwater exploration.',
    firstLine:
      "The year 1866 was signalised by a remarkable incident, a mysterious and puzzling phenomenon, which doubtless no one has yet forgotten.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 165,
    name: "McTeague: A Story of San Francisco",
    author: "Frank Norris",
    category: "Novel",
    blurb:
      '"McTeague: A Story of San Francisco" by Frank Norris is a gripping tale that delves into the darker aspects of human nature and the destructive power of greed. Set in the bustling and diverse city of San Francisco at the turn of the 20th century, the novel follows the life of McTeague, a simple and brutish dentist whose life spirals out of control due to his insatiable avarice. The story explores his tumultuous marriage to Trina, a woman whose own obsession with money leads to tragic consequences.\n\nAs McTeague\'s fortunes rise and fall, Norris paints a vivid picture of the psychological and social forces at play, offering a stark commentary on the corrupting influence of wealth and the fragility of human morality. The novel\'s intense narrative and richly drawn characters make it a powerful exploration of the human condition, set against the backdrop of a rapidly changing American society. Through its unflinching portrayal of ambition, jealousy, and downfall, "McTeague" remains a compelling and thought-provoking read.',
    firstLine:
      "It was Sunday, and, according to his custom on that day, McTeague took his dinner at two in the afternoon at the car conductors' coffee-joint on Polk Street.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 166,
    name: "Summer",
    author: "Edith Wharton",
    category: "Novel",
    blurb:
      'In Edith Wharton\'s "Summer," readers are transported to the picturesque yet confining world of the Berkshire Hills in Massachusetts. The novel follows the life of Charity Royall, a young woman who is taken in by a guardian, Lawyer Royall, from the impoverished mountain community where she was born. As Charity grows up, she becomes increasingly restless and yearns for a life beyond the stifling confines of her small town. Her world is turned upside down when she meets Lucius Harney, a sophisticated and charming architect who introduces her to the possibilities of love and a broader existence.\n\nWharton masterfully explores themes of desire, societal constraints, and the quest for personal freedom. Through Charity\'s tumultuous journey, the novel delves into the complexities of human relationships and the often harsh realities faced by women in the early 20th century. "Summer" is a poignant and evocative tale that captures the intensity of youthful passion and the bittersweet nature of self-discovery.',
    firstLine:
      "A girl came out of lawyer Royall's house, at the end of the one street of North Dormer, and stood on the doorstep.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 168,
    name: "The History and Practice of the Art of Photography",
    author: "Henry Hunt Snelling",
    category: "Art",
    blurb:
      '"The History and Practice of the Art of Photography" by Henry Hunt Snelling is a seminal work that delves into the origins, development, and technical aspects of early photography, with a particular focus on the daguerreotype process. Written in the mid-19th century, this book serves as both a historical account and a practical guide, offering readers a comprehensive understanding of the art and science behind one of the most revolutionary inventions of the time. Snelling meticulously documents the contributions of pioneers like Louis Daguerre and Joseph Nicéphore Niépce, providing detailed descriptions of their methods and the chemical processes involved.\n\nIn addition to its historical narrative, the book is a valuable resource for practitioners of early photography. It includes step-by-step instructions on creating daguerreotypes, from preparing the silver-plated copper plates to developing and fixing the images. Snelling\'s work is enriched with practical tips and troubleshooting advice, making it an indispensable manual for photographers of his era. Through its blend of historical insight and technical guidance, "The History and Practice of the Art of Photography" captures the transformative impact of photography on art, science, and society.',
    firstLine:
      "As in all cases of great and valuable inventions in science and art the English lay claim to the honor of having first discovered that of Photogenic drawing.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 169,
    name: "The Well at the World's End: A Tale",
    author: "William Morris",
    category: "Fantasy",
    blurb:
      "In \"The Well at the World's End: A Tale,\" William Morris weaves a rich tapestry of fantasy and adventure, following the journey of young Ralph of Upmeads. Eager to escape the confines of his sheltered life, Ralph embarks on a quest to find the legendary Well at the World's End, a mystical source of eternal life and boundless wisdom. Along the way, he encounters a host of vivid characters, from noble knights and wise hermits to treacherous foes and enchanting maidens, each adding depth and intrigue to his perilous journey.\n\nSet against a backdrop of medieval landscapes and imbued with Morris's poetic prose, the novel explores themes of courage, destiny, and the eternal struggle between good and evil. As Ralph navigates through trials and tribulations, he not only seeks the fabled well but also discovers the true essence of his own character and the transformative power of love and sacrifice. \"The Well at the World's End\" stands as a timeless epic, celebrating the enduring spirit of adventure and the quest for deeper meaning in a world brimming with wonder and peril.",
    firstLine:
      "Long ago there was a little land, over which ruled a regulus or kinglet, who was called King Peter, though his kingdom was but little.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 170,
    name: "The Haunted Hotel: A Mystery of Modern Venice",
    author: "Wilkie Collins",
    category: "Mystery",
    blurb:
      'In "The Haunted Hotel: A Mystery of Modern Venice," Wilkie Collins masterfully intertwines the eerie allure of Venice with a gripping detective story. The narrative begins with the enigmatic Countess Narona, whose impending marriage to the wealthy Lord Montbarry is shrouded in suspicion and foreboding. When Lord Montbarry dies under mysterious circumstances in a Venetian palace-turned-hotel, and his courier vanishes without a trace, a web of intrigue and supernatural elements begins to unravel.\n\nAs the story progresses, the hotel becomes a focal point of ghostly occurrences and dark secrets. The bereaved family, along with the astute detective, must navigate the labyrinthine canals and shadowy corridors of Venice to uncover the truth. Collins\' atmospheric prose and intricate plotting create a suspenseful tale that explores themes of betrayal, revenge, and the supernatural, making "The Haunted Hotel" a quintessential Victorian mystery that keeps readers on the edge of their seats.',
    firstLine:
      "In the year 1860, the reputation of Doctor Wybrow as a London physician reached its highest point.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 171,
    name: "Charlotte Temple",
    author: "Mrs Rowson",
    category: "Novel",
    blurb:
      '"Charlotte Temple" by Mrs. Rowson is a poignant tale of innocence, betrayal, and the harsh realities faced by a young woman in the 18th century. The novel follows the life of Charlotte, a naive British teenager who is seduced by the charming but unscrupulous soldier, Montraville. Persuaded to elope with him to America, Charlotte leaves behind her family and the safety of her home, only to find herself abandoned and destitute in New York.\n\nAs Charlotte grapples with the consequences of her decisions, including an illegitimate pregnancy, the story delves into themes of societal expectations, the vulnerability of young women, and the devastating impact of betrayal. Through Charlotte\'s tragic journey, Mrs. Rowson crafts a didactic narrative that serves as a cautionary tale, highlighting the importance of virtue and the perils of succumbing to temptation. "Charlotte Temple" remains a significant work in early American literature, offering a compelling exploration of the psychological and social challenges faced by women of the era.',
    firstLine:
      "“Are you for a walk,” said Montraville to his companion, as they arose from table; “are you for a walk? or shall we order the chaise and proceed to Portsmouth?”",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 172,
    name: "The Haunted Bookshop",
    author: "Christopher Morley",
    category: "Mystery",
    blurb:
      "In \"The Haunted Bookshop\" by Christopher Morley, readers are transported to the quaint and mysterious world of Parnassus at Home, a charming second-hand bookstore in Brooklyn, New York. The shop is owned by the enigmatic Roger Mifflin, a passionate bibliophile who believes in the transformative power of books. When a young advertising man named Aubrey Gilbert visits the shop, he becomes entangled in a web of intrigue and suspense. Gilbert's initial interest in the bookstore's unique charm quickly turns into a thrilling adventure as he uncovers a series of strange occurrences and hidden secrets within the shop's dusty shelves.\n\nSet against the backdrop of post-World War I America, \"The Haunted Bookshop\" is a delightful blend of mystery, romance, and literary homage. Morley's narrative is rich with witty dialogue, eccentric characters, and a deep love for the written word. As Gilbert and Mifflin delve deeper into the mystery, they discover that the true hauntings of the bookshop are not of the supernatural kind, but rather the lingering echoes of history, ideas, and the enduring spirit of literature. This captivating tale is a must-read for anyone who cherishes the magic of books and the adventures they inspire.",
    firstLine:
      "If you are ever in Brooklyn, that borough of superb sunsets and magnificent vistas of husband-propelled baby-carriages, it is to be hoped you may chance upon a quiet by-street where there is a very remarkable bookshop.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 173,
    name: "The Insidious Dr. Fu Manchu",
    author: "Sax Rohmer",
    category: "Mystery",
    blurb:
      'In "The Insidious Dr. Fu Manchu," Sax Rohmer introduces readers to one of literature\'s most notorious villains, the enigmatic and malevolent Dr. Fu Manchu. Set against the backdrop of early 20th-century London, the novel follows the relentless pursuit of this criminal mastermind by the intrepid British detective, Nayland Smith, and his loyal companion, Dr. Petrie. Fu Manchu, a brilliant but ruthless figure, is determined to bring the Western world to its knees through a series of diabolical schemes, employing his vast knowledge of Eastern poisons, secret societies, and arcane sciences.\n\nAs Smith and Petrie delve deeper into the shadowy underworld controlled by Fu Manchu, they encounter a series of harrowing challenges and near-fatal encounters. The novel is a thrilling blend of mystery, adventure, and suspense, with Rohmer\'s vivid descriptions and fast-paced narrative keeping readers on the edge of their seats. "The Insidious Dr. Fu Manchu" not only captivates with its intricate plot and memorable characters but also reflects the cultural anxieties and fascinations of its time, making it a seminal work in the detective and mystery genre.',
    firstLine: '"A gentleman to see you, Doctor."',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 175,
    name: "The Phantom of the Opera",
    author: "Gaston Leroux",
    category: "Horror",
    blurb:
      "\"The Phantom of the Opera\" by Gaston Leroux is a haunting tale set in the opulent Paris Opera House, where a mysterious and disfigured musical genius known only as the Phantom lurks in the shadows. The story revolves around the beautiful and talented Christine Daaé, a young soprano who becomes the object of the Phantom's obsessive affection. As Christine's star rises, she finds herself torn between her love for her childhood friend, Raoul, and the dark allure of the Phantom, who promises to make her the greatest singer in the world.\n\nThe novel masterfully blends elements of horror, romance, and mystery, as it delves into the Phantom's tragic past and his reign of terror over the opera house. Leroux's vivid descriptions and intricate plot twists keep readers on the edge of their seats, exploring themes of love, jealousy, and the fine line between genius and madness. \"The Phantom of the Opera\" remains a timeless classic, captivating audiences with its unforgettable characters and the eerie, atmospheric world it creates.",
    firstLine:
      "It was the evening on which M. Debienne and M. Poligny, the managers of the Opera, were giving a last gala performance to mark their retirement.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 176,
    name: "Roderick Hudson",
    author: "Henry James",
    category: "Novel",
    blurb:
      "\"Roderick Hudson\" by Henry James is a compelling exploration of ambition, artistry, and the complexities of human relationships. The novel follows the journey of the titular character, Roderick Hudson, a young and talented American sculptor who is taken under the wing of Rowland Mallet, a wealthy art patron. Together, they travel to Rome, where Roderick's artistic potential is set to flourish amidst the rich cultural backdrop of the Eternal City. However, as Roderick becomes entangled in the intoxicating allure of his new environment, his personal and professional life begins to unravel.\n\nSet against the vivid and romantic landscape of Italy, the story delves into themes of passion, jealousy, and the often-destructive nature of genius. Roderick's relationships, particularly with the enigmatic Christina Light and the steadfast Mary Garland, serve as focal points for his internal struggles and external conflicts. Henry James masterfully portrays the tension between artistic aspiration and personal downfall, crafting a narrative that is as much about the human condition as it is about the world of art.",
    firstLine:
      "Rowland Mallet had made his arrangements to sail for Europe on the first of September, and having in the interval a fortnight to spare, he determined to spend it with his cousin Cecilia, the widow of a nephew of his father.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 177,
    name: "The American",
    author: "Henry James",
    category: "Novel",
    blurb:
      "\"The American\" by Henry James is a compelling exploration of cultural clash and personal transformation set against the backdrop of 19th-century Paris. The novel follows Christopher Newman, a wealthy and self-made American businessman, who travels to Europe in search of art, culture, and a wife. His journey leads him to the aristocratic Bellegarde family, where he becomes enamored with the beautiful and enigmatic Claire de Cintré. However, Newman's straightforward American values soon collide with the intricate and often duplicitous social mores of the European aristocracy.\n\nAs Newman navigates the labyrinthine world of French high society, he encounters resistance and intrigue, forcing him to confront the complexities of love, honor, and ambition. The novel delves deeply into themes of cultural conflict, the nature of true nobility, and the psychological struggles of its characters. Through Newman's trials and tribulations, Henry James masterfully examines the contrasts between the New World and the Old, offering a rich and nuanced portrait of human relationships and societal expectations.",
    firstLine:
      "On a brilliant day in May, in the year 1868, a gentleman was reclining at his ease on the great circular divan which at that period occupied the centre of the Salon Carré, in the Museum of the Louvre.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 178,
    name: "Confidence",
    author: "Henry James",
    category: "Novel",
    blurb:
      '"Confidence" by Henry James is a compelling exploration of the intricate dynamics of friendship, love, and societal expectations. Set against the picturesque backdrop of Europe, the novel delves into the lives of three Americans abroad: Bernard Longueville, a charming and confident artist; his close friend, Gordon Wright, a wealthy and introspective scientist; and Angela Vivian, a captivating and enigmatic woman who becomes the focal point of their affections. As Bernard and Gordon navigate their complex feelings for Angela, their friendship is tested in unexpected ways, revealing the delicate balance between loyalty and desire.\n\nJames masterfully portrays the subtleties of human relationships, capturing the nuances of male camaraderie and the often unspoken tensions that arise when romantic interests intersect. Through his richly drawn characters and eloquent prose, "Confidence" offers a timeless reflection on the nature of trust, the pursuit of happiness, and the societal pressures that shape our choices. This novel is a testament to Henry James\'s keen insight into the human heart and his ability to weave a narrative that is both intellectually stimulating and emotionally resonant.',
    firstLine:
      "It was in the early days of April; Bernard Longueville had been spending the winter in Rome.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 179,
    name: "The Europeans",
    author: "Henry James",
    category: "Novel",
    blurb:
      '"The Europeans" by Henry James is a delightful exploration of cultural contrasts and social mores, set against the backdrop of 19th-century Boston. The story follows the arrival of two European siblings, the charming and sophisticated Eugenia Munster and her artistic brother, Felix Young, who come to visit their American relatives, the Wentworths. As they navigate the rigid and puritanical society of New England, their presence stirs up both admiration and consternation, leading to a series of humorous and poignant encounters.\n\nThrough the lens of these contrasting worlds, James deftly examines themes of identity, tradition, and the clash between Old World elegance and New World simplicity. The novel\'s rich characterizations and witty dialogue offer a keen insight into the complexities of social interactions and the subtle tensions that arise when different cultures meet. "The Europeans" is a masterful blend of comedy and social commentary, showcasing Henry James\'s enduring talent for capturing the nuances of human behavior and the intricacies of familial relationships.',
    firstLine:
      "A narrow grave-yard in the heart of a bustling, indifferent city, seen from the windows of a gloomy-looking inn, is at no time an object of enlivening suggestion; and the spectacle is not at its best when the mouldy tombstones and funereal umbrage have received the ineffectual refreshment of a dull, moist snow-fall.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 202,
    name: "My Bondage and My Freedom",
    author: "Frederick Douglass",
    category: "Biography",
    blurb:
      '"My Bondage and My Freedom" is an autobiographical narrative by Frederick Douglass, a former enslaved person who became a leading voice in the abolitionist movement. In this profound and powerful work, Douglass recounts his early life in Maryland, detailing the harsh realities and brutal conditions of slavery. He provides a vivid portrayal of the physical and psychological torment endured by enslaved individuals, while also highlighting the resilience and determination that fueled his quest for freedom.\n\nDouglass\'s journey from bondage to freedom is not just a personal triumph but also a scathing critique of the institution of slavery and its dehumanizing effects. Through his eloquent prose and compelling storytelling, Douglass exposes the moral and social injustices of his time, advocating for the abolition of slavery and the recognition of African American rights. "My Bondage and My Freedom" stands as a testament to Douglass\'s enduring legacy as a champion for equality and human dignity, offering readers an intimate glimpse into the life of one of America\'s most influential abolitionists.',
    firstLine:
      "In Talbot county, Eastern Shore, Maryland, near Easton, the county town of that county, there is a small district of country, thinly populated, and remarkable for nothing that I know of more than for the worn-out, sandy, desert-like appearance of its soil, the general dilapidation of its farms and fences, the indigent and spiritless character of its inhabitants, and the prevalence of ague and fever.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 203,
    name: "Uncle Tom's Cabin",
    author: "Harriet Beecher Stowe",
    category: "Novel",
    blurb:
      "\"Uncle Tom's Cabin\" by Harriet Beecher Stowe is a seminal work of American literature that delves into the brutal realities of slavery in the 19th-century Southern United States. Through the poignant and heartrending story of Uncle Tom, a dignified and devout enslaved man, Stowe exposes the inhumanity and moral corruption inherent in the institution of slavery. The novel follows Tom's journey as he is sold from one owner to another, each representing different facets of the slaveholding society, from the relatively kind-hearted Mr. Shelby to the cruel and sadistic Simon Legree.\n\nInterwoven with Tom's narrative are the stories of other characters, such as the courageous Eliza, who escapes with her son across the frozen Ohio River, and the conflicted Augustine St. Clare, who grapples with his own complicity in the system. Through these interconnected lives, Stowe crafts a powerful and didactic tale that not only aims to evoke empathy and outrage but also to galvanize the abolitionist movement. \"Uncle Tom's Cabin\" remains a critical exploration of the moral and social ramifications of slavery, and its impact on American history and literature is profound and enduring.",
    firstLine:
      "Late in the afternoon of a chilly day in February, two gentlemen were sitting alone over their wine, in a well-furnished dining parlor, in the town of Paint Lick, in Kentucky.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 205,
    name: "Walden, and On The Duty Of Civil Disobedience",
    author: "Henry David Thoreau",
    category: "Non-Fiction",
    blurb:
      'In "Walden, and On The Duty Of Civil Disobedience," Henry David Thoreau presents a profound exploration of simple living, self-sufficiency, and individual conscience. "Walden" chronicles Thoreau\'s experiment in transcendentalist philosophy as he retreats to a cabin in the woods near Walden Pond in Massachusetts. Through his reflections on nature, solitude, and the essentials of life, Thoreau advocates for a minimalist lifestyle that fosters personal growth and a deeper connection to the natural world. His observations and musings offer timeless insights into the human condition and the pursuit of a meaningful existence.\n\nIn "On The Duty Of Civil Disobedience," Thoreau extends his philosophical inquiry to the realm of social and political ethics. He argues for the moral imperative to resist unjust government actions through nonviolent means. Drawing from his own experience of being jailed for refusing to pay a poll tax that funded the Mexican-American War and supported slavery, Thoreau makes a compelling case for individual conscience over compliance with unjust laws. Together, these works challenge readers to reflect on their own lives, their relationship with society, and the principles that guide their actions.',
    firstLine:
      "When I wrote the following pages, or rather the bulk of them, I lived alone, in the woods, a mile from any neighbor, in a house which I had built myself, on the shore of Walden Pond, in Concord, Massachusetts, and earned my living by the labor of my hands only.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 208,
    name: "Daisy Miller: A Study",
    author: "Henry James",
    category: "Novel",
    blurb:
      '"Daisy Miller: A Study" by Henry James is a compelling novella that delves into the complexities of social conventions and cultural clashes through the experiences of a young American woman in Europe. The story follows Daisy Miller, a spirited and independent young lady from Schenectady, New York, as she navigates the sophisticated social circles of Europe. Her free-spirited nature and disregard for societal expectations quickly make her the subject of gossip and scrutiny among the expatriate community.\n\nThe novella is narrated by Winterbourne, a fellow American who becomes both fascinated and perplexed by Daisy\'s behavior. As he observes her interactions and the judgments she faces, Winterbourne grapples with his own perceptions and the rigid social codes of the time. Through Daisy\'s tragic journey, Henry James masterfully explores themes of innocence, freedom, and the often harsh consequences of defying societal norms. "Daisy Miller: A Study" remains a poignant examination of cultural differences and the enduring struggle between individuality and conformity.',
    firstLine:
      "At the little town of Vevey, in Switzerland, there is a particularly comfortable hotel.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 209,
    name: "The Turn of the Screw",
    author: "Henry James",
    category: "Horror",
    blurb:
      'In Henry James\'s chilling novella, "The Turn of the Screw," a young, inexperienced governess is hired to care for two orphaned children, Flora and Miles, at a remote English estate. As she settles into her new role, she begins to witness strange and unsettling apparitions of a man and a woman, whom she believes to be the ghosts of the former valet, Peter Quint, and her predecessor, Miss Jessel. Convinced that these malevolent spirits are intent on corrupting the innocent children, the governess becomes increasingly obsessed with protecting them, even as her own grip on reality starts to unravel.\n\nJames masterfully weaves a tale of psychological horror, leaving readers to question the reliability of the governess\'s narrative and the true nature of the supernatural events. Is she a brave protector battling evil forces, or is she descending into madness, projecting her fears onto the children? "The Turn of the Screw" remains a haunting exploration of the human psyche, the ambiguity of truth, and the thin line between reality and imagination.',
    firstLine:
      "The story had held us, round the fire, sufficiently breathless, but except the obvious remark that it was gruesome, as, on Christmas Eve in an old house, a strange tale should essentially be, I remember no comment uttered till somebody happened to say that it was the only case he had met in which such a visitation had fallen on a child.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 210,
    name: "An International Episode",
    author: "Henry James",
    category: "Novel",
    blurb:
      '"An International Episode" by Henry James is a captivating novella that delves into the cultural contrasts and social intricacies between Americans and the British during the late 19th century. The story follows two American gentlemen, Bessie Alden and her brother, who travel to England and become entangled in the lives of two British aristocrats, Lord Lambeth and Percy Beaumont. Through their interactions, James masterfully explores themes of manners, customs, and the subtle yet profound differences in social etiquette and expectations between the two nations.\n\nSet against the vibrant backdrops of New York and London, the novella offers a keen observation of the social life and customs in both locales. James\'s nuanced portrayal of the characters\' experiences and the ensuing romantic entanglements provides a rich commentary on the complexities of international relationships and the often humorous misunderstandings that arise from cultural differences. "An International Episode" is a delightful and insightful exploration of transatlantic relations, showcasing Henry James\'s signature wit and keen eye for social detail.',
    firstLine:
      "Four years ago--in 1874--two young Englishmen had occasion to go to the United States.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 211,
    name: "The Aspern Papers",
    author: "Henry James",
    category: "Novel",
    blurb:
      'In "The Aspern Papers," Henry James crafts a compelling tale set against the enchanting backdrop of Venice. The story revolves around an unnamed narrator, a literary scholar obsessed with obtaining the private papers of the deceased American poet Jeffrey Aspern. To achieve his goal, the narrator rents rooms in the dilapidated palazzo of Juliana Bordereau, Aspern\'s former lover, and her reclusive niece, Miss Tina. As he ingratiates himself with the two women, the narrator\'s single-minded pursuit of the papers leads him into a web of deceit, manipulation, and moral ambiguity.\n\nJames masterfully explores themes of obsession, the ethics of literary scholarship, and the complex dynamics of human relationships. The decaying grandeur of Venice serves as a fitting metaphor for the narrator\'s own moral decay, as he becomes increasingly entangled in his quest. "The Aspern Papers" is a richly layered psychological drama that delves into the lengths to which people will go to possess a piece of history, and the personal costs of such an endeavor.',
    firstLine:
      "I had taken Mrs. Prest into my confidence; in truth without her I should have made but little advance, for the fruitful idea in the whole business dropped from her friendly lips.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 215,
    name: "The Call of the Wild",
    author: "Jack London",
    category: "Adventure",
    blurb:
      '"The Call of the Wild" by Jack London is a gripping adventure novel that follows the journey of Buck, a domesticated dog who is stolen from his comfortable home in California and sold into the harsh life of an Alaskan sled dog during the Klondike Gold Rush. As Buck is thrust into the brutal and unforgiving wilderness, he must adapt to survive, discovering his primal instincts and inner strength along the way. The story vividly portrays Buck\'s transformation from a pampered pet to a fierce, independent leader of the wild.\n\nSet against the backdrop of the icy Yukon, London\'s narrative explores themes of survival, the call of nature, and the struggle for dominance. Through Buck\'s eyes, readers experience the raw beauty and relentless challenges of the wild, as well as the complex relationships between humans and animals. "The Call of the Wild" is not just a tale of adventure, but a profound exploration of the instinctual forces that drive all creatures, making it a timeless classic in literature.',
    firstLine:
      "Buck did not read the newspapers, or he would have known that trouble was brewing, not alone for himself, but for every tide-water dog, strong of muscle and with warm, long hair, from Puget Sound to San Diego.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 216,
    name: "The Tao Teh King, or the Tao and its Characteristics",
    author: "Laozi",
    category: "Non-Fiction",
    blurb:
      '"The Tao Teh King, or the Tao and its Characteristics" by Laozi is a foundational text of Taoism, offering profound insights into the nature of existence, the universe, and the path to harmony and balance. Composed of 81 short chapters, this ancient Chinese classic explores the concept of the Tao, often translated as "the Way," which represents the fundamental principle that underlies and unites all things. Laozi\'s teachings emphasize simplicity, humility, and the importance of aligning oneself with the natural order to achieve a life of peace and fulfillment.\n\nThrough poetic and paradoxical language, Laozi delves into the characteristics of the Tao, advocating for a life of non-action (wu wei), where one acts in accordance with the flow of life rather than against it. The text also addresses the qualities of the ideal ruler and the virtues of wisdom, compassion, and moderation. "The Tao Teh King" remains a timeless guide, offering readers a path to inner tranquility and a deeper understanding of the interconnectedness of all things.',
    firstLine:
      "The Tao that can be trodden is not the enduring and unchanging Tao.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 217,
    name: "Sons and Lovers",
    author: "D. H. Lawrence",
    category: "Novel",
    blurb:
      '"Sons and Lovers" by D.H. Lawrence is a profound exploration of familial bonds, class struggles, and the complexities of human relationships. Set in the coal-mining town of Nottinghamshire, England, the novel follows the life of Paul Morel, a young man torn between his deep attachment to his mother, Gertrude, and his romantic entanglements. Gertrude, trapped in a loveless marriage with Walter Morel, a coarse and often drunken miner, pours all her hopes and dreams into her sons, particularly Paul. This intense maternal bond shapes Paul\'s emotional and psychological development, influencing his relationships with women and his quest for personal identity.\n\nAs Paul navigates the tumultuous waters of love, ambition, and self-discovery, Lawrence delves into themes of industrialization, class conflict, and the struggle for individual fulfillment. The novel is a poignant portrayal of the working-class experience in early 20th-century England, capturing the tensions between tradition and change, duty and desire. Through rich, evocative prose and deeply drawn characters, "Sons and Lovers" offers a timeless examination of the human condition, making it a cornerstone of modern literature.',
    firstLine: '"The Bottoms" succeeded to "Hell Row".',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 219,
    name: "Heart of Darkness",
    author: "Joseph Conrad",
    category: "Novel",
    blurb:
      '"Heart of Darkness" by Joseph Conrad is a profound exploration of the human psyche set against the backdrop of European imperialism in Africa. The novella follows Charles Marlow, a seasoned seaman, as he recounts his harrowing journey up the Congo River to find Kurtz, an enigmatic ivory trader who has succumbed to the wilderness and his own dark impulses. Through Marlow\'s eyes, readers witness the stark contrast between the so-called "civilized" Europeans and the untamed African landscape, revealing the thin veneer of civilization and the lurking potential for savagery within all humans.\n\nAs Marlow ventures deeper into the heart of the Congo, he encounters the brutal realities of colonial exploitation and the moral decay it engenders. Kurtz, once an idealistic and eloquent man, has become a demigod among the indigenous people, embodying the corrupting influence of unchecked power and isolation. Conrad\'s narrative delves into themes of degeneration, the duality of human nature, and the existential darkness that lies within. "Heart of Darkness" remains a timeless and unsettling examination of the complexities of the human soul and the devastating impact of imperialism.',
    firstLine:
      "The Nellie, a cruising yawl, swung to her anchor without a flutter of the sails, and was at rest.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 220,
    name: "The Secret Sharer",
    author: "Joseph Conrad",
    category: "Novel",
    blurb:
      'In "The Secret Sharer," Joseph Conrad crafts a compelling tale of duality and self-discovery set against the backdrop of the high seas. The story follows a young, inexperienced ship captain who encounters a fugitive named Leggatt, a former first mate accused of murder. As the captain secretly shelters Leggatt in his cabin, he grapples with his own insecurities and the moral complexities of his actions. The bond that forms between the two men becomes a profound exploration of identity, leadership, and the thin line between right and wrong.\n\nConrad\'s masterful prose delves deep into the psychological landscape of his characters, creating a tense and introspective narrative. The isolation of the sea mirrors the captain\'s internal struggle, making "The Secret Sharer" a rich, thought-provoking read that examines the nature of human connection and the shadows that reside within us all. Through this gripping sea story, Conrad invites readers to ponder the hidden facets of their own selves and the secrets they harbor.',
    firstLine:
      "On my right hand there were lines of fishing stakes resembling a mysterious system of half-submerged bamboo fences, incomprehensible in its division of the domain of tropical fishes, and crazy of aspect as if abandoned forever by some nomad tribe of fishermen now gone to the other end of the ocean; for there was no sign of human habitation as far as the eye could reach.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 222,
    name: "The Moon and Sixpence",
    author: "W Maugham",
    category: "Novel",
    blurb:
      "\"The Moon and Sixpence\" by W. Somerset Maugham is a compelling novel that delves into the life of Charles Strickland, a seemingly ordinary London stockbroker who abruptly abandons his family and career to pursue his fervent passion for painting. Inspired by the life of the post-impressionist artist Paul Gauguin, the story explores Strickland's relentless quest for artistic fulfillment, which leads him to the exotic landscapes of Tahiti. Through the eyes of the unnamed narrator, readers witness Strickland's transformation from a conventional man into a visionary artist, driven by an insatiable need to create, regardless of the personal and social costs.\n\nSet against the backdrop of early 20th-century Europe and the South Pacific, Maugham's novel is a profound exploration of the sacrifices and obsessions that often accompany the pursuit of artistic genius. Strickland's journey is marked by his indifference to societal norms and the suffering he inflicts on those around him, raising poignant questions about the nature of art, the price of creativity, and the complexities of human relationships. \"The Moon and Sixpence\" is a richly textured narrative that captures the tumultuous inner world of an artist and the often harsh realities of following one's true calling.",
    firstLine:
      "I confess that when first I made acquaintance with Charles Strickland I never for a moment discerned that there was in him anything out of the ordinary.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 223,
    name: "The Wisdom of Father Brown",
    author: "G Chesterton",
    category: "Mystery",
    blurb:
      'In "The Wisdom of Father Brown," G.K. Chesterton presents a collection of twelve intriguing detective stories featuring the astute and unassuming Father Brown. This mild-mannered Catholic priest, with his keen intellect and deep understanding of human nature, unravels complex mysteries that baffle even the most seasoned detectives. Set against the backdrop of early 20th-century England, each story showcases Father Brown\'s unique approach to solving crimes, blending his theological insights with sharp logical reasoning.\n\nAs Father Brown navigates through a series of perplexing cases involving theft, murder, and deception, he reveals the profound wisdom that lies beneath his humble exterior. His ability to see beyond the obvious and delve into the moral and psychological dimensions of each case sets him apart as a detective. "The Wisdom of Father Brown" not only entertains with its clever plots and unexpected twists but also offers readers a deeper reflection on the nature of good and evil, making it a timeless classic in the realm of detective fiction.',
    firstLine:
      "The consulting-rooms of Dr Orion Hood, the eminent criminologist and specialist in certain moral disorders, lay along the sea-front at Scarborough, in a series of very large and well-lighted french windows, which showed the North Sea like one endless outer wall of blue-green marble.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 224,
    name: "A Pair of Blue Eyes",
    author: "Thomas Hardy",
    category: "Romance",
    blurb:
      "\"A Pair of Blue Eyes\" by Thomas Hardy is a compelling tale set in the picturesque yet rugged landscape of Wessex, England. The novel follows the life of Elfride Swancourt, a young and naive woman who finds herself entangled in a complex web of romantic and social dilemmas. Elfride's journey begins when she falls in love with Stephen Smith, a modest architect with humble origins. Their budding romance faces societal disapproval and personal insecurities, leading to a series of misunderstandings and separations.\n\nAs the story unfolds, Elfride becomes the object of affection for another suitor, the intellectual and sophisticated Henry Knight. This love triangle intensifies the emotional and psychological struggles of the characters, exploring themes of class disparity, personal growth, and the consequences of choices. Hardy's rich narrative and vivid descriptions of the Wessex countryside provide a poignant backdrop to this intricate exploration of love, loyalty, and the human condition.",
    firstLine:
      '"Elfride Swancourt was a girl whose emotions lay very near the surface."',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 225,
    name: "At the Back of the North Wind",
    author: "George MacDonald",
    category: "Fantasy",
    blurb:
      '"At the Back of the North Wind" by George MacDonald is a timeless fairy tale that weaves together elements of fantasy, adventure, and profound philosophical musings. The story follows young Diamond, a kind-hearted and curious boy who lives in a poor London neighborhood. His life takes a magical turn when he meets the North Wind, a mystical and enchanting figure who personifies the natural force. Together, they embark on a series of fantastical journeys that take them to wondrous and mysterious places, including the ethereal realm "at the back of the North Wind."\n\nThroughout their adventures, Diamond learns valuable lessons about life, love, and the nature of existence. The North Wind, both nurturing and formidable, serves as a guide and protector, helping Diamond navigate the complexities of the world around him. MacDonald\'s rich narrative and vivid imagery create a captivating tale that explores themes of innocence, resilience, and the eternal struggle between good and evil. "At the Back of the North Wind" is a poignant and enchanting story that continues to resonate with readers of all ages, offering a glimpse into the boundless possibilities of the human spirit.',
    firstLine:
      "I have been asked to tell you about the back of the north wind.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 233,
    name: "Sister Carrie: A Novel",
    author: "Theodore Dreiser",
    category: "Novel",
    blurb:
      '"Sister Carrie" by Theodore Dreiser is a compelling narrative that delves into the life of a young woman, Carrie Meeber, who leaves her rural home in search of a better life in the bustling cities of Chicago and New York. The novel explores her journey from a naive country girl to a successful actress, highlighting the stark realities of urban life and the moral complexities of ambition and desire. As Carrie navigates through relationships and societal expectations, she becomes entangled with men who influence her rise and fall, reflecting the often harsh and unforgiving nature of the American Dream.\n\nSet against the backdrop of the late 19th and early 20th centuries, "Sister Carrie" is a poignant exploration of the themes of poverty, aspiration, and the pursuit of happiness. Dreiser\'s unflinching portrayal of Carrie\'s struggles and triumphs offers a critical look at the social and economic forces that shape individual destinies. The novel\'s rich character development and realistic depiction of urban life make it a timeless classic that continues to resonate with readers today.',
    firstLine:
      "When Caroline Meeber boarded the afternoon train for Chicago, her total outfit consisted of a small trunk, a cheap imitation alligator-skin satchel, a small lunch in a paper box, and a yellow leather snap purse, containing her ticket, a scrap of paper with her sister’s address in Van Buren Street, and four dollars in money.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 234,
    name: "Child Christopher and Goldilind the Fair",
    author: "William Morris",
    category: "Fantasy",
    blurb:
      "\"Child Christopher and Goldilind the Fair\" by William Morris is a captivating tale set in a richly imagined medieval world. The story follows the young and noble Child Christopher, who is raised in obscurity by a forester after being separated from his royal lineage. Unbeknownst to him, he is the rightful heir to a kingdom usurped by treachery. As he grows, Christopher's innate valor and sense of justice lead him on a quest to reclaim his birthright and restore honor to his family name.\n\nParallel to Christopher's journey is the story of Goldilind, a beautiful and gentle princess who has been wrongfully imprisoned by her cruel guardian. Her fate becomes intertwined with Christopher's as they navigate a landscape filled with danger, enchantment, and the timeless struggle between good and evil. Through trials and tribulations, their paths converge, revealing themes of love, bravery, and destiny. Morris's lyrical prose and vivid descriptions bring to life a world where chivalry and romance reign supreme, making \"Child Christopher and Goldilind the Fair\" a timeless fantasy classic.",
    firstLine:
      "Of old there was a land which was so much a woodland, that a minstrel thereof said it that a squirrel might go from end to end, and all about, from tree to tree, and never touch the earth: therefore was that land called Oakenrealm.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 235,
    name: "William Gibson Interviewed",
    author: "Giuseppe Salza",
    category: "Biography",
    blurb:
      "\"William Gibson Interviewed\" by Giuseppe Salza offers an in-depth exploration of the life and mind of William Gibson, the pioneering author who reshaped the landscape of science fiction. Through a series of insightful interviews, Salza delves into Gibson's creative process, his inspirations, and the cultural impact of his work. Readers are given a rare glimpse into the thoughts of the man behind iconic works like \"Neuromancer,\" which introduced the world to the concept of cyberspace and laid the groundwork for the cyberpunk genre.\n\nThe book not only captures Gibson's reflections on his own writing but also examines his views on technology, society, and the future. Salza's thoughtful questions and Gibson's candid responses create a compelling narrative that is both informative and engaging. Whether you're a long-time fan of Gibson's work or new to his visionary storytelling, \"William Gibson Interviewed\" provides a fascinating look at one of the most influential writers of the 20th and 21st centuries.",
    firstLine:
      'William Gibson was in Cannes in May 1994 to promote the filming of "Johnny Mnemonic", a $26 million science fiction movie based on his short story, and starring megastar Keanu Reeves as the main character.',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 236,
    name: "The Jungle Book",
    author: "Rudyard Kipling",
    category: "Adventure",
    blurb:
      '"The Jungle Book" by Rudyard Kipling is a timeless collection of short stories that transport readers into the heart of the Indian jungle. The book primarily follows the adventures of Mowgli, a young boy raised by wolves, as he learns the laws of the jungle and navigates its many dangers. Alongside Mowgli, readers meet a host of unforgettable characters, including the wise bear Baloo, the cunning panther Bagheera, and the fearsome tiger Shere Khan. Each story is a rich tapestry of adventure, moral lessons, and the intricate relationships between humans and animals.\n\nBeyond Mowgli\'s tale, Kipling\'s anthology also includes other captivating stories such as "Rikki-Tikki-Tavi," the brave mongoose who protects his human family from deadly cobras, and "Toomai of the Elephants," which explores the deep bond between a young boy and the majestic elephants he tends. Set against the lush and vibrant backdrop of 19th-century India, "The Jungle Book" is a masterful blend of fantasy and reality, offering readers a profound exploration of nature, survival, and the essence of community.',
    firstLine:
      "It was seven o’clock of a very warm evening in the Seeonee hills when Father Wolf woke up from his day’s rest, scratched himself, yawned, and spread out his paws one after the other to get rid of the sleepy feeling in their tips.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 238,
    name: "Dear Enemy",
    author: "Jean Webster",
    category: "Novel",
    blurb:
      '"Dear Enemy" by Jean Webster is a captivating epistolary novel that serves as a sequel to the beloved "Daddy-Long-Legs." The story unfolds through a series of letters written by Sallie McBride, a spirited and witty young woman who takes on the challenging role of superintendent at the John Grier Home, an orphanage. As Sallie navigates the complexities of managing the orphanage, she corresponds with her friends, including Judy Abbott (the protagonist of "Daddy-Long-Legs") and a mysterious Scottish doctor, Robin MacRae, whom she initially dubs her "enemy."\n\nThrough her letters, Sallie shares her trials, triumphs, and evolving relationships with the orphans and staff. Her correspondence with Dr. MacRae, in particular, evolves from antagonistic banter to a deeper, more nuanced connection. "Dear Enemy" is a heartwarming and insightful exploration of personal growth, social reform, and the transformative power of compassion and love. Jean Webster\'s engaging narrative and rich character development make this novel a timeless and endearing read.',
    firstLine:
      "Dear Judy: Your letter is here. I have read it twice, and with amazement.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 241,
    name: "Clotelle; Or, The President's Daughter",
    author: "William Wells Brown",
    category: "Novel",
    blurb:
      '"Clotelle; Or, The President\'s Daughter" by William Wells Brown is a poignant and groundbreaking novel that delves into the complex and often harrowing lives of African American women in the antebellum South. The story centers around Clotelle, the mixed-race daughter of an enslaved woman and a prominent white man, rumored to be President Thomas Jefferson. Through Clotelle\'s journey, Brown explores themes of identity, race, and the brutal realities of slavery, while also highlighting the resilience and strength of African American women.\n\nSet against the backdrop of the Southern United States, the novel weaves a narrative that is both personal and political, shedding light on the intimate struggles and broader societal issues faced by its characters. Brown\'s work is notable not only for its compelling storytelling but also for its historical significance, as it is one of the earliest novels published by an African American author. "Clotelle" remains a powerful and thought-provoking read, offering a unique perspective on American history and the enduring quest for freedom and equality.',
    firstLine:
      "For many years the South has been noted for its beautiful Quadroon women.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 242,
    name: "My Ántonia",
    author: "Willa Cather",
    category: "Novel",
    blurb:
      '"My Ántonia" by Willa Cather is a poignant and evocative novel that captures the spirit of the American frontier through the eyes of Jim Burden, an orphaned boy from Virginia who moves to Nebraska to live with his grandparents. The story is a rich tapestry of pioneer life, depicting the hardships and triumphs of the early settlers. Central to the narrative is Ántonia Shimerda, a spirited and resilient young immigrant girl from Bohemia, whose friendship with Jim becomes a cornerstone of his life. Through Jim\'s recollections, Cather paints a vivid picture of the vast Nebraska landscape and the indomitable human spirit that tames it.\n\nThe novel delves deeply into themes of friendship, nostalgia, and the passage of time, exploring how the bonds formed in youth can shape one\'s identity and outlook on life. Ántonia\'s journey from a hopeful immigrant girl to a strong, independent woman mirrors the broader story of the American frontier, highlighting the struggles and resilience of women pioneers. "My Ántonia" is not just a story of personal growth and friendship, but also a tribute to the enduring spirit of the people who built the American Midwest. Through its lyrical prose and richly drawn characters, the novel remains a timeless exploration of the human condition and the transformative power of the land.',
    firstLine:
      "I first heard of Ántonia on what seemed to me an interminable journey across the great midland plain of North America.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 244,
    name: "A Study in Scarlet",
    author: "Arthur Conan Doyle",
    category: "Mystery",
    blurb:
      '"A Study in Scarlet" by Arthur Conan Doyle introduces the world to the legendary detective Sherlock Holmes and his loyal friend, Dr. John Watson. The novel begins with Watson, a war veteran, returning to London in search of a place to live. He soon meets Holmes, an eccentric and brilliant detective, and the two decide to share a flat at 221B Baker Street. Their peaceful cohabitation is disrupted when Holmes is called upon to investigate a baffling murder case. The body of Enoch Drebber is found in an abandoned house, with no apparent cause of death and the word "RACHE" scrawled in blood on the wall.\n\nAs Holmes and Watson delve deeper into the mystery, they uncover a tale of love, revenge, and a secret society. The narrative shifts to the American West, revealing the backstory of the victim and the motives behind the crime. Holmes\'s unparalleled deductive skills and keen observation lead to the unmasking of the murderer, showcasing his methodical approach to solving even the most perplexing cases. "A Study in Scarlet" not only marks the beginning of one of literature\'s most iconic partnerships but also sets the stage for the many adventures that follow in the Sherlock Holmes series.',
    firstLine:
      "In the year 1878 I took my degree of Doctor of Medicine of the University of London, and proceeded to Netley to go through the course prescribed for surgeons in the army.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 245,
    name: "Life on the Mississippi",
    author: "Mark Twain",
    category: "Non-Fiction",
    blurb:
      "\"Life on the Mississippi\" by Mark Twain is a captivating blend of autobiography, travel narrative, and historical commentary. The book chronicles Twain's experiences as a young steamboat pilot on the Mississippi River before the American Civil War, offering a vivid portrayal of the river's bustling activity and the diverse characters who navigated its waters. Through Twain's keen observations and sharp wit, readers are transported to a time when the Mississippi was a vital artery of commerce and culture in the 19th-century United States.\n\nIn addition to recounting his own adventures, Twain delves into the broader social and economic changes that transformed the Mississippi River Valley. He reflects on the impact of technological advancements, such as the advent of the railroad, and the decline of the steamboat era. With a mix of humor, nostalgia, and critical insight, \"Life on the Mississippi\" provides a rich tapestry of life along one of America's most iconic rivers, capturing the spirit and challenges of an era long past.",
    firstLine: "The Mississippi is well worth reading about.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 250,
    name: "A Brief History of the Internet",
    author: "Michael Hart & Maxwell Fuller",
    category: "Non-Fiction",
    blurb:
      '"A Brief History of the Internet" by Michael Hart & Maxwell Fuller offers a comprehensive exploration of the internet\'s evolution, from its humble beginnings to its current status as an indispensable global network. Fuller meticulously traces the development of the internet, highlighting key milestones such as the creation of ARPANET, the rise of the World Wide Web, and the proliferation of social media. The book delves into the technological advancements and visionary individuals who played pivotal roles in shaping the digital landscape, providing readers with a clear understanding of how the internet has transformed communication, commerce, and culture.\n\nIn addition to celebrating the internet\'s achievements, Fuller does not shy away from examining its darker aspects. The book addresses critical issues such as cybersecurity threats, privacy concerns, and the spread of misinformation. By presenting a balanced view, Fuller encourages readers to appreciate the internet\'s potential while remaining vigilant about its challenges. "A Brief History of the Internet" is an essential read for anyone interested in understanding the profound impact of this revolutionary technology on modern society.',
    firstLine: "The Internet Conquers Space, Time, and Mass Production...",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 260,
    name: "An Introduction to the Study of Robert Browning's Poetry",
    author: "Robert Browning",
    category: "Art",
    blurb:
      "\"An Introduction to the Study of Robert Browning's Poetry\" offers a comprehensive exploration of the works of one of the 19th century's most enigmatic poets. This insightful guide delves into the intricate layers of Browning's verse, providing readers with the tools to appreciate his complex use of language, innovative narrative techniques, and profound thematic concerns. The book serves as both a critical analysis and a bibliographic resource, making it an invaluable companion for students, scholars, and poetry enthusiasts alike.\n\nThe author meticulously examines Browning's major works, shedding light on his unique approach to character development and his philosophical inquiries into human nature and morality. Through detailed criticism and interpretation, readers gain a deeper understanding of Browning's artistic vision and the historical context that shaped his poetry. This study not only celebrates Browning's literary achievements but also invites readers to engage with his poetry on a more meaningful level, fostering a lasting appreciation for his contribution to English literature.",
    firstLine:
      "Literature, in its most restricted art-sense, is an expression in letters of the life of the spirit of man co-operating with the intellect.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 265,
    name: "The Life and Death of Cormac the Skald",
    author: "W Collingwood",
    category: "Romance",
    blurb:
      "\"The Life and Death of Cormac the Skald\" by W. Collingwood is a captivating translation of the Icelandic saga that chronicles the life of Kormákr Ögmundarson, a renowned 10th-century poet and warrior. This saga, rich with the cultural and historical essence of medieval Iceland, delves into the tumultuous life of Cormac, whose poetic genius and romantic entanglements are as legendary as his martial exploits. Through vivid storytelling, readers are transported to a world where honor, love, and fate intertwine, revealing the complexities of a man who is both celebrated and cursed by his extraordinary talents.\n\nCollingwood's translation brings to life the saga's intricate narrative, capturing the lyrical beauty and raw emotion of Cormac's verses. The book not only explores Cormac's passionate love for Steingerd, a woman whose affections are as elusive as they are consuming, but also his relentless pursuit of glory and recognition. As Cormac navigates the treacherous waters of love, rivalry, and destiny, readers are offered a profound glimpse into the values and struggles of Viking-age society. \"The Life and Death of Cormac the Skald\" stands as a testament to the enduring power of the sagas, preserving the legacy of one of Iceland's most enigmatic and compelling figures.",
    firstLine: "Harald Fairhair was king of Norway when this tale begins.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 267,
    name: "The Touchstone",
    author: "Edith Wharton",
    category: "Novel",
    blurb:
      "In Edith Wharton's \"The Touchstone,\" readers are drawn into a compelling psychological drama that explores the intricate dynamics of guilt, ambition, and moral conflict. The story centers on Stephen Glennard, a man who finds himself in financial straits and sees an opportunity to resolve his troubles by selling the love letters written to him by a former lover, the now-deceased and celebrated author, Margaret Aubyn. As the letters gain public acclaim and Glennard's fortunes improve, he is haunted by the ethical implications of his actions and the betrayal of a woman who once loved him deeply.\n\nWharton masterfully delves into the complexities of human relationships, particularly the strains within Glennard's marriage to his wife, Alexa, who remains unaware of the source of their newfound wealth. The narrative examines the corrosive effects of Glennard's secret on his conscience and his relationships, creating a rich tapestry of emotional and psychological tension. \"The Touchstone\" is a poignant exploration of the consequences of one's choices and the enduring struggle between personal gain and moral integrity.",
    firstLine:
      "Glennard dropped 'The Spectator' and sat looking into the fire.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 268,
    name: "The Octopus: A Story of California",
    author: "Frank Norris",
    category: "Novel",
    blurb:
      '"The Octopus: A Story of California" by Frank Norris is a gripping tale set in the late 19th century, exploring the intense and often brutal conflict between California wheat farmers and the powerful railroad monopolies. Inspired by the real-life Mussel Slough Tragedy of 1880, the novel delves into the lives of the farmers in the San Joaquin Valley, depicting their struggles to maintain their livelihoods against the encroaching and manipulative forces of the railroad companies. Through vivid storytelling and richly developed characters, Norris paints a stark picture of the socio-economic tensions and the harsh realities of agrarian life.\n\nAt its core, "The Octopus" is a didactic fiction that critiques the unchecked power of corporate monopolies and their devastating impact on individuals and communities. The novel\'s title metaphorically represents the railroad\'s far-reaching and suffocating grip on the farmers, symbolizing the broader theme of exploitation and resistance. As the farmers band together to fight for their rights, the story unfolds with dramatic intensity, highlighting themes of justice, greed, and the enduring human spirit. Norris\'s work remains a powerful commentary on the American experience, resonating with readers as a timeless exploration of power, corruption, and resilience.',
    firstLine:
      "Just after passing Caraher's saloon, on the County Road that ran south from Bonneville, and that divided the Broderson ranch from that of Los Muertos, Presley was suddenly aware of the faint and prolonged blowing of a steam whistle that he knew must come from the railroad shops near the depot at Bonneville.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 269,
    name: "Beasts and Super-Beasts",
    author: " Saki",
    category: "Fantasy",
    blurb:
      '"Beasts and Super-Beasts" is a delightful collection of short stories by the master of wit, Saki (the pen name of H.H. Munro). This anthology showcases Saki\'s signature blend of dark humor, sharp social commentary, and a touch of the supernatural. Each story is a finely crafted gem, featuring eccentric characters, unexpected twists, and a satirical take on Edwardian society. From mischievous children and cunning animals to hapless adults caught in absurd situations, Saki\'s tales are as entertaining as they are thought-provoking.\n\nThe stories in "Beasts and Super-Beasts" often explore the thin veneer of civilization, revealing the primal instincts and absurdities that lie just beneath the surface. With his keen eye for human folly and a penchant for the macabre, Saki creates a world where the boundaries between the natural and the supernatural blur, and where the mundane can quickly turn into the extraordinary. This collection is a testament to Saki\'s enduring legacy as one of the greatest short story writers in the English language, offering readers a perfect blend of humor, irony, and the uncanny.',
    firstLine:
      "Leonard Bilsiter was one of those people who have failed to find this world attractive or interesting, and who have sought compensation in an “unseen world” of their own experience or imagination—or invention.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 270,
    name: "Dream Days",
    author: "Kenneth Grahame",
    category: "Children's",
    blurb:
      '"Dream Days" by Kenneth Grahame is a charming collection of short stories that transport readers to the idyllic English countryside, capturing the essence of childhood wonder and imagination. Through the eyes of a group of siblings, Grahame weaves tales of adventure, mischief, and discovery, painting a nostalgic picture of rural life. Each story is imbued with a sense of innocence and whimsy, as the children navigate their world with boundless curiosity and creativity.\n\nSet against the backdrop of the late 19th century, "Dream Days" explores themes of family, friendship, and the simple joys of country living. Grahame\'s lyrical prose and vivid descriptions bring to life the lush landscapes and quaint villages of England, making readers yearn for a simpler time. Whether it\'s a fantastical journey into a hidden realm or a heartfelt moment shared between siblings, "Dream Days" captures the magic of youth and the enduring power of dreams.',
    firstLine:
      "In the matter of general culture and attainments, we youngsters stood on pretty level ground.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 271,
    name: "Black Beauty",
    author: "Anna Sewell",
    category: "Children's",
    blurb:
      '"Black Beauty" by Anna Sewell is a timeless classic that delves into the life and experiences of a horse named Black Beauty. Narrated from the perspective of the horse himself, the novel takes readers on an emotional journey through the various stages of his life, from his idyllic early days in the English countryside to the harsh realities of urban life. Through Black Beauty\'s eyes, readers gain insight into the treatment of horses in 19th-century Britain, highlighting both the kindness and cruelty they faced.\n\nThe story is not just about the life of a horse but also serves as a powerful commentary on human-animal relationships and the moral responsibilities of humans towards animals. Sewell\'s vivid descriptions and empathetic storytelling bring to light the importance of compassion, respect, and humane treatment of all living beings. "Black Beauty" remains a poignant and influential work, advocating for animal welfare and continuing to resonate with readers of all ages.',
    firstLine:
      "The first place that I can well remember was a large pleasant meadow with a pond of clear water in it.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 272,
    name: "An Open Letter on Translating",
    author: "Martin Luther",
    category: "Non-Fiction",
    blurb:
      '"An Open Letter on Translating" by Martin Luther is a seminal work that delves into the intricacies and challenges of translating the Bible into the vernacular. Written during the Reformation in Germany, Luther\'s letter addresses the theological, linguistic, and cultural considerations that come with rendering sacred texts accessible to the common people. He passionately argues for the necessity of translating the Bible into German, emphasizing that the Word of God should be available to all, not just the clergy or the educated elite.\n\nLuther also provides a detailed account of his translation methodology, discussing the importance of clarity, accuracy, and faithfulness to the original texts. He defends his choices against critics who accused him of altering the Scriptures, explaining his rationale for certain word choices and phrasing. Through this letter, Luther not only justifies his translation efforts but also lays the groundwork for future translators, highlighting the transformative power of making religious texts comprehensible to the masses.',
    firstLine:
      'The wise Solomon says in Proverbs 11: "The people who withhold grain curse him. But there is a blessing on those who sell it."',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 274,
    name: "Disputation of Doctor Martin Luther on the Power and Efficacy of Indulgences",
    author: "Martin Luther",
    category: "History",
    blurb:
      "\"Disputation of Doctor Martin Luther on the Power and Efficacy of Indulgences,\" commonly known as the Ninety-Five Theses, is a seminal work by Martin Luther that ignited the Protestant Reformation. Written in 1517, this document challenges the Catholic Church's practice of selling indulgences, which were believed to reduce punishment for sins. Luther argues that true repentance and faith, rather than financial transactions, are the path to salvation. His theses question the authority of the Pope and criticize the exploitation of believers' fears for monetary gain.\n\nLuther's bold assertions not only sparked theological debate but also led to significant religious and political upheaval across Europe. The Ninety-Five Theses emphasize the importance of scripture and faith over church-imposed rituals and highlight the need for reform within the church. This work remains a cornerstone in the history of Christianity, marking the beginning of a movement that sought to return to the foundational teachings of the Bible and reshape the religious landscape of the Western world.",
    firstLine:
      "Out of love for the truth and the desire to bring it to light, the following propositions will be discussed at Wittenberg, under the presidency of the Reverend Father Martin Luther, Master of Arts and of Sacred Theology, and Lecturer in Ordinary on the same at that place.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 275,
    name: "The Augsburg Confession",
    author: "Philipp Melanchthon",
    category: "Non-Fiction",
    blurb:
      '"The Augsburg Confession," authored by Philipp Melanchthon, is a seminal document in the history of the Lutheran Church and a cornerstone of Protestant Reformation theology. Presented to Emperor Charles V at the Diet of Augsburg in 1530, this confession of faith articulates the core beliefs and doctrinal positions of the Lutheran tradition. It was crafted to clarify the theological differences between the emerging Protestant movement and the Roman Catholic Church, aiming to foster unity and understanding within the Holy Roman Empire.\n\nThe text is structured into two main parts: the first detailing the fundamental articles of faith, including doctrines on God, original sin, justification, and the sacraments, while the second addresses various abuses and practices within the Church that the reformers sought to correct. Through its clear and concise exposition of Lutheran doctrine, "The Augsburg Confession" not only served as a pivotal declaration during a time of religious upheaval but also continues to be a vital reference for Lutheran theology and ecclesiastical practice.',
    firstLine:
      "Our Churches, with common consent, do teach that the decree of the Council of Nicaea concerning the Unity of the Divine Essence and concerning the Three Persons, is true and to be believed without any doubting.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 277,
    name: "Trinity Site",
    author: "National Atomic Museum (U.S.)",
    category: "History",
    blurb:
      '"Trinity Site" by the National Atomic Museum delves into the pivotal moment in history when humanity first unleashed the power of the atomic bomb. This meticulously researched book provides a comprehensive account of the Manhattan Project, the top-secret endeavor that culminated in the first nuclear explosion on July 16, 1945, in the New Mexico desert. Through detailed narratives and rare photographs, readers are transported to the heart of the project, exploring the scientific breakthroughs, the intense secrecy, and the profound implications of this groundbreaking event.\n\nThe book not only chronicles the technical and logistical challenges faced by the scientists and engineers but also reflects on the ethical and moral questions that arose from the creation of such a devastating weapon. "Trinity Site" serves as both a historical document and a contemplative piece, offering insights into the dawn of the atomic age and its lasting impact on global politics, warfare, and society. This compelling account is essential reading for anyone interested in the history of science, military history, and the complex legacy of nuclear weapons.',
    firstLine:
      "On Monday morning July 16, 1945, the world was changed forever when the first atomic bomb was tested in an isolated area of the New Mexico desert.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 280,
    name: "Records of a Family of Engineers",
    author: "Robert Louis Stevenson",
    category: "Biography",
    blurb:
      '"Records of a Family of Engineers" by Robert Louis Stevenson is a compelling historical account that delves into the remarkable legacy of the Stevenson family, renowned for their monumental contributions to engineering in Great Britain. The narrative primarily focuses on the life and achievements of Robert Stevenson (1772-1850), the author\'s grandfather, whose pioneering work in lighthouse construction, particularly the iconic Bell Rock Lighthouse in Scotland, stands as a testament to human ingenuity and perseverance.\n\nThrough meticulous research and personal anecdotes, Stevenson paints a vivid portrait of the challenges and triumphs faced by his forebears. The book not only highlights the technical and logistical feats accomplished by these engineers but also provides an intimate glimpse into the familial bonds and personal sacrifices that underpinned their professional endeavors. "Records of a Family of Engineers" is both an inspiring tribute to the Stevenson family\'s indomitable spirit and a fascinating exploration of the evolution of engineering during a transformative period in British history.',
    firstLine:
      "From the thirteenth century onwards, the name, under the various disguises of Stevinstoun, Stevensoun, Stevensonne, Stenesone, and Stewinsoune, spread across Scotland from the mouth of the Firth of Forth to the mouth of the Firth of Clyde.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 281,
    name: "Father Damien: An Open Letter to the Reverend Dr. Hyde of Honolulu",
    author: "Robert Louis Stevenson",
    category: "Biography",
    blurb:
      "\"Father Damien: An Open Letter to the Reverend Dr. Hyde of Honolulu\" by Robert Louis Stevenson is a passionate and eloquent defense of Father Damien, a Catholic priest who dedicated his life to serving the leper colony on the Hawaiian island of Molokai. Written in response to criticisms made by Reverend Dr. Hyde, Stevenson's letter is a powerful testament to Damien's selflessness, courage, and unwavering commitment to the marginalized and suffering. Through vivid and compelling prose, Stevenson dismantles the accusations against Damien, highlighting his profound humanity and the profound impact of his work.\n\nStevenson's letter not only serves as a vindication of Father Damien's character but also as a broader commentary on the nature of true charity and the moral responsibilities of society. It underscores the importance of compassion, empathy, and the recognition of the inherent dignity of every individual, regardless of their circumstances. This work remains a poignant and inspiring tribute to a man who lived and died in service to others, and a reminder of the enduring power of integrity and love in the face of adversity.",
    firstLine:
      "Sir,--It may probably occur to you that we have met, and visited, and conversed; on my side, with interest.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 282,
    name: "Eothen; Or, Traces of Travel Brought Home from the East",
    author: "Alexander William Kinglake",
    category: "Non-Fiction",
    blurb:
      "\"Eothen; Or, Traces of Travel Brought Home from the East\" by Alexander William Kinglake is a captivating travelogue that chronicles the author's journey through the Middle East in the early 19th century. Kinglake's narrative is both vivid and personal, offering readers an intimate glimpse into the landscapes, cultures, and people he encountered during his travels. From the bustling bazaars of Cairo to the serene deserts of Sinai, Kinglake's keen observations and eloquent prose bring the exotic locales to life, making the reader feel as though they are walking alongside him.\n\nThe book is not just a mere recounting of places visited; it delves into the historical and social contexts of the regions, providing insightful commentary on the political and cultural dynamics of the time. Kinglake's reflections on the East-West dichotomy and his encounters with various local customs and traditions add depth to his travel narrative, making \"Eothen\" a rich tapestry of adventure, history, and personal discovery. This classic work remains a significant contribution to travel literature, offering timeless insights into the enduring allure of the Middle East.",
    firstLine:
      "At Semlin I still was encompassed by the scenes and the sounds of familiar life; the din of a busy world still vexed and cheered me; the unveiled faces of women still shone in the light of day.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 283,
    name: "The Reef",
    author: "Edith Wharton",
    category: "Novel",
    blurb:
      'In "The Reef," Edith Wharton masterfully explores the intricate dynamics of love, betrayal, and societal expectations. Set against the backdrop of early 20th-century France, the novel delves into the lives of George Darrow and Anna Leath, two Americans whose rekindled romance is tested by unforeseen complications. As Darrow arrives in France with hopes of marrying Anna, he encounters a young woman named Sophy Viner, whose presence and past entanglements with Darrow create a web of misunderstandings and emotional turmoil.\n\nWharton\'s keen insight into human nature and her elegant prose illuminate the characters\' internal struggles and the societal pressures that shape their decisions. "The Reef" is a poignant exploration of the complexities of human relationships, the consequences of past actions, and the often-painful pursuit of happiness. Through its richly drawn characters and evocative settings, the novel offers a timeless reflection on love and the moral dilemmas that accompany it.',
    firstLine:
      "All the way from Charing Cross to Dover the train had hammered the words of the telegram into George Darrow's ears, ringing every change of irony on its commonplace syllables.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 284,
    name: "The House of Mirth",
    author: "Edith Wharton",
    category: "Novel",
    blurb:
      'In "The House of Mirth," Edith Wharton masterfully explores the intricate social dynamics and rigid class structures of early 20th-century New York society. The novel follows the life of Lily Bart, a beautiful and charming young woman who is caught in the web of societal expectations and her own desires for wealth and status. As Lily navigates the opulent world of high society, she faces a series of moral and financial dilemmas that ultimately lead to her downfall. Wharton\'s keen psychological insight and sharp social commentary paint a vivid portrait of a woman struggling to maintain her dignity and independence in a world that values appearances above all else.\n\nThrough Lily\'s tragic journey, Wharton critiques the superficiality and hypocrisy of the upper class, highlighting the limited options available to women who seek both autonomy and social acceptance. "The House of Mirth" is not only a poignant character study but also a powerful indictment of a society that sacrifices individual happiness for the sake of maintaining rigid social hierarchies. With its richly drawn characters and incisive prose, the novel remains a timeless exploration of ambition, desire, and the human cost of societal expectations.',
    firstLine: "Selden paused in surprise.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 285,
    name: "The Lost Continent",
    author: "C. J. Cutliffe Hyne",
    category: "Adventure",
    blurb:
      '"The Lost Continent" by C. J. Cutliffe Hyne is a captivating adventure novel that transports readers to the legendary realm of Atlantis. The story is narrated by Deucalion, a high-ranking priest and statesman in Atlantis, who recounts the decline and eventual destruction of this once-glorious civilization. Through Deucalion\'s eyes, we witness the political intrigue, religious fervor, and societal decadence that plague Atlantis, leading to its downfall. The novel combines elements of fantasy, historical fiction, and romance, creating a richly detailed and immersive narrative that explores themes of power, corruption, and the human spirit. \n\nSet in a time of myth and mystery, "The Lost Continent" is both a cautionary tale and an imaginative exploration of an ancient world. Hyne\'s vivid descriptions and intricate plot weave together to create a sense of grandeur and impending doom, drawing readers into the fate of Atlantis and its people. As Deucalion struggles to save his homeland from its self-inflicted wounds, the novel reflects on the timeless nature of human ambition and folly, making it a thought-provoking and enduring classic in the genre of lost world fiction.',
    firstLine: "The public official reception was over.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 286,
    name: "Laddie: A True Blue Story",
    author: "Gene Stratton-Porter",
    category: "Novel",
    blurb:
      '"Laddie: A True Blue Story" by Gene Stratton-Porter is a heartwarming and nostalgic tale set in the rural landscapes of Indiana. The story is narrated by Little Sister, the youngest member of the Stanton family, who idolizes her older brother, Laddie. Through her eyes, readers are introduced to the idyllic life on the family farm, where values of hard work, love, and integrity are cherished. Laddie, the epitome of the true-blue American hero, is not only a skilled farmer but also a man of deep principles and unwavering loyalty.\n\nAs the narrative unfolds, the Stanton family faces various challenges, including societal expectations and personal trials. Central to the plot is Laddie\'s romance with Pamela Pryor, a mysterious and cultured young woman from England, whose presence brings both joy and complications to the Stanton household. Through vivid descriptions and rich character development, Stratton-Porter weaves a story that celebrates the beauty of nature, the strength of family bonds, and the triumph of love and honor over adversity. "Laddie: A True Blue Story" is a timeless classic that resonates with readers of all ages, offering a poignant glimpse into a bygone era of American life.',
    firstLine:
      '"Have I got a Little Sister anywhere in this house?" inquired Laddie at the door, in his most coaxing voice.',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 287,
    name: "Remember the Alamo",
    author: "Amelia E Barr",
    category: "History",
    blurb:
      '"Remember the Alamo" by Amelia E. Barr is a gripping historical novel that transports readers to the tumultuous period of the Texas Revolution. Set against the backdrop of the 1836 Siege of the Alamo, the story weaves together the lives of a diverse cast of characters, each grappling with their own personal struggles and aspirations amidst the chaos of war. Through vivid descriptions and meticulous historical detail, Barr brings to life the heroism, sacrifice, and indomitable spirit of those who fought for Texas independence.\n\nThe novel delves deep into the human side of the conflict, exploring themes of love, loyalty, and courage. As the characters\' paths intersect at the iconic Alamo, their individual stories converge into a powerful narrative of resilience and hope. "Remember the Alamo" is not just a tale of battle; it is a poignant exploration of the human condition in the face of adversity, making it a compelling read for anyone interested in history, war stories, and the enduring legacy of the American frontier.',
    firstLine:
      "In A. D. sixteen hundred and ninety-two, a few Franciscan monks began to build a city.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 289,
    name: "The Wind in the Willows",
    author: "Kenneth Grahame",
    category: "Children's",
    blurb:
      "\"The Wind in the Willows\" by Kenneth Grahame is a timeless classic that transports readers to the idyllic English countryside, where the lives of anthropomorphic animals unfold in a series of enchanting adventures. At the heart of the story are Mole, Ratty, Badger, and the irrepressible Toad, whose deep friendship and diverse personalities drive the narrative. Mole's curiosity leads him to the riverbank, where he meets the water-loving Ratty, and together they embark on a journey filled with camaraderie and discovery.\n\nThe tale weaves through humorous escapades and poignant moments, from Toad's reckless obsession with motorcars to the group's united effort to reclaim Toad Hall from weasels and stoats. Grahame's rich, pastoral prose paints a vivid picture of the natural world, celebrating the simple joys of country life and the enduring bonds of friendship. \"The Wind in the Willows\" is a heartwarming exploration of loyalty, adventure, and the beauty of the English landscape, making it a beloved read for both children and adults.",
    firstLine:
      "The Mole had been working very hard all the morning, spring-cleaning his little home.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 290,
    name: "The Stark Munro Letters",
    author: "Arthur Conan Doyle",
    category: "Novel",
    blurb:
      '"The Stark Munro Letters" by Arthur Conan Doyle is an engaging epistolary novel that chronicles the professional and personal journey of J. Stark Munro, a young doctor navigating the challenges of his early career. Through a series of twelve letters addressed to his friend Herbert Swanborough, Munro provides a candid and often humorous account of his experiences from 1881 to 1884. These letters offer a vivid portrayal of the medical profession during the late 19th century, capturing the trials and triumphs of a fledgling doctor striving to establish himself in a competitive field.\n\nAs Munro shares his thoughts and observations, readers gain insight into his evolving philosophies on life, medicine, and human nature. The letters reveal his encounters with a variety of patients, colleagues, and mentors, each contributing to his growth and resilience. Doyle\'s own background as a physician lends authenticity to Munro\'s narrative, making "The Stark Munro Letters" not only a compelling story of personal development but also a fascinating glimpse into the historical context of medical practice.',
    firstLine:
      "I have missed you very much since your return to America, my dear Bertie, for you are the one man upon this earth to whom I have ever been able to unreservedly open my whole mind.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 291,
    name: "The Golden Age",
    author: "Kenneth Grahame",
    category: "Novel",
    blurb:
      '"The Golden Age" by Kenneth Grahame is a charming collection of nostalgic vignettes that transport readers to the idyllic English countryside of the late 19th century. Through the eyes of five siblings, Grahame captures the essence of childhood innocence and the boundless imagination that transforms everyday life into a series of enchanting adventures. The children, left largely to their own devices, explore the pastoral landscape, creating a world where the ordinary becomes extraordinary, and the boundaries between reality and fantasy blur.\n\nSet against the backdrop of a serene rural England, the stories delve into themes of freedom, curiosity, and the bittersweet passage from childhood to adulthood. Grahame\'s lyrical prose and keen observations of nature and human behavior evoke a sense of timelessness, making "The Golden Age" a poignant reflection on the joys and sorrows of growing up. This collection is a testament to the enduring power of imagination and the simple pleasures of country life, offering readers a gentle escape into a world where the golden days of youth are forever preserved.',
    firstLine:
      "Looking back to those days of old, ere the gate shut behind me, I can see now that to children with a proper equipment of parents these things would have worn a different aspect.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 293,
    name: "Paul Prescott's Charge",
    author: "Horatio Alger",
    category: "Novel",
    blurb:
      "\"Paul Prescott's Charge\" by Horatio Alger is a compelling tale set in the 19th century, chronicling the trials and triumphs of a young orphan named Paul Prescott. After the death of his father, Paul is left to navigate a harsh world on his own. His journey takes him from the rural countryside to the bustling streets of New York City, where he encounters a series of challenges that test his character and resolve. Through hard work, determination, and a steadfast adherence to his moral principles, Paul strives to overcome adversity and carve out a better future for himself.\n\nThe novel delves into themes of resilience, integrity, and the importance of making ethical choices, even in the face of hardship. Alger's narrative not only provides a vivid portrayal of 19th-century New York but also serves as an inspirational story about the power of perseverance and the impact of positive conduct on one's destiny. \"Paul Prescott's Charge\" is a timeless reminder of the virtues of hard work and moral fortitude, making it a classic in the genre of rags-to-riches stories.",
    firstLine:
      '"HANNAH!" The speaker was a tall, pompous-looking man, whose age appeared to verge close upon fifty.',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 296,
    name: "The Cash Boy",
    author: "Horatio Alger",
    category: "Children's",
    blurb:
      '"The Cash Boy" by Horatio Alger is a classic tale of resilience and determination, following the life of Frank Fowler, a young orphan who faces numerous challenges with unwavering courage. After the death of his parents, Frank is left to fend for himself in a world that seems indifferent to his plight. Despite his hardships, he remains optimistic and industrious, securing a job as a cash boy in a large department store. His honesty and hard work soon catch the attention of his employers, setting him on a path to success.\n\nAs Frank navigates the trials of adolescence, he encounters both friends and foes, each shaping his journey in different ways. The story is rich with themes of integrity, perseverance, and the quintessential American dream, making it a timeless piece of didactic fiction. Alongside his adventures, Frank\'s love for baseball provides a backdrop of camaraderie and youthful exuberance, adding a layer of relatability and charm to his character. "The Cash Boy" is not just a story of a boy\'s struggle and triumph, but a testament to the enduring spirit of hope and the belief that hard work and good character can lead to a better life.',
    firstLine:
      "A group of boys was assembled in an open field to the west of the public schoolhouse in the town of Crawford.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 297,
    name: "The Flirt",
    author: "Booth Tarkington",
    category: "Romance",
    blurb:
      "\"The Flirt\" by Booth Tarkington is a captivating exploration of early 20th-century social dynamics, centered around the lives of the Madison family. At the heart of the story is Cora Madison, a young woman whose beauty and charm make her the object of many suitors' affections. Cora's flirtatious nature and manipulative tendencies create a whirlwind of romantic entanglements and social drama, as she navigates the complexities of courtship and societal expectations.\n\nAs the plot unfolds, the Madison family becomes entangled in a web of deceit and forgery, orchestrated by a cunning swindler. The novel delves into themes of love, ambition, and the consequences of deceit, painting a vivid picture of the era's moral and social landscape. Tarkington's keen observations and rich characterizations make \"The Flirt\" a timeless tale of human folly and the pursuit of happiness.",
    firstLine:
      "Valentine Corliss walked up Corliss Street the hottest afternoon of that hot August, a year ago, wearing a suit of white serge which attracted a little attention from those observers who were able to observe anything except the heat.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 298,
    name: "The Market-Place",
    author: "Harold Frederic",
    category: "Novel",
    blurb:
      '"The Market-Place" by Harold Frederic is a compelling exploration of the intricate and often ruthless world of finance and social ambition in late 19th-century England. The novel delves into the lives of various characters who navigate the treacherous waters of the stock exchange, high society, and personal ambition. At its core, the story revolves around the rise and fall of a charismatic financier whose dealings and manipulations impact not only his own fate but also the lives of those around him.\n\nFrederic masterfully portrays the complexities of human nature, ambition, and morality against the backdrop of a rapidly changing economic landscape. The narrative weaves through themes of swindling, nobility, and the stark contrasts between different social classes, offering a vivid portrayal of the era\'s capitalist fervor. With its richly drawn characters and intricate plot, "The Market-Place" provides a thought-provoking commentary on the pursuit of wealth and the ethical dilemmas that accompany it.',
    firstLine:
      "The battle was over, and the victor remained on the field--sitting alone with the hurly-burly of his thoughts.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 299,
    name: "Tales from Two Hemispheres",
    author: "Hjalmar Hjorth Boyesen",
    category: "Novel",
    blurb:
      '"Tales from Two Hemispheres" by Hjalmar Hjorth Boyesen is a captivating collection of short stories that intricately weave together the experiences of Norwegian immigrants in America with the rich cultural tapestry of their homeland. Through a series of poignant and evocative narratives, Boyesen explores themes of identity, belonging, and the often bittersweet journey of adapting to a new world while holding onto the traditions of the old. Each story is a window into the lives of characters who navigate the complexities of dual heritage, offering readers a profound understanding of the immigrant experience.\n\nBoyesen\'s masterful storytelling is marked by vivid descriptions and deep emotional resonance, capturing the essence of both the Norwegian landscape and the burgeoning American society of the time. His characters are richly drawn, their struggles and triumphs reflecting the universal quest for a sense of home and purpose. "Tales from Two Hemispheres" is not just a collection of stories, but a heartfelt tribute to the resilience and spirit of those who bridge two worlds, making it a timeless piece of literature that continues to resonate with readers today. The first story is "The Man Who Lost His Name".',
    firstLine:
      "On the second day of June, 186--, a young Norseman, Halfdan Bjerk by name, landed on the pier at Castle Garden.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 300,
    name: "United States Declaration of Independence",
    author: "Thomas Jefferson",
    category: "History",
    blurb:
      "The \"United States Declaration of Independence,\" authored by Thomas Jefferson, is a seminal document in American history that marked the formal separation of the thirteen American colonies from British rule. Drafted in 1776, this powerful proclamation articulates the colonies' grievances against King George III and outlines the philosophical underpinnings of their quest for freedom. Jefferson eloquently asserts the inherent rights of individuals to life, liberty, and the pursuit of happiness, grounding these principles in the belief that governments derive their just powers from the consent of the governed.\n\nThe Declaration not only served as a bold statement of autonomy but also as a rallying cry for the fledgling nation during the Revolutionary War. It encapsulates the spirit of the American Revolution, emphasizing the colonies' determination to establish a government that respects and protects the rights of its citizens. As a foundational text, the Declaration of Independence continues to inspire movements for freedom and justice around the world, embodying the enduring ideals of democracy and human rights.",
    firstLine:
      "When in the Course of human events, it becomes necessary for one people to dissolve the political bands which have connected them with another, and to assume, among the Powers of the earth, the separate and equal station to which the Laws of Nature and of Nature's God entitle them, a decent respect to the opinions of mankind requires that they should declare the causes which impel them to the separation.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 303,
    name: "HomeBrew HomePages Put YOU on the World Wide Web",
    author: "Anonymous",
    category: "Non-Fiction",
    blurb:
      '"HomeBrew HomePages Put YOU on the World Wide Web" is a comprehensive guide designed to empower individuals to create their own personal or professional presence on the internet. Authored by an anonymous expert, this book demystifies the process of building and maintaining a website, making it accessible even to those with minimal technical knowledge. Through step-by-step instructions, readers will learn the essentials of web design, HTML coding, and the use of various tools and resources to craft a unique and engaging online space.\n\nThe book delves into practical aspects such as choosing a domain name, selecting a web hosting service, and optimizing content for search engines. It also covers advanced topics like incorporating multimedia elements, ensuring website security, and leveraging social media to drive traffic. Whether you\'re a hobbyist looking to share your passions or a small business owner aiming to reach a broader audience, "HomeBrew HomePages Put YOU on the World Wide Web" provides the knowledge and confidence needed to establish a successful online presence.',
    firstLine:
      "The World Wide Web is a new medium for publishing information, and it is growing at an incredible rate.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 305,
    name: "The Count's Millions",
    author: "Emile Gaboriau",
    category: "Mystery",
    blurb:
      "In \"The Count's Millions,\" Emile Gaboriau weaves a gripping tale of mystery and intrigue set against the backdrop of 19th-century France. The story follows the enigmatic Count de Chalusse, whose sudden and mysterious death leaves behind a fortune and a host of unanswered questions. As the count's wealth becomes the focal point of a fierce battle, the narrative delves into the lives of those who stand to gain or lose everything from the inheritance.\n\nCentral to the plot is the determined and astute detective, Monsieur Lecoq, who is tasked with unraveling the complex web of secrets surrounding the count's demise. As Lecoq delves deeper, he uncovers a labyrinth of deceit, hidden identities, and long-buried scandals. Gaboriau masterfully combines elements of suspense and drama, creating a richly textured narrative that keeps readers on the edge of their seats. \"The Count's Millions\" is a testament to Gaboriau's prowess as a pioneer of the detective genre, offering a compelling exploration of human greed, ambition, and the quest for justice.",
    firstLine:
      "It was a Thursday evening, the fifteenth of October; and although only half-past six o'clock, it had been dark for some time already.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 307,
    name: "Three Elephant Power, and Other Stories",
    author: "A Paterson",
    category: "Adventure",
    blurb:
      '"Three Elephant Power, and Other Stories" by A. Paterson is a captivating collection that delves into the rugged and adventurous spirit of frontier and pioneer life in Australia. Through a series of short stories, Paterson masterfully captures the essence of the Australian outback, painting vivid portraits of its landscapes, characters, and the unique challenges they face. Each tale is imbued with a sense of authenticity and humor, reflecting the social customs and resilient spirit of the people who call this vast land home.\n\nThe stories range from thrilling accounts of bushmen and their daring exploits to heartwarming narratives of camaraderie and the simple joys of rural life. Paterson\'s keen observations and rich storytelling bring to life the trials and triumphs of Australia\'s early settlers, offering readers a window into a bygone era. Whether it\'s the ingenuity required to harness "three elephant power" or the everyday adventures that define life in the outback, this collection is a testament to the enduring human spirit and the unique culture of Australia.',
    firstLine:
      '"Them things," said Alfred the chauffeur, tapping the speed indicator with his fingers, "them things are all right for the police."',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 308,
    name: "Three Men in a Boat (To Say Nothing of the Dog)",
    author: "Jerome K Jerome",
    category: "Novel",
    blurb:
      '"Three Men in a Boat (To Say Nothing of the Dog)" by Jerome K. Jerome is a timeless comedic novel that chronicles the misadventures of three friends—J., George, and Harris—along with Montmorency, their spirited fox terrier. Seeking a respite from their supposed ailments and the stresses of London life, the trio embarks on a boating holiday up the River Thames. What begins as a quest for relaxation quickly devolves into a series of humorous escapades, marked by their ineptitude and the unpredictable nature of river travel.\n\nAs they navigate the scenic waterway, the friends encounter a variety of challenges, from navigating locks and dealing with inclement weather to cooking disasters and encounters with eccentric locals. Jerome\'s witty prose and keen observations on human nature transform mundane events into laugh-out-loud moments, making the novel a delightful exploration of friendship, folly, and the simple joys of life. The book remains a beloved classic, celebrated for its enduring humor and charming portrayal of Victorian England.',
    firstLine:
      "There were four of us—George, and William Samuel Harris, and myself, and Montmorency.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 310,
    name: "Before Adam",
    author: "Jack London",
    category: "Fantasy",
    blurb:
      'In "Before Adam," Jack London takes readers on a mesmerizing journey through the mists of prehistory, blending fantasy and speculative fiction to explore the primal roots of humanity. The novel follows the dreams of a modern man who experiences the life of his ancient ancestor, Big Tooth, a member of a primitive tribe. Through these vivid and often harrowing dreams, the protagonist witnesses the daily struggles, dangers, and triumphs of early human existence, offering a unique perspective on the evolution of mankind.\n\nLondon\'s narrative delves into the harsh realities of survival in a prehistoric world, where the line between life and death is razor-thin, and the bonds of kinship and community are essential for endurance. The story is rich with themes of instinct, adaptation, and the relentless drive for progress, painting a compelling picture of our distant past. "Before Adam" is not just a tale of adventure and survival; it is a profound exploration of the human condition, tracing the threads of our ancestry and the primal forces that continue to shape us today.',
    firstLine: "Pictures! Pictures! Pictures!",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 311,
    name: "Bunner Sisters",
    author: "Edith Wharton",
    category: "Novel",
    blurb:
      "In \"Bunner Sisters,\" Edith Wharton masterfully explores the lives of two impoverished sisters, Ann Eliza and Evelina Bunner, who run a modest millinery shop in the gritty, bustling streets of 19th-century New York City. The sisters' quiet, predictable existence is upended when they encounter the enigmatic clockmaker, Mr. Ramy. As romance and ambition stir within their humble lives, the sisters find themselves grappling with dreams and desires that threaten to disrupt their close-knit bond.\n\nWharton's keen eye for social detail and her profound understanding of human nature shine through in this poignant novella. Through the Bunner sisters' story, she delves into themes of sacrifice, loyalty, and the harsh realities of economic struggle, painting a vivid portrait of the era's social fabric. \"Bunner Sisters\" is a compelling exploration of the delicate balance between hope and hardship, capturing the essence of a bygone New York with both empathy and unflinching honesty.",
    firstLine:
      "In the days when New York's traffic moved at the pace of the drooping horse-car, when society applauded Christine Nilsson at the Academy of Music and basked in the sunsets of the Hudson River School on the walls of the National Academy of Design, an inconspicuous shop with a single show-window was intimately and favourably known to the feminine population of the quarter bordering on Stuyvesant Square.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 314,
    name: "Guide to Life and Literature of the Southwest, with a Few Observations",
    author: "J Dobie",
    category: "Art",
    blurb:
      "\"Guide to Life and Literature of the Southwest, with a Few Observations\" by J. Frank Dobie is an insightful exploration into the rich cultural and literary heritage of the American Southwest. This comprehensive guide delves into the region's unique blend of history, folklore, and natural beauty, offering readers a curated bibliography of essential works that capture the spirit of the Southwest. Dobie, a renowned folklorist and writer, provides not only a list of significant books but also his personal observations and reflections, making this guide an engaging and informative resource for anyone interested in the Southwest's vibrant tapestry.\n\nThe book is more than just a bibliography; it is a journey through the landscapes, traditions, and stories that define the Southwest. Dobie's keen eye for detail and deep appreciation for the region's diverse influences shine through as he discusses the works of various authors, from early explorers to contemporary writers. His observations provide context and depth, helping readers understand the cultural and historical significance of each work. Whether you are a scholar, a casual reader, or a lover of Southwestern culture, this guide offers a valuable roadmap to the literary treasures of the region.",
    firstLine:
      'In the University of Texas I teach a course called "Life and Literature of the Southwest."; about 1929 I had a brief guide to books concerning the Southwest mimeographed; in 1931 it was included by John William Rogers in a booklet entitled Finding Literature on the Texas Plains.',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 316,
    name: "The Golden Road",
    author: "L Montgomery",
    category: "Children's",
    blurb:
      '"The Golden Road" by L. M. Montgomery is a heartwarming tale set in the picturesque landscape of Prince Edward Island. This enchanting novel follows the adventures of a group of children, led by cousins Beverley and Sara Stanley, as they navigate the joys and challenges of growing up. The story is a sequel to "The Story Girl" and continues to explore themes of friendship, imagination, and the bittersweet passage of time.\n\nThe children embark on various escapades, from publishing their own magazine to exploring the mysteries of their rural surroundings. Through their imaginative play and close-knit bonds, they learn valuable life lessons about love, loss, and the importance of cherishing the golden moments of youth. Montgomery\'s vivid descriptions and charming characters make "The Golden Road" a timeless classic that captures the essence of childhood and the enduring power of friendship.',
    firstLine:
      "\"I've thought of something amusing for the winter,\" I said as we drew into a half-circle around the glorious wood-fire in Uncle Alec's kitchen.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 318,
    name: "John Barleycorn",
    author: "Jack London",
    category: "Biography",
    blurb:
      '"John Barleycorn" by Jack London is a compelling autobiographical account that delves into the author\'s tumultuous relationship with alcohol. Through a series of vivid and often harrowing anecdotes, London chronicles his experiences with drinking from his youth to his later years, offering a raw and unflinching look at the seductive and destructive power of alcohol. The narrative is not just a personal memoir but also a broader social commentary on the pervasive culture of drinking in early 20th-century America.\n\nLondon\'s introspective journey reveals the dual nature of alcohol as both a source of camaraderie and a catalyst for personal downfall. He explores the psychological and societal factors that contribute to alcoholism, providing insights into his own struggles and the broader implications for society. "John Barleycorn" stands as a poignant and thought-provoking exploration of addiction, resilience, and the human condition, making it a timeless piece that resonates with readers across generations.',
    firstLine: "It all came to me one election day.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 319,
    name: "The Ways of Men",
    author: "Eliot Gregory",
    category: "Non-Fiction",
    blurb:
      '"The Ways of Men" by Eliot Gregory is a compelling collection of essays that offers a keen insight into the social mores and cultural dynamics of 19th-century America. Through a series of articulate and thought-provoking pieces, Gregory delves into the intricacies of human behavior, societal expectations, and the subtle nuances that define the interactions between individuals in a rapidly evolving society. His essays are marked by a blend of wit, observation, and a deep understanding of the human condition, making them both engaging and enlightening.\n\nGregory\'s work stands out for its ability to capture the essence of an era while addressing timeless themes that resonate with readers across generations. Whether discussing the complexities of social etiquette, the shifting roles of men and women, or the broader implications of cultural change, "The Ways of Men" provides a rich tapestry of reflections that invite readers to ponder the enduring questions of identity, morality, and community. This collection is a testament to Gregory\'s skill as an essayist and his profound grasp of the subtleties that shape human interactions.',
    firstLine:
      "The gentleman who graced the gubernatorial armchair of our state when this century was born happened to be an admirer of classic lore and the sonorous names of antiquity.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 321,
    name: "Moran of the Lady Letty",
    author: "Frank Norris",
    category: "Adventure",
    blurb:
      '"Moran of the Lady Letty" by Frank Norris is a gripping sea adventure that plunges readers into the tumultuous world of maritime life off the coast of California. The story follows the young and affluent Ross Wilbur, who is unexpectedly shanghaied and finds himself aboard a rough-and-tumble sealing schooner. As he navigates the treacherous waters and the even more perilous dynamics of the ship\'s crew, Ross\'s life takes a dramatic turn from the comforts of San Francisco society to the raw, unrefined existence at sea.\n\nAmidst the chaos, Ross encounters Moran, a fiercely independent and capable woman who commands the Lady Letty, a vessel that becomes central to their shared journey. Together, they face the brutal elements, treacherous mutinies, and the stark realities of survival. Through these trials, Ross undergoes a profound transformation, discovering inner strengths and forging an unexpected bond with Moran. Norris\'s vivid storytelling and rich character development make "Moran of the Lady Letty" a timeless tale of adventure, resilience, and the unyielding human spirit.',
    firstLine:
      "This is to be a story of a battle, at least one murder, and several sudden deaths.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 322,
    name: "St. Ives: Being the Adventures of a French Prisoner in England",
    author: "Robert Louis Stevenson",
    category: "Adventure",
    blurb:
      "\"St. Ives: Being the Adventures of a French Prisoner in England\" by Robert Louis Stevenson is a captivating tale set during the Napoleonic Wars. The story follows the charming and resourceful French officer, Jacques St. Ives, who is captured by the British and held as a prisoner of war in Scotland. Despite his confinement, St. Ives' wit and charisma win him friends and admirers, including the beautiful Flora Gilchrist, with whom he falls in love.\n\nAs St. Ives plots his daring escape, he navigates a series of thrilling adventures and narrow escapes, all while maintaining his honor and sense of humor. The novel is a delightful blend of romance, adventure, and historical fiction, showcasing Stevenson's masterful storytelling and rich character development. Through St. Ives' journey, readers are treated to a vivid portrayal of early 19th-century England and the complexities of war, loyalty, and love.",
    firstLine:
      "It was in the month of May 1813 that I was so unlucky as to fall at last into the hands of the enemy.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 324,
    name: "A Knight of the Cumberland",
    author: "John Fox",
    category: "Novel",
    blurb:
      'Set against the rugged backdrop of the Appalachian Mountains in Kentucky, "A Knight of the Cumberland" by John Fox Jr. is a captivating tale of love, honor, and the clash of cultures. The story follows the life of a young, chivalrous mountaineer named Jack Hale, whose bravery and sense of justice earn him the title of a modern-day knight. As he navigates the complexities of mountain life, Jack becomes entangled in a fierce family feud and a burgeoning romance with the beautiful and spirited June Tolliver.\n\nFox masterfully weaves a narrative that explores the stark contrasts between the untamed wilderness and the encroaching modern world. Through vivid descriptions and rich character development, the novel delves into themes of loyalty, tradition, and the transformative power of love. "A Knight of the Cumberland" is not just a historical fiction; it is a poignant reflection on the enduring human spirit and the timeless quest for honor and redemption in a rapidly changing world.',
    firstLine:
      "High noon of a crisp October day, sunshine flooding the earth with the warmth and light of old wine and, going single-file up through the jagged gap that the dripping of water has worn down through the Cumberland Mountains from crest to valley-level, a gray horse and two big mules, a man and two young girls.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 325,
    name: "Phantastes: A Faerie Romance for Men and Women",
    author: "George MacDonald",
    category: "Fantasy",
    blurb:
      '"Phantastes: A Faerie Romance for Men and Women" by George MacDonald is a seminal work of fantasy fiction that transports readers into a dreamlike realm of enchantment and self-discovery. The story follows the young protagonist, Anodos, who, on the eve of his twenty-first birthday, inherits a mysterious key that unlocks a hidden world of faerie. As Anodos ventures through this magical landscape, he encounters a myriad of fantastical beings, from ethereal spirits to menacing shadows, each encounter serving as a reflection of his inner struggles and desires.\n\nThroughout his journey, Anodos grapples with themes of love, loss, and the quest for identity, all while navigating the ever-shifting boundaries between reality and fantasy. MacDonald\'s rich, poetic prose and vivid imagery create an immersive experience that challenges readers to ponder the deeper meanings of life and the nature of the human soul. "Phantastes" is not just a tale of adventure, but a profound exploration of the transformative power of imagination and the enduring quest for truth and beauty.',
    firstLine:
      "I awoke one morning with the usual perplexity of mind which accompanies the return of consciousness.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 326,
    name: "Steep Trails",
    author: "John Muir",
    category: "Non-Fiction",
    blurb:
      '"Steep Trails" by John Muir is a captivating collection of essays and letters that chronicle the naturalist\'s explorations of the American West. Through vivid descriptions and keen observations, Muir takes readers on a journey through the rugged landscapes of the Sierra Nevada, the Cascade Range, and the vast forests of the Pacific Northwest. His writings not only capture the breathtaking beauty of these regions but also reflect his deep reverence for nature and his passionate advocacy for conservation.\n\nIn this compilation, Muir shares his experiences climbing Mount Shasta, traversing the canyons of Utah, and wandering through the forests of Washington and Oregon. His eloquent prose brings to life the majesty of towering peaks, the serenity of ancient woodlands, and the dynamic interplay of natural forces. "Steep Trails" is more than just a travelogue; it is a testament to Muir\'s enduring legacy as a pioneer of the environmental movement and a timeless reminder of the importance of preserving the wild places that inspire awe and wonder.',
    firstLine: "Moral improvers have calls to preach.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 327,
    name: "The Princess Aline",
    author: "Richard Harding Davis",
    category: "Novel",
    blurb:
      '"The Princess Aline" by Richard Harding Davis is a captivating tale that follows the adventures of a young American artist named Morton Carlton. Enchanted by a photograph of the beautiful and enigmatic Princess Aline of Hohenwald, Carlton becomes infatuated and embarks on a whimsical journey across Europe with the singular goal of meeting her. His quest takes him through picturesque landscapes and bustling cities, where he encounters a colorful array of characters and experiences the highs and lows of romantic pursuit.\n\nAs Carlton\'s journey unfolds, the novel delves into themes of idealism, the nature of love, and the often unpredictable path of destiny. Through witty dialogue and vivid descriptions, Davis paints a charming and humorous portrait of late 19th-century society. "The Princess Aline" is a delightful exploration of one man\'s dream and the lengths to which he will go to achieve it, offering readers a blend of adventure, romance, and a touch of fairy-tale magic.',
    firstLine:
      'H. R. H. the Princess Aline of Hohenwald came into the life of Morton Carlton--or "Morney" Carlton, as men called him--of New York city, when that young gentleman\'s affairs and affections were best suited to receive her.',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 329,
    name: "Island Nights' Entertainments",
    author: "Robert Louis Stevenson",
    category: "Novel",
    blurb:
      '"Island Nights\' Entertainments" by Robert Louis Stevenson is a captivating collection of three short stories set against the exotic and mysterious backdrop of the South Pacific islands. The tales, rich with local color and cultural nuances, delve into the lives of both native islanders and foreign settlers, exploring themes of adventure, superstition, and the clash of civilizations. Stevenson\'s masterful storytelling transports readers to a world where the line between reality and myth blurs, and where the beauty and danger of the islands are ever-present.\n\nThe stories, "The Beach of Falesá," "The Bottle Imp," and "The Isle of Voices," each offer a unique glimpse into the complexities of island life. The first, "The Beach of Falesá" follows the journey of a trader who becomes entangled in the dark secrets of a remote village. "The Bottle Imp" is a cautionary tale about a magical bottle that grants wishes but comes with a sinister price. "The Isle of Voices" explores the mysterious powers of a sorcerer and the consequences of greed. Together, these tales showcase Stevenson\'s ability to weave intricate plots with vivid descriptions, making "Island Nights\' Entertainments" a timeless exploration of human nature and the enchanting allure of the Pacific islands.',
    firstLine: "I saw that island first when it was neither night nor morning.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 330,
    name: "Where There's a Will",
    author: "Mary Roberts Rinehart",
    category: "Mystery",
    blurb:
      'In "Where There\'s a Will" by Mary Roberts Rinehart, readers are whisked away to the charming and mysterious Hope Springs, a health resort that promises rejuvenation but delivers much more. The story unfolds with a delightful blend of humor, romance, and intrigue as the characters navigate the complexities of love and deception. The protagonist, a resourceful and witty woman, finds herself entangled in a web of impersonation and mystery, all while trying to maintain the facade of a serene health retreat.\n\nAs secrets unravel and relationships are tested, the narrative keeps readers on their toes with unexpected twists and turns. Rinehart masterfully balances the light-hearted elements of a romantic comedy with the suspenseful undertones of a detective story, creating a captivating tale that explores the lengths to which people will go for love and the truth. "Where There\'s a Will" is a delightful read that combines the best of humor, mystery, and romance, making it a timeless classic in the world of fiction.',
    firstLine:
      "When it was all over Mr. Sam came out to the spring-house to say good-by to me before he and Mrs. Sam left.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 331,
    name: "The Mucker",
    author: "Edgar Rice Burroughs",
    category: "Adventure",
    blurb:
      '"The Mucker" by Edgar Rice Burroughs is a riveting adventure novel that follows the tumultuous journey of Billy Byrne, a rough-and-tumble street thug from Chicago. Disillusioned with society and driven by a fierce sense of independence, Billy finds himself entangled in a series of high-stakes escapades that take him from the gritty urban underworld to the untamed wilds of the South Seas. His life takes a dramatic turn when he is shanghaied and becomes embroiled in piracy, ultimately leading to a shipwreck on a remote island.\n\nAs Billy struggles for survival, he encounters Barbara Harding, a high-society woman who challenges his worldview and ignites a transformation within him. Through trials of courage, loyalty, and redemption, Billy evolves from a hardened criminal to a hero with a newfound sense of honor. Burroughs masterfully weaves themes of personal growth and the clash of social classes into this action-packed narrative, making "The Mucker" a compelling tale of adventure and self-discovery.',
    firstLine:
      "Billy Byrne was a product of the streets and alleys of Chicago's great West Side.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 333,
    name: "Robert Louis Stevenson",
    author: "Walter Alexander Raleigh",
    category: "Biography",
    blurb:
      "\"Robert Louis Stevenson\" by Walter Alexander Raleigh is a compelling biography that delves into the life and literary contributions of one of Scotland's most celebrated authors. Raleigh meticulously chronicles Stevenson's journey from his early years in Edinburgh, through his struggles with chronic illness, to his eventual success as a writer of enduring classics such as \"Treasure Island\" and \"Strange Case of Dr Jekyll and Mr Hyde.\" The biography not only highlights Stevenson's literary achievements but also provides a nuanced portrait of his complex personality, his adventurous spirit, and his relentless pursuit of artistic excellence.\n\nRaleigh's insightful narrative captures the essence of Stevenson's creative genius and his impact on 19th-century literature. Through detailed research and engaging prose, the biography explores Stevenson's relationships, his travels across Europe and the South Pacific, and his profound influence on both contemporary and future generations of writers. This book is an essential read for anyone interested in the life of Robert Louis Stevenson and the rich literary heritage of Scotland.",
    firstLine:
      "When a popular writer dies, the question it has become the fashion with a nervous generation to ask is the question, 'Will he live?'",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 334,
    name: "Episodes in Van Bibber's Life",
    author: "Richard Harding Davis",
    category: "Novel",
    blurb:
      "\"Episodes in Van Bibber's Life\" by Richard Harding Davis is a captivating collection of short stories that chronicles the adventures and misadventures of Van Bibber, a charming and resourceful young man navigating the social and cultural landscape of late 19th-century America. Each story offers a glimpse into Van Bibber's encounters with a diverse array of characters, from high society elites to the downtrodden, showcasing his wit, compassion, and unerring sense of justice.\n\nThrough Davis's keen observations and vivid prose, readers are transported into a world where Van Bibber's quick thinking and moral compass often lead to unexpected and heartwarming outcomes. Whether he is thwarting a crime, aiding those in need, or simply enjoying the pleasures of urban life, Van Bibber's episodes are a testament to the enduring human spirit and the complexities of social interactions. This collection not only entertains but also provides a nuanced commentary on the societal norms and challenges of the era.",
    firstLine:
      'It was at the end of the first act of the first night of "The Sultana," and every member of the Lester Comic Opera Company, from Lester himself down to the wardrobe woman\'s son, who would have had to work if his mother lost her place, was sick with anxiety.',
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 335,
    name: "Frances Waldeaux",
    author: "Rebecca Harding Davis",
    category: "Novel",
    blurb:
      '"Frances Waldeaux" by Rebecca Harding Davis is a compelling exploration of personal transformation and the complexities of human relationships set against the backdrop of European travel. The story follows Frances Waldeaux, a strong-willed and independent woman, as she embarks on a journey across the Atlantic with her son, George. As they traverse the picturesque landscapes and bustling cities of Europe, Frances grapples with her own expectations and the evolving dynamics between her and George, who is on the cusp of forging his own path in life.\n\nThrough vivid descriptions and keen psychological insight, Davis delves into themes of identity, maternal love, and the quest for self-discovery. The novel captures the essence of travel not just as a physical journey, but as a profound inner voyage that challenges and reshapes the characters\' perceptions of themselves and each other. "Frances Waldeaux" is a richly textured narrative that invites readers to reflect on the transformative power of new experiences and the enduring bonds of family.',
    firstLine:
      "In another minute the Kaiser Wilhelm would push off from her pier in Hoboken.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 336,
    name: "Indian Heroes and Great Chieftains",
    author: "Charles A Eastman",
    category: "Biography",
    blurb:
      '"Indian Heroes and Great Chieftains" by Charles A. Eastman is a compelling collection of biographical sketches that brings to life the valor, wisdom, and leadership of notable Native American figures. Eastman, himself of Dakota descent, offers an intimate and respectful portrayal of these heroes, providing readers with a nuanced understanding of their lives and legacies. The book delves into the stories of legendary chieftains such as Sitting Bull, Red Cloud, and Crazy Horse, among others, highlighting their struggles, triumphs, and enduring impact on their people and American history.\n\nThrough vivid storytelling and meticulous research, Eastman captures the essence of these leaders\' characters and the cultural contexts in which they lived. The narratives not only celebrate their bravery and strategic acumen but also shed light on the broader historical and social dynamics of Native American tribes during times of conflict and change. "Indian Heroes and Great Chieftains" serves as both an educational resource and a tribute, preserving the rich heritage and honoring the indomitable spirit of these remarkable individuals.',
    firstLine: "Every age, every race, has its leaders and heroes.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 337,
    name: "Indian Boyhood",
    author: "Charles A. Eastman",
    category: "Biography",
    blurb:
      '"Indian Boyhood" by Charles A. Eastman is a captivating memoir that offers a vivid portrayal of the author\'s early years growing up as a Santee Sioux in the late 19th century. Through a series of engaging anecdotes and reflections, Eastman, also known by his native name Ohiyesa, provides an intimate glimpse into the traditional customs, values, and daily life of his people. The narrative is rich with descriptions of the natural world, the rites of passage, and the communal spirit that defined his childhood, offering readers a rare and authentic perspective on Native American life before the widespread influence of European settlers.\n\nEastman\'s storytelling is both educational and deeply personal, as he recounts his experiences with hunting, spiritual ceremonies, and the teachings of his elders. His journey from a traditional Sioux upbringing to his eventual education in Western schools serves as a poignant exploration of cultural identity and adaptation. "Indian Boyhood" is not just a memoir; it is a testament to the resilience and wisdom of the Santee Sioux, and a valuable contribution to the understanding of Native American heritage.',
    firstLine:
      "What boy would not be an Indian for a while when he thinks of the freest life in the world?",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 340,
    name: "The Soul of the Indian: An Interpretation",
    author: "Charles A Eastman",
    category: "Non-Fiction",
    blurb:
      'In "The Soul of the Indian: An Interpretation," Charles A. Eastman, a Native American author and physician of Santee Dakota heritage, offers a profound exploration of the spiritual and philosophical dimensions of Native American life. Drawing from his own experiences and the rich oral traditions of his people, Eastman delves into the core beliefs, rituals, and values that define the indigenous worldview. He provides a compelling narrative that contrasts the spiritual simplicity and deep connection to nature found in Native American spirituality with the complexities and materialism of Western culture.\n\nEastman\'s work is not just an exposition of religious practices but a heartfelt plea for understanding and respect for Native American ways of life. He articulates the intrinsic harmony between the natural world and the spiritual realm, emphasizing themes of gratitude, humility, and interconnectedness. Through his eloquent prose, Eastman seeks to bridge cultural divides and offer readers a glimpse into the profound wisdom and enduring spirit of the Indian soul.',
    firstLine:
      "The original attitude of the American Indian toward the Eternal, the 'Great Mystery' that surrounds and embraces us, was as simple as it was exalted.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 342,
    name: "Margaret Ogilvy",
    author: "J Barrie",
    category: "Biography",
    blurb:
      "\"Margaret Ogilvy\" by J.M. Barrie is a heartfelt biographical tribute to the author's mother, Margaret Ogilvy. Through a series of intimate and poignant vignettes, Barrie paints a vivid portrait of a remarkable woman whose indomitable spirit and boundless love profoundly shaped his life and work. The narrative delves into Margaret's early years, her marriage, and the trials and triumphs she faced, all while highlighting her unwavering faith, wisdom, and resilience.\n\nBarrie's affectionate and often humorous recollections offer readers a glimpse into the close-knit family dynamics and the deep bond between mother and son. \"Margaret Ogilvy\" is not just a memoir but a celebration of maternal influence and the enduring legacy of a mother's love. It is a touching homage that resonates with universal themes of family, sacrifice, and the enduring impact of a parent's guidance.",
    firstLine:
      "On the day I was born we bought six hair-bottomed chairs, and in our little house it was an event, the first great victory in a woman's long campaign.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 345,
    name: "Dracula",
    author: "Bram Stoker",
    category: "Horror",
    blurb:
      '"Dracula" by Bram Stoker is a seminal work in Gothic fiction that introduces readers to the legendary vampire Count Dracula. The novel unfolds through a series of diary entries, letters, and newspaper clippings, creating an epistolary narrative that immerses readers in the chilling tale. The story begins with Jonathan Harker, a young English solicitor, traveling to Transylvania to assist the mysterious Count with a real estate transaction. Harker soon discovers that Dracula is no ordinary nobleman but a centuries-old vampire with sinister plans to spread his undead curse to England.\n\nAs Dracula makes his way to England, he leaves a trail of terror and despair, targeting the innocent Lucy Westenra and drawing the attention of her friends and family. Dr. Van Helsing, a knowledgeable and determined vampire hunter, leads the charge to stop Dracula\'s reign of terror. The novel explores themes of fear, superstition, and the clash between modernity and ancient evil, all set against the eerie backdrops of Transylvania and Whitby. "Dracula" remains a timeless classic, captivating readers with its atmospheric storytelling and the enduring allure of its titular villain.',
    firstLine:
      "Left Munich at 8:35 P. M., on 1st May, arriving at Vienna early next morning; should have arrived at 6:46, but train was an hour late.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
  {
    id: 347,
    name: "The Saga of Grettir the Strong",
    author: "Unknown",
    category: "Adventure",
    blurb:
      '"The Saga of Grettir the Strong," also known as "Grettir\'s Saga," is a classic Icelandic saga that chronicles the life and adventures of Grettir Ásmundarson, a formidable and complex Viking hero. Set in the early 11th century, the narrative follows Grettir from his youthful exploits and early brushes with the law to his eventual status as an outlaw. Known for his extraordinary strength and indomitable spirit, Grettir\'s life is marked by a series of heroic deeds, fierce battles, and tragic misfortunes. His encounters with supernatural beings, including ghosts and trolls, add a mythical dimension to his story, highlighting the blend of history and legend that characterizes the sagas.\n\nDespite his prowess, Grettir\'s life is fraught with challenges, including a curse that dooms him to a life of isolation and conflict. The saga delves into themes of fate, honor, and the struggle for survival in a harsh and unforgiving landscape. Through vivid storytelling and rich character development, "Grettir\'s Saga" offers a compelling portrait of a hero who is both larger-than-life and deeply human, capturing the essence of the Viking spirit and the timeless allure of Icelandic sagas.',
    firstLine:
      "There was a man named Onund, the son of Ofeig Clumsyfoot, who was the son of Ivar Horsetail.",
    lastLine: "N/A",
    imgUrl: "N/A",
  },
];
